import React from 'react';

const Blurb2_10 = () =>
  <>
    <p>
      Hearing difficulties may be present for you/your child, and it will be necessary to access accommodations and connect with supports to promote healthy auditory functioning and promote accessibility.
    </p>
  </>;

export default Blurb2_10;
