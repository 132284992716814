import React from 'react';

const Blurb4_3B = () =>
  <>
    <p>
      There are many approaches you can take to help your child improve their social abilities. Directly teaching social skills through modelling, conversations, and play in early childhood, can act as a protective factor, and may also promote overall well-being.
    </p>
  </>;

export default Blurb4_3B;
