import React from 'react';
import CheckBox from '../../../components/CheckBox';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import SpecifyTextBox from '../../../components/SpecifyTextBox';

const PalpebralFissureNormsForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.PALPEBRAL_FISSURE_NORMS.id]);
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    if (updatedFormData.fissurenorms___3 === '0') {
      updatedFormData[FORMS.PALPEBRAL_FISSURE_NORMS.fields.fissurenorms___3.specify_other.id] = FORMS.PALPEBRAL_FISSURE_NORMS.fields.fissurenorms___3.specify_other.value;
    }

    dispatch(submitForm(FORMS.PALPEBRAL_FISSURE_NORMS.id, updatedFormData));
  };

  const palpebralFissureNorms = FORMS.PALPEBRAL_FISSURE_NORMS.fields;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] === '1');
  const isFormComplete = checkedFields.length > 0 &&
    (formData.fissurenorms___3 === '1' ? formData.fissurenorms.length > 0 : true);

  return (
    <>
      <form>
        <div className='grid-x grid-margin-y'>
          <div className='cell large-7'>
            <label className='form-field'>Palpebral fissure norms used:</label>
            <div className='grid-x small-up-1 medium-up-2'>
              {Object.entries(palpebralFissureNorms).map(([normKey, normValues]) =>
                <CheckBox
                  checked={formData[normKey] === '1'}
                  className='cell'
                  key={normKey}
                  onChange={e => handleFormChange({
                    ...formData,
                    [normKey]: e.target.checked ? '1' : '0'
                  })}
                  value={normValues.title}/>)}
            </div>
          </div>
          <div className='cell large-7'>
            {
              formData.fissurenorms___3 === '1' && <>
                <SpecifyTextBox
                  fieldId={FORMS.PALPEBRAL_FISSURE_NORMS.fields.fissurenorms___3.specify_other.id}
                  formId={FORMS.PALPEBRAL_FISSURE_NORMS.id}
                  handleFormChange={handleFormChange}
                  title={FORMS.PALPEBRAL_FISSURE_NORMS.fields.fissurenorms___3.specify_other.title}/>
              </>
            }
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

PalpebralFissureNormsForm.propTypes = {
  formIndex: PropTypes.number
};

export default PalpebralFissureNormsForm;
