import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Variables from '../components/Variables';
import Blurb5_1A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_1A';
import Blurb5_1B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_1B';
import Blurb5_1C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_1C';
import Blurb5_1D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_1D';
import Blurb5_1E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_1E';
import Blurb5_2A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_2A';
import Blurb5_2B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_2B';
import Blurb5_2C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_2C';
import Blurb5_2D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_2D';
import Blurb5_2E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_2E';
import Blurb5_3A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_3A';
import Blurb5_3B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_3B';
import Blurb5_3C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_3C';
import Blurb5_3D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_3D';
import Blurb5_3E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_3E';
import Blurb5_4A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_4A';
import Blurb5_4B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_4B';
import Blurb5_4C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_4C';
import Blurb5_4D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_4D';
import Blurb5_4E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_4E';
import Blurb5_5A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_5A';
import Blurb5_5B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_5B';
import Blurb5_5C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_5C';
import Blurb5_5E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_5E';
import Blurb5_5D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_5D';
import Blurb5_6A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_6A';
import Blurb5_6B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_6B';
import Blurb5_6C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_6C';
import Blurb5_6D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_6D';
import Blurb5_6E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_6E';
import Blurb5_7A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_7A';
import Blurb5_7B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_7B';
import Blurb5_7C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_7C';
import Blurb5_7D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_7D';
import Blurb5_7E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_7E';
import Blurb5_8A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_8A';
import Blurb5_8B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_8B';
import Blurb5_8C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_8C';
import Blurb5_8D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_8D';
import Blurb5_8E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_8E';
import Blurb5_9 from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_9';
import Blurb5_10 from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_10';
import Blurb5_11 from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_11';
import Blurb5_12A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_12A';
import Blurb5_12B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_12B';
import Blurb5_12C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_12C';
import Blurb5_12D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_12D';
import Blurb5_12E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_12E';
import Blurb5_13A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_13A';
import Blurb5_13B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_13B';
import Blurb5_13D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_13D';
import Blurb5_13E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_13E';
import Blurb5_14A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_14A';
import Blurb5_14B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_14B';
import Blurb5_14C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_14C';
import Blurb5_14D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_14D';
import Blurb5_14E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_14E';
import Blurb5_15A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_15A';
import Blurb5_15B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_15B';
import Blurb5_15C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_15C';
import Blurb5_15D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_15D';
import Blurb5_15E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_15E';
import Blurb5_16C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_16C';
import Blurb5_16B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_16B';
import Blurb5_16A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_16A';
import Blurb5_16D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_16D';
import Blurb5_16E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_16E';
import Blurb5_13C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_13C';
import TwoColumnLayout from './TwoColumnLayout';

const EducationAndSkillbuilding = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {ref_reason___1, ref_reason___3} = useSelector(state => state.forms[FORMS.REFERRAL_REASON.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {iq} = useSelector(state => state.forms[FORMS.IQ_SCORE.id]);
  const {intellectual, language} = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);
  const {language_brain, academic_brain} = useSelector(state => state.forms[FORMS.BRAIN_DOMAIN_ASSESSMENT.id]);
  const {associatedfeatures___4} = useSelector(state => state.forms[FORMS.OTHER_FEATURES.id]);
  const {teacherassistant, expulsion} = useSelector(state => state.forms[FORMS.EXPERIENCE.id]);
  const {reassessment, slp, ot, aba} = useSelector(state => state.forms[FORMS.RECOMMENDATIONS.id]);

  const variables = {diagnosis, age, ref_reason___3, ref_reason___1, iq, intellectual, language,
    language_brain, associatedfeatures___4, academic_brain, teacherassistant, expulsion, reassessment, slp, ot, aba};

  const EducationAndSkillbuildingBlurb5_1A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_1B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_1C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_1D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_1E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationAndSkillbuildingBlurb5_2A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_2B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_2C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_2D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_2E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationAndSkillbuildingBlurb5_3A = (intellectual === '1' || iq === '0') && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_3B = (intellectual === '1' || iq === '0') && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_3C = (intellectual === '1' || iq === '0') && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_3D = (intellectual === '1' || iq === '0') && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_3E = (intellectual === '1' || iq === '0') && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationAndSkillbuildingBlurb5_4A = (language_brain === '1' || language === '1') && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_4B = (language_brain === '1' || language === '1') && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_4C = (language_brain === '1' || language === '1') && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_4D = (language_brain === '1' || language === '1') && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_4E = (language_brain === '1' || language === '1') && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationAndSkillbuildingBlurb5_5A = associatedfeatures___4 === '1' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_5B = associatedfeatures___4 === '1' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_5C = associatedfeatures___4 === '1' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_5D = associatedfeatures___4 === '1' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_5E = associatedfeatures___4 === '1' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationAndSkillbuildingBlurb5_6A = academic_brain === '1' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_6B = academic_brain === '1' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_6C = academic_brain === '1' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_6D = academic_brain === '1' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_6E = academic_brain === '1' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationAndSkillbuildingBlurb5_7A = ref_reason___1 === '1' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_7B = ref_reason___1 === '1' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_7C = ref_reason___1 === '1' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_7D = ref_reason___1 === '1' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_7E = ref_reason___1 === '1' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationAndSkillbuildingBlurb5_8A = ref_reason___3 === '1' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_8B = ref_reason___3 === '1' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_8C = ref_reason___3 === '1' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_8D = ref_reason___3 === '1' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_8E = ref_reason___3 === '1' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationAndSkillbuildingBlurb5_9 = teacherassistant === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_10 = teacherassistant === '0' && (academic_brain === '1' || intellectual === '1' || iq === '0') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_11 = expulsion === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationAndSkillbuildingBlurb5_12A = reassessment === '1' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_12B = reassessment === '1' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_12C = reassessment === '1' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_12D = reassessment === '1' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_12E = reassessment === '1' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationAndSkillbuildingBlurb5_13A = slp === '1' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_13B = slp === '1' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_13C = slp === '1' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_13D = slp === '1' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_13E = slp === '1' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationAndSkillbuildingBlurb5_14A = ot === '1' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_14B = ot === '1' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_14C = ot === '1' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_14D = ot === '1' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_14E = ot === '1' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationAndSkillbuildingBlurb5_15A = aba === '1' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_15B = aba === '1' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_15C = aba === '1' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_15D = aba === '1' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_15E = aba === '1' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationAndSkillbuildingBlurb5_16A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_16B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_16C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_16D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationAndSkillbuildingBlurb5_16E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  return <section>
    <SectionTitle>Education And Skill building </SectionTitle>
    <Variables variableObject={variables}/>
    {
      (EducationAndSkillbuildingBlurb5_1A || EducationAndSkillbuildingBlurb5_1B || EducationAndSkillbuildingBlurb5_1C || EducationAndSkillbuildingBlurb5_1D || EducationAndSkillbuildingBlurb5_1E) &&
      <Card>
        <h3 className='chapter-title'>
          Things to <span className='card-title'>Know</span>
        </h3>
        <>
          {EducationAndSkillbuildingBlurb5_1A && <Blurb5_1A/>}
          {EducationAndSkillbuildingBlurb5_1B && <Blurb5_1B/>}
          {EducationAndSkillbuildingBlurb5_1C && <Blurb5_1C/>}
          {EducationAndSkillbuildingBlurb5_1D && <Blurb5_1D/>}
          {EducationAndSkillbuildingBlurb5_1E && <Blurb5_1E/>}
        </>
      </Card>
    }
    {
      (EducationAndSkillbuildingBlurb5_2A || EducationAndSkillbuildingBlurb5_2B || EducationAndSkillbuildingBlurb5_2C || EducationAndSkillbuildingBlurb5_2D || EducationAndSkillbuildingBlurb5_2E ||
        EducationAndSkillbuildingBlurb5_3A || EducationAndSkillbuildingBlurb5_3B || EducationAndSkillbuildingBlurb5_3C || EducationAndSkillbuildingBlurb5_3D || EducationAndSkillbuildingBlurb5_3E ||
        EducationAndSkillbuildingBlurb5_4A || EducationAndSkillbuildingBlurb5_4B || EducationAndSkillbuildingBlurb5_4C || EducationAndSkillbuildingBlurb5_4D || EducationAndSkillbuildingBlurb5_4E ||
        EducationAndSkillbuildingBlurb5_5A || EducationAndSkillbuildingBlurb5_5B || EducationAndSkillbuildingBlurb5_5C || EducationAndSkillbuildingBlurb5_5D || EducationAndSkillbuildingBlurb5_5E ||
        EducationAndSkillbuildingBlurb5_6A || EducationAndSkillbuildingBlurb5_6B || EducationAndSkillbuildingBlurb5_6C || EducationAndSkillbuildingBlurb5_6D || EducationAndSkillbuildingBlurb5_6E ||
        EducationAndSkillbuildingBlurb5_12A || EducationAndSkillbuildingBlurb5_12B || EducationAndSkillbuildingBlurb5_12C || EducationAndSkillbuildingBlurb5_12D || EducationAndSkillbuildingBlurb5_12E ||
        EducationAndSkillbuildingBlurb5_13A || EducationAndSkillbuildingBlurb5_13B || EducationAndSkillbuildingBlurb5_13C || EducationAndSkillbuildingBlurb5_13D || EducationAndSkillbuildingBlurb5_13E ||
        EducationAndSkillbuildingBlurb5_14A || EducationAndSkillbuildingBlurb5_14B || EducationAndSkillbuildingBlurb5_14C || EducationAndSkillbuildingBlurb5_14D || EducationAndSkillbuildingBlurb5_14E ||
        EducationAndSkillbuildingBlurb5_15A || EducationAndSkillbuildingBlurb5_15B || EducationAndSkillbuildingBlurb5_15C || EducationAndSkillbuildingBlurb5_15D || EducationAndSkillbuildingBlurb5_15E) &&
      <Card>
        <h3 className='chapter-title'>
          Things to <span className='card-title'>Do Now</span>
        </h3>
        <TwoColumnLayout>
          <>
            {EducationAndSkillbuildingBlurb5_2A && <Blurb5_2A/>}
            {EducationAndSkillbuildingBlurb5_2B && <Blurb5_2B/>}
            {EducationAndSkillbuildingBlurb5_2C && <Blurb5_2C/>}
            {EducationAndSkillbuildingBlurb5_2D && <Blurb5_2D/>}
            {EducationAndSkillbuildingBlurb5_2E && <Blurb5_2E/>}
          </>
          <>
            {EducationAndSkillbuildingBlurb5_3A && <Blurb5_3A/>}
            {EducationAndSkillbuildingBlurb5_3B && <Blurb5_3B/>}
            {EducationAndSkillbuildingBlurb5_3C && <Blurb5_3C/>}
            {EducationAndSkillbuildingBlurb5_3D && <Blurb5_3D/>}
            {EducationAndSkillbuildingBlurb5_3E && <Blurb5_3E/>}
          </>
          <>
            {EducationAndSkillbuildingBlurb5_4A && <Blurb5_4A/>}
            {EducationAndSkillbuildingBlurb5_4B && <Blurb5_4B/>}
            {EducationAndSkillbuildingBlurb5_4C && <Blurb5_4C/>}
            {EducationAndSkillbuildingBlurb5_4D && <Blurb5_4D/>}
            {EducationAndSkillbuildingBlurb5_4E && <Blurb5_4E/>}
          </>
          <>
            {EducationAndSkillbuildingBlurb5_5A && <Blurb5_5A/>}
            {EducationAndSkillbuildingBlurb5_5B && <Blurb5_5B/>}
            {EducationAndSkillbuildingBlurb5_5C && <Blurb5_5C/>}
            {EducationAndSkillbuildingBlurb5_5D && <Blurb5_5D/>}
            {EducationAndSkillbuildingBlurb5_5E && <Blurb5_5E/>}
          </>
          <>
            {EducationAndSkillbuildingBlurb5_6A && <Blurb5_6A/>}
            {EducationAndSkillbuildingBlurb5_6B && <Blurb5_6B/>}
            {EducationAndSkillbuildingBlurb5_6C && <Blurb5_6C/>}
            {EducationAndSkillbuildingBlurb5_6D && <Blurb5_6D/>}
            {EducationAndSkillbuildingBlurb5_6E && <Blurb5_6E/>}
          </>
          <>
            {EducationAndSkillbuildingBlurb5_7A && <Blurb5_7A/>}
            {EducationAndSkillbuildingBlurb5_7B && <Blurb5_7B/>}
            {EducationAndSkillbuildingBlurb5_7C && <Blurb5_7C/>}
            {EducationAndSkillbuildingBlurb5_7D && <Blurb5_7D/>}
            {EducationAndSkillbuildingBlurb5_7E && <Blurb5_7E/>}
          </>
          <>
            {EducationAndSkillbuildingBlurb5_8A && <Blurb5_8A/>}
            {EducationAndSkillbuildingBlurb5_8B && <Blurb5_8B/>}
            {EducationAndSkillbuildingBlurb5_8C && <Blurb5_8C/>}
            {EducationAndSkillbuildingBlurb5_8D && <Blurb5_8D/>}
            {EducationAndSkillbuildingBlurb5_8E && <Blurb5_8E/>}
          </>
          <>
            {EducationAndSkillbuildingBlurb5_9 && <Blurb5_9/>}
            {EducationAndSkillbuildingBlurb5_10 && <Blurb5_10/>}
          </>
          <>
            {EducationAndSkillbuildingBlurb5_11 && <Blurb5_11/>}
          </>
          <>
            {EducationAndSkillbuildingBlurb5_12A && <Blurb5_12A/>}
            {EducationAndSkillbuildingBlurb5_12B && <Blurb5_12B/>}
            {EducationAndSkillbuildingBlurb5_12C && <Blurb5_12C/>}
            {EducationAndSkillbuildingBlurb5_12D && <Blurb5_12D/>}
            {EducationAndSkillbuildingBlurb5_12E && <Blurb5_12E/>}
          </>
          <>
            {EducationAndSkillbuildingBlurb5_13A && <Blurb5_13A/>}
            {EducationAndSkillbuildingBlurb5_13B && <Blurb5_13B/>}
            {EducationAndSkillbuildingBlurb5_13C && <Blurb5_13C/>}
            {EducationAndSkillbuildingBlurb5_13D && <Blurb5_13D/>}
            {EducationAndSkillbuildingBlurb5_13E && <Blurb5_13E/>}
          </>
          <>
            {EducationAndSkillbuildingBlurb5_14A && <Blurb5_14A/>}
            {EducationAndSkillbuildingBlurb5_14B && <Blurb5_14B/>}
            {EducationAndSkillbuildingBlurb5_14C && <Blurb5_14C/>}
            {EducationAndSkillbuildingBlurb5_14D && <Blurb5_14D/>}
            {EducationAndSkillbuildingBlurb5_14E && <Blurb5_14E/>}
          </>
          <>
            {EducationAndSkillbuildingBlurb5_15A && <Blurb5_15A/>}
            {EducationAndSkillbuildingBlurb5_15B && <Blurb5_15B/>}
            {EducationAndSkillbuildingBlurb5_15C && <Blurb5_15C/>}
            {EducationAndSkillbuildingBlurb5_15D && <Blurb5_15D/>}
            {EducationAndSkillbuildingBlurb5_15E && <Blurb5_15E/>}
          </>
        </TwoColumnLayout>
      </Card>
    }
    {
      (EducationAndSkillbuildingBlurb5_16A || EducationAndSkillbuildingBlurb5_16B || EducationAndSkillbuildingBlurb5_16C || EducationAndSkillbuildingBlurb5_16D || EducationAndSkillbuildingBlurb5_16E) &&
      <Card>
        <h3 className='chapter-title'>
          Things to <span className='card-title'>Plan</span> for
        </h3>
        {EducationAndSkillbuildingBlurb5_16A && <Blurb5_16A/>}
        {EducationAndSkillbuildingBlurb5_16B && <Blurb5_16B/>}
        {EducationAndSkillbuildingBlurb5_16C && <Blurb5_16C/>}
        {EducationAndSkillbuildingBlurb5_16D && <Blurb5_16D/>}
        {EducationAndSkillbuildingBlurb5_16E && <Blurb5_16E/>}
      </Card>
    }
  </section>;
};

export default EducationAndSkillbuilding;
