import React from 'react';

const Blurb13_1A = () =>
  <>
    <p>
      The Canadian education system aims to provide students with the skills and experiences needed to build academic and potential employment success, navigate peer interactions, build a sense of personal identity, and transition into adulthood. In school, we learn who we are, what we do well, and build social networks that influence our decision-making and our trajectories forward.
    </p>
    <p>
      Educators, educational assistants, administration, and support staff all play a role in these developmental processes. This is particularly true for children and youth with brain-based differences such as FASD who may require creative and collaborative efforts to navigate the school environment and experience success.
    </p>
    <p>
      FASD can look very different from student to student. Often, strengths and difficulties are scattered within an individual and small changes in demands can have big impacts on functioning. Establishing a shared understanding of the unique brain-based needs, strengths, and goals of each student with FASD provides a good starting point for comprehensive, individualized, and supportive education planning.
    </p>
    <p>
      Mental health is a particularly important part of a student’s education journey and plays an important role within classrooms to influence social interactions and learning outcomes. Ensuring that the classroom environment supports this student’s mental health will be important for their growth and development.
    </p>
    <p>
      For more information around understanding ways in which behaviour may reflect underlying brain differences or mental health needs for students with FASD, refer to the “Brain not Blame” handout from CanFASD: <a href='https://www.engagingalllearners.ca/il/supporting-students-with-fasd/documents/brain-not-blame.pdf'>https://www.engagingalllearners.ca/il/supporting-students-with-fasd/documents/brain-not-blame.pdf</a>
    </p>
    <p>
      Access to <span className='text-bold'>FASD education</span> may also help to build educator skills to create well suited environments. The Canada FASD Research Network offers free online FASD resources and training: <a href='https://estore.canfasd.ca/foundations-in-fasd'>https://estore.canfasd.ca/foundations-in-fasd</a>
    </p>
  </>;

export default Blurb13_1A;
