import React from 'react';

const Blurb11_3D = () =>
  <>
    <p>
      Like any major life event, becoming a parent can be easier when you and your supporters make sure that services are put in place that help you use your strengths and develop your parenting skills. Potential supports to consider include in-home services, advocacy, counselling, education, and pregnancy programs that leverage and support your unique strengths and needs. As you become more confident and able to advocate for yourself and your children, environmental supports can be reduced if desired.
    </p>
    <p>
      People with FASD can have a hard time with regulation, which can make the unpredictability and stress of parenting even harder. Building skills to help stay calm under pressure while also learning to be flexible can help all parents, including those with FASD. Self-care will also be essential for your parenting journey and will help you to care for your children. This may look like mental health and parenting support, access to support groups, self-care activities, or hobbies that bring you joy.
    </p>
  </>;

export default Blurb11_3D;
