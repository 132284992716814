import React from 'react';

const Blurb2_1D = () =>
  <>
    <p>
      Physical health is fundamental to daily living. Adulthood is an important period for physical
      and cognitive development. Ongoing monitoring of your physical health, including hearing, vision,
      mobility, and diet, is important to your overall well-being. Individuals with FASD may experience
      more physical health needs at earlier ages than might typically be expected – this means that looking
      out for even unexpected needs is important. It is also important to access evidence-based information
      about your sexual health, pregnancy, and healthy relationships.
    </p>
  </>;

export default Blurb2_1D;
