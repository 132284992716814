import React from 'react';

const Blurb10_6 = () =>
  <>
    <p>
      You may be experiencing immediate concerns with housing at this time. Your province may have specific emergency housing and/or financial support. It may be helpful to talk to your local FASD clinic or advocacy group to find out what resources are available in your area.
    </p>
  </>;

export default Blurb10_6;
