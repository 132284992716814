import React from 'react';

const Blurb4_2B = () =>
  <>
    <p>
      Brain-based differences can make it more challenging for people with FASD to regulate emotions, read social cues, and interact positively with others in social settings. This can be stressful for children with FASD who often value connections and relationships - like we all do. Children with FASD can work towards building their social skills to help them engage with peers, make friends, and explore new social settings and environments. Your child may have strengths in reciprocating affection, friendliness, and the desire to please others, which may be leveraged in the development of social skills.
    </p>
  </>;

export default Blurb4_2B;
