import React from 'react';
import PropTypes from 'prop-types';

const TwoColumnLayout = ({children}) =>
  <div className='column-count-2'>
    {children}
    {/* These line breaks are added so that the left column is always taller than the right one. */}
    <br/>
    <br/>
  </div>;

TwoColumnLayout.propTypes = {
  children: PropTypes.any.isRequired
};

export default TwoColumnLayout;
