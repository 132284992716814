import React from 'react';

const Blurb2_12E = () =>
  <>
    <p>
      People with FASD require ongoing health support as they age throughout the lifespan. Planning ahead helps us be proactive and focus on providing supports and achieving goals, rather than just responding to problems. To help with proactive planning, Dr. Hanlon-Dearman and colleagues created an <span className='text-bold'>anticipatory guide</span> for primary health care providers working with individuals with FASD: <a href='https://jptcp.com/index.php/jptcp/article/view/275/228'>https://jptcp.com/index.php/jptcp/article/view/275/228</a>
    </p>
  </>;

export default Blurb2_12E;
