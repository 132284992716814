import React from 'react';

const Blurb7_3C = () =>
  <>
    <p>
      Interdependence is integral to daily life. It requires a balance between community engagement and adaptive functioning. Adolescents with FASD have a range of strengths and needs related to interdependence. Most people receive help from others in their day-to-day life, to some degree. Helping your teen navigate their community and daily living skills is important to promote interdependence. Ongoing and developmentally appropriate help, such as coaching, peer mentorship, and decision-making guides, will help to support your teen’s growing need for choice and autonomy.
    </p>
  </>;

export default Blurb7_3C;
