import React from 'react';

const Blurb17C = () =>
  <>
    <p>
        People with FASD differ in the areas where they excel and where supports would be helpful.
        Some have stronger skills in intellectual functioning, language abilities, and academics. However,
        having these skills, and the ability to consistently use them are two different things.
    </p>
    <p>
        Your teen may experience difficulties in applying their skills. This could look like struggling
        to pay attention, slowing down to think closely about something, and thinking flexibly. Problem-solving
        and planning may also be more of a challenge. Your teen has many strengths, but it may be more difficult
        for them to put that knowledge into action, for brain-based reasons. These difficulties may make it
        hard for your teen to control emotions, especially when it becomes more challenging to meet daily expectations.
    </p>
    <p>
        Some people may misinterpret your teen’s “application” difficulties as unwillingness to follow
        instructions. For example, your teen may not follow instructions even if they remember them, and
        some may see this as oppositional behaviour, anger, or avoidance. By understanding that your teen’s
        brain-based differences impact their ability to use their skills in everyday ways, we can change our
        approach to support them better so they can grow and succeed in their own way. All individuals want
        to connect meaningfully with others and find purpose, so it is important to promote opportunities
        for success and acceptance.
    </p>
  </>;

export default Blurb17C;
