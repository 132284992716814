import React from 'react';
import PropTypes from 'prop-types';

const MonthYear = ({title, onChange, monthOptions, yearOptions, formData, monthId, yearId}) => {
  const monthValue = formData[monthId];
  const yearValue = formData[yearId];

  return (
    <>
      <form>
        <label
          className='form-field'>{title}</label>
        <div className='grid-x grid-margin-x small-up-2 medium-up-3 large-up-4'>
          <div className='cell'>
            <select
              id='month'
              name='month'
              onChange={event => onChange({...formData, [monthId]: event.target.value})}
              value={monthValue ? monthValue : ''}>
              <option
                disabled
                hidden
                value=''>Choose Month</option>
              {
                Object.keys(monthOptions).map(value =>
                  <option
                    key={value}
                    value={value}>{monthOptions[value]}
                  </option>)
              }
            </select>
          </div>
          <div className='cell'>
            <select
              id='year'
              name='year'
              onChange={event => onChange({...formData, [yearId]: event.target.value})}
              value={yearValue ? yearValue : ''}>
              <option
                disabled
                hidden
                value=''>Choose Year</option>
              {
                Object.keys(yearOptions).map(optionKey =>
                  <option
                    key={optionKey}
                    value={yearOptions[optionKey]}>{yearOptions[optionKey]}
                  </option>)
              }
            </select>
          </div>
        </div>
      </form>
    </>
  );
};

export default MonthYear;

MonthYear.propTypes = {
  formData: PropTypes.object.isRequired,
  monthId: PropTypes.string.isRequired,
  monthOptions: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  yearId: PropTypes.string.isRequired,
  yearOptions: PropTypes.object.isRequired
};
