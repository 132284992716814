import React from 'react';

const Blurb7_3B = () =>
  <>
    <p>
      Interdependence is integral to daily life. It requires a balance between community engagement and adaptive functioning. Children with FASD have a range of strengths and needs related to interdependence. Most people receive help from others in their day-to-day life, to some degree. Helping your child navigate their community and daily living skills is important to promote interdependence. Ongoing and developmentally appropriate help, such as coaching, peer mentorship, and decision-making guides, will help to support your child’s growing need for choice and autonomy.
    </p>
  </>;

export default Blurb7_3B;
