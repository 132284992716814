import React from 'react';

const Blurb11_2D = () =>
  <>
    <p>
      Parenting can be very challenging. It requires diverse skills, including problem-solving, empathy, patience, stress management, communication, and adaptability. Healthy parenting skills are important to promote positive, stable relationships between family members. If you are or one day become a parent, it will be important to consider supports that best fit your needs so you can experience parenting success. Providing consistent, stable support for parents with FASD can help promote healthy relationships.
    </p>
  </>;

export default Blurb11_2D;
