import React from 'react';

const Blurb5_4D = () =>
  <>
    <p>
      Your language and communication skills have been assessed and identified as an area of need. This means that you may struggle to express yourself verbally and/or may have difficulty consistently understanding others – especially when verbal exchanges are fast paced, which is typical within social interactions and school-based or training and work environments. Sometimes individuals with FASD can effectively mask these challenges by repeating familiar language, controlling conversations, and simply agreeing with others and following along with peers – particularly as an adult. You may also sometimes feel overwhelmed by verbal communication and quickly dysregulate, become angry, or avoid others.
    </p>
    <p>
      Advocating for your communication needs in school and work environments will help others to understand how to best understand and work with you, and to address your needs and learning goals. Trying out different communication strategies, for example short concrete texts instead of long phone conversations, can be helpful.
    </p>
  </>;

export default Blurb5_4D;
