import React from 'react';

const Blurb9_3 = () =>
  <>
    <p>
      You/your teen may have struggled to find and keep meaningful work in a job that fits well with your unique strengths and abilities. Employment best suited to each person depends on their values, interests, and goals, so learning what fits your unique strengths and needs will be helpful.
    </p>
  </>;

export default Blurb9_3;
