import React from 'react';

const Blurb5_2A = () =>
  <>
    <p>
      To help build your child’s capacity in the pre-school setting, access to <span className='text-bold'>FASD training</span> for your child’s educators and other supports may help to build skills to create well suited environments. The Canada FASD Research Network offers free online FASD resources and training for educators: <a href='https://estore.canfasd.ca/foundations-in-fasd'>
      https://estore.canfasd.ca/foundations-in-fasd</a>
    </p>
    <p>
      Sharing information about your child’s unique needs may further help to identify approaches best suited to your child. A shared understanding and consistent communication between home and school can help ensure supports are implemented. This also creates opportunities to highlight strengths and creatively consider opportunities for success within the school setting. This kind of approach can not only help support your child&apos;s early learning, but also promote self-confidence and growth within the school setting.
    </p>
  </>;

export default Blurb5_2A;
