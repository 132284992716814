import React from 'react';

const Blurb3_10B = () =>
  <>
    <p>
      Healthy attachment and relationships are important ingredients for intervention for individuals with FASD across the lifespan. Supporting families to navigate stress and areas of need, listening to caregivers, and engaging family, are all important in leveraging and maintaining family stability while promoting your child’s strengths and supporting their needs. Families and caregivers are excellent sources of information related to your child’s strengths and resiliency factors that may be incorporated in success planning.
    </p>
    <p>
      To help your child’s attachment and relationships, continuous, proactive support and ongoing monitoring will be important. Understanding the role that family and natural networks of support will play in your child’s life as they get older and transition to school and beyond will also be helpful. Consider some key goals for continued interactions or support that will encourage familial cohesion and support that is best suited for your child and their family.
    </p>
  </>;

export default Blurb3_10B;
