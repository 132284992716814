import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Variables from '../components/Variables';
import Blurb14_1A from '../../../blurbs/14_ClinicianTearoff/Blurb14_1A';
import Blurb14_1B from '../../../blurbs/14_ClinicianTearoff/Blurb14_1B';
import Blurb14_1C from '../../../blurbs/14_ClinicianTearoff/Blurb14_1C';
import Blurb14_1D from '../../../blurbs/14_ClinicianTearoff/Blurb14_1D';
import Blurb14_1E from '../../../blurbs/14_ClinicianTearoff/Blurb14_1E';
import Blurb14_2A from '../../../blurbs/14_ClinicianTearoff/Blurb14_2A';
import Blurb14_2B from '../../../blurbs/14_ClinicianTearoff/Blurb14_2B';
import Blurb14_2C from '../../../blurbs/14_ClinicianTearoff/Blurb14_2C';
import Blurb14_2D from '../../../blurbs/14_ClinicianTearoff/Blurb14_2D';
import Blurb14_2E from '../../../blurbs/14_ClinicianTearoff/Blurb14_2E';
import Blurb14_3A from '../../../blurbs/14_ClinicianTearoff/Blurb14_3A';
import Blurb14_3B from '../../../blurbs/14_ClinicianTearoff/Blurb14_3B';
import Blurb14_3C from '../../../blurbs/14_ClinicianTearoff/Blurb14_3C';
import Blurb14_3D from '../../../blurbs/14_ClinicianTearoff/Blurb14_3D';
import Blurb14_3E from '../../../blurbs/14_ClinicianTearoff/Blurb14_3E';
import Blurb14_4A from '../../../blurbs/14_ClinicianTearoff/Blurb14_4A';
import Blurb14_4B from '../../../blurbs/14_ClinicianTearoff/Blurb14_4B';
import Blurb14_4C from '../../../blurbs/14_ClinicianTearoff/Blurb14_4C';
import Blurb14_4D from '../../../blurbs/14_ClinicianTearoff/Blurb14_4D';
import Blurb14_4E from '../../../blurbs/14_ClinicianTearoff/Blurb14_4E';
import Blurb14_8 from '../../../blurbs/14_ClinicianTearoff/Blurb14_8';
import Blurb14_5 from '../../../blurbs/14_ClinicianTearoff/Blurb14_5';
import Blurb14_6C from '../../../blurbs/14_ClinicianTearoff/Blurb14_6C';
import Blurb14_6B from '../../../blurbs/14_ClinicianTearoff/Blurb14_6B';
import Blurb14_6D from '../../../blurbs/14_ClinicianTearoff/Blurb14_6D';
import Blurb14_7B from '../../../blurbs/14_ClinicianTearoff/Blurb14_7B';
import Blurb14_7C from '../../../blurbs/14_ClinicianTearoff/Blurb14_7C';
import Blurb14_7D from '../../../blurbs/14_ClinicianTearoff/Blurb14_7D';
import TwoColumnLayout from './TwoColumnLayout';
import PaddedPrintLayout from '../../../components/PaddedPrintLayout';

const ClinicianTearoff = () => {
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {iq} = useSelector(state => state.forms[FORMS.IQ_SCORE.id]);
  const {intellectual} = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);
  const {cognition_brain, attention_brain, adaptive_brain, executive_brain, language_brain, memory_brain, academic_brain} = useSelector(state => state.forms[FORMS.BRAIN_DOMAIN_ASSESSMENT.id]);
  const variables = {age, diagnosis, iq, intellectual, cognition_brain, adaptive_brain, attention_brain,
    executive_brain, language_brain, memory_brain, academic_brain};
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS.id]);

  const ClinicianTearoffsBlurbs14_1A = age === '1' && diagnosis === '0';
  const ClinicianTearoffsBlurbs14_1B = age === '2' && diagnosis === '0';
  const ClinicianTearoffsBlurbs14_1C = age === '3' && diagnosis === '0';
  const ClinicianTearoffsBlurbs14_1D = age === '4' && diagnosis === '0';
  const ClinicianTearoffsBlurbs14_1E = age === '5' && diagnosis === '0';

  const ClinicianTearoffsBlurbs14_2A = age === '1' && diagnosis === '1';
  const ClinicianTearoffsBlurbs14_2B = age === '2' && diagnosis === '1';
  const ClinicianTearoffsBlurbs14_2C = age === '3' && diagnosis === '1';
  const ClinicianTearoffsBlurbs14_2D = age === '4' && diagnosis === '1';
  const ClinicianTearoffsBlurbs14_2E = age === '5' && diagnosis === '1';

  const ClinicianTearoffsBlurbs14_3A = age === '1' && diagnosis === '2';
  const ClinicianTearoffsBlurbs14_3B = age === '2' && diagnosis === '2';
  const ClinicianTearoffsBlurbs14_3C = age === '3' && diagnosis === '2';
  const ClinicianTearoffsBlurbs14_3D = age === '4' && diagnosis === '2';
  const ClinicianTearoffsBlurbs14_3E = age === '5' && diagnosis === '2';

  const ClinicianTearoffsBlurbs14_4A = age === '1' && diagnosis === '3';
  const ClinicianTearoffsBlurbs14_4B = age === '2' && diagnosis === '3';
  const ClinicianTearoffsBlurbs14_4C = age === '3' && diagnosis === '3';
  const ClinicianTearoffsBlurbs14_4D = age === '4' && diagnosis === '3';
  const ClinicianTearoffsBlurbs14_4E = age === '5' && diagnosis === '3';

  const ClinicianTearoffsBlurbs14_6B = (attention_brain === '1' && executive_brain === '1' ||
    adaptive_brain === '1' && executive_brain === '1' ||
    adaptive_brain === '1' && attention_brain === '1') &&
    ((iq === '1' || iq === '2' || iq === '3') && intellectual === '0' && cognition_brain === '0') &&
    age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const ClinicianTearoffsBlurbs14_6C = (attention_brain === '1' && executive_brain === '1' ||
    adaptive_brain === '1' && executive_brain === '1' ||
    adaptive_brain === '1' && attention_brain === '1') &&
    ((iq === '1' || iq === '2' || iq === '3') && intellectual === '0' && cognition_brain === '0') &&
    age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const ClinicianTearoffsBlurbs14_6D = (attention_brain === '1' && executive_brain === '1' ||
    adaptive_brain === '1' && executive_brain === '1' ||
    adaptive_brain === '1' && attention_brain === '1') &&
    ((iq === '1' || iq === '2' || iq === '3') && intellectual === '0' && cognition_brain === '0') &&
    age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const ClinicianTearoffsBlurbs14_7B = cognition_brain === '1' && adaptive_brain === '1' && academic_brain === '1' &&
    (language_brain === '1' && memory_brain === '1' ||
      memory_brain === '1' && attention_brain === '1' ||
      attention_brain === '1' && language_brain === '1') &&
    age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const ClinicianTearoffsBlurbs14_7C = cognition_brain === '1' && adaptive_brain === '1' && academic_brain === '1' &&
    (language_brain === '1' && memory_brain === '1' ||
      memory_brain === '1' && attention_brain === '1' ||
      attention_brain === '1' && language_brain === '1') &&
    age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const ClinicianTearoffsBlurbs14_7D = cognition_brain === '1' && adaptive_brain === '1' && academic_brain === '1' &&
    (language_brain === '1' && memory_brain === '1' ||
      memory_brain === '1' && attention_brain === '1' ||
      attention_brain === '1' && language_brain === '1') &&
    age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const ClinicianTearoffsBlurbs14_8 = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  return <PaddedPrintLayout>
    <section>
      <Variables variableObject={variables}/>
      <SectionTitle>Tailored Feedback - Clinician</SectionTitle>
      {/* This section will always be shown because Blurb14_5 will always be shown. */}
      <Card>
        <h3 className='chapter-title'>
        My Unique <span className='card-title'>Needs</span>
        </h3>
        <TwoColumnLayout>
          <>
            {ClinicianTearoffsBlurbs14_1A && <Blurb14_1A/>}
            {ClinicianTearoffsBlurbs14_1B && <Blurb14_1B/>}
            {ClinicianTearoffsBlurbs14_1C && <Blurb14_1C/>}
            {ClinicianTearoffsBlurbs14_1D && <Blurb14_1D/>}
            {ClinicianTearoffsBlurbs14_1E && <Blurb14_1E/>}
          </>
          <>
            {ClinicianTearoffsBlurbs14_2A && <Blurb14_2A/>}
            {ClinicianTearoffsBlurbs14_2B && <Blurb14_2B/>}
            {ClinicianTearoffsBlurbs14_2C && <Blurb14_2C/>}
            {ClinicianTearoffsBlurbs14_2D && <Blurb14_2D/>}
            {ClinicianTearoffsBlurbs14_2E && <Blurb14_2E/>}
          </>
          <>
            {ClinicianTearoffsBlurbs14_3A && <Blurb14_3A/>}
            {ClinicianTearoffsBlurbs14_3B && <Blurb14_3B/>}
            {ClinicianTearoffsBlurbs14_3C && <Blurb14_3C/>}
            {ClinicianTearoffsBlurbs14_3D && <Blurb14_3D/>}
            {ClinicianTearoffsBlurbs14_3E && <Blurb14_3E/>}
          </>
          <>
            {ClinicianTearoffsBlurbs14_4A && <Blurb14_4A/>}
            {ClinicianTearoffsBlurbs14_4B && <Blurb14_4B/>}
            {ClinicianTearoffsBlurbs14_4C && <Blurb14_4C/>}
            {ClinicianTearoffsBlurbs14_4D && <Blurb14_4D/>}
            {ClinicianTearoffsBlurbs14_4E && <Blurb14_4E/>}
          </>
          <>
            <Blurb14_5/>
          </>
          {
            formData.my_unique_needs && <p>
              {formData.my_unique_needs}
            </p>
          }
        </TwoColumnLayout>
      </Card>
      {
        (ClinicianTearoffsBlurbs14_6B || ClinicianTearoffsBlurbs14_6C || ClinicianTearoffsBlurbs14_6D ||
        ClinicianTearoffsBlurbs14_7B || ClinicianTearoffsBlurbs14_7C || ClinicianTearoffsBlurbs14_7D ||
        ClinicianTearoffsBlurbs14_8 || formData.my_unique_brain2) &&
        <Card>
          <h3 className='chapter-title'>
            My Unique <span className='card-title'>Brain</span>
          </h3>
          <>
            {ClinicianTearoffsBlurbs14_6B && <Blurb14_6B/>}
            {ClinicianTearoffsBlurbs14_6C && <Blurb14_6C/>}
            {ClinicianTearoffsBlurbs14_6D && <Blurb14_6D/>}
          </>
          <>
            {ClinicianTearoffsBlurbs14_7B && <Blurb14_7B/>}
            {ClinicianTearoffsBlurbs14_7C && <Blurb14_7C/>}
            {ClinicianTearoffsBlurbs14_7D && <Blurb14_7D/>}
          </>
          <>
            {ClinicianTearoffsBlurbs14_8 && <Blurb14_8/>}
          </>
          {
            formData.my_unique_brain2 && <p>
              {formData.my_unique_brain2}
            </p>
          }
        </Card>
      }
    </section>
  </PaddedPrintLayout>;
};

export default ClinicianTearoff;
