import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const BrainDomainAccessmentForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.BRAIN_DOMAIN_ASSESSMENT.id]);
  const dispatch = useDispatch();
  const brainDomainAssessments = FORMS.BRAIN_DOMAIN_ASSESSMENT.fields;
  const fieldOptions = FORMS.BRAIN_DOMAIN_ASSESSMENT.options;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] !== '');
  const isFormComplete = checkedFields.length === Object.keys(formData).length;

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.BRAIN_DOMAIN_ASSESSMENT.id, updatedFormData));
  };

  return (
    <>
      <form>
        <div className='lead group-name'>Brain Domain Assessment Results</div>
        <label className='form-field padding-bottom-2'>Please indicate how the following brain domain was
          assessed</label>
        <div className='grid-x grid-margin-y'>
          <div className='cell large-7'>
            <div className='grid-x'>
              <div className='cell small-4'/>
              <div className='cell small-8'>
                <div className='grid-x'>
                  {
                    Object.values(fieldOptions).map(fieldOption =>
                      <div
                        className='auto cell text-center'
                        key={fieldOption}>
                        <label className='form-field'>{fieldOption}</label>
                      </div>)
                  }
                </div>
              </div>
            </div>
            {Object.keys(brainDomainAssessments).map(assessmentKey =>
              <div
                className='grid-x'
                key={assessmentKey}>
                <div className='cell small-4'>
                  <label className='form-field'>{brainDomainAssessments[assessmentKey].title}</label>
                </div>
                <div className='cell small-8'>
                  <div className='grid-x'>
                    {Object.keys(fieldOptions).map(optionKey =>
                      <div
                        className='auto cell text-center'
                        key={optionKey}>
                        <input
                          checked={formData[assessmentKey] === optionKey}
                          className='margin-right-0'
                          id={`answer_${optionKey}`}
                          name={assessmentKey}
                          onChange={event => handleFormChange({...formData, [assessmentKey]: event.target.value})}
                          type='radio'
                          value={optionKey}
                        />
                      </div>)}
                  </div>
                </div>
              </div>)}
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

BrainDomainAccessmentForm.propTypes = {
  formIndex: PropTypes.number
};

export default BrainDomainAccessmentForm;
