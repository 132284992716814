import React from 'react';

const Blurb2_11 = () =>
  <>
    <p>
      Difficulties in visual functioning may include difficulties seeing or processing information visually. Providing necessary visual supports and accommodations will help support overall physical wellbeing and promote accessibility.
    </p>
  </>;

export default Blurb2_11;
