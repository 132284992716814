export const config = {
  app: {
    environment: process.env.REACT_APP_ENVIRONMENT || 'development',
    version: process.env.REACT_APP_VERSION || new Date().toISOString()
  },
  google: {
    analytics: {
      measurementID: process.env.REACT_APP_GA_MEASUREMENT_ID || 'ga-measurement-id'
    }
  },
  redcap: {
    proxy: {
      url: '/proxy/redcap'
    }
  },
  rollbar: {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || 'rollbar-access-token'
  }
};
