import React from 'react';
import {resetApp} from '../../redux/actions/app';
import ROUTES from '../../routes';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {config} from '../../config';
import logo from '../../assets/Images/logo.png';
import FORMS from '../../redux/constants';
import dateToday from './DateToday';

const Topbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // Extract the dynamic base path from ROUTES.FORMS
  const getFormBasePath = route => {
    // Assuming route is something like "/form/:url"
    const paramIndex = route.indexOf(':');
    return paramIndex === -1 ? route : route.substring(0, paramIndex);
  };

  const formBasePath = getFormBasePath(ROUTES.FORMS);

  const getCurrentForm = pathname => {
    // Remove the base path from the current pathname
    const pathWithoutBase = pathname.replace(formBasePath, '');
    // Find the matching form using the modified path
    return Object.values(FORMS).find(form => form.url === pathWithoutBase);
  };

  const currentForm = getCurrentForm(location.pathname);
  const currentFormHeader = currentForm?.header;

  const handleFormReset = () => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure you want to reset the entire form?')) {
      dispatch(resetApp());
      navigate(ROUTES.ROOT);
    }
  };

  const handleVariableToggle = () => {
    const elements = document.querySelectorAll('[data-variables="true"]');

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.toggle('is-hidden');
    }
  };

  if (location.pathname === ROUTES.REPORT) {
    return <div className='grid-container'>
      <div className='grid-x grid-margin-x align-justify'>
        <div className='cell small-12 large-5 large-offset-1 shadow topbar'>
          <div className='top-bar-report'>
            <img
              alt='logo of the website'
              className='cover-logo'
              src={logo}
              width='70px'/>
            <h1 className='cover-title'>MY COMPASS</h1>
            <div className='cover-text'>
              {dateToday()}
            </div>
          </div>
        </div>
        <div className='shrink cell padding-vertical-1 hide-for-print'>
          <div className='grid-x grid-margin-x align-middle'>
            {config.app.environment !== 'production' && <div className='shrink cell'>
              <div className='grid-x'>
                <div className='shrink cell'>
                  <div className='switch large'>
                    <input
                      className='switch-input'
                      id='toggle-variables'
                      onChange={handleVariableToggle}
                      type='checkbox'/>
                    <label
                      className='switch-paddle'
                      htmlFor='toggle-variables'>
                      <span className='show-for-sr'>Do you like me?</span>
                      <span
                        aria-hidden='true'
                        className='switch-active'>Yes</span>
                      <span
                        aria-hidden='true'
                        className='switch-inactive'>No</span>
                    </label>
                  </div>
                </div>
                <div className='shrink cell'>
                  <label
                    className='middle'
                    htmlFor='toggle-variables'>&nbsp;Show Variables</label>
                </div>
              </div>
            </div>}
            <div className='auto cell'>
              <a
                className='button'
                onClick={() => window.print()}>Print/Save in PDF
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }

  return <div className='top-bar beige-background'>
    <div className='grid-container width-100'>
      <div className='grid-x grid-margin-x align-middle'>
        <div className='cell auto beige-background'>
          <div className='grid-x grid-margin-x align-middle'>
            <div className='cell shrink'>
              <img
                alt='logo of the website'
                src={logo}
                width='70px'/>
            </div>
            <div className='cell auto'>
              {currentFormHeader && <div className='menu-text'>{currentFormHeader}</div>}
            </div>
          </div>
        </div>
        <div className='cell shrink'>
          <div className='grid-y'>
            <div className='cell'>
              {location.pathname !== ROUTES.ROOT && <button
                className='button reset-button width-100'
                onClick={handleFormReset}
                type='button'
              >Reset Form</button>}
            </div>
            {config.app.version && <div className='cell'>
              <div className='text-right line-height-1 margin-0 version-date-text'>
                Version Date:<br/>
                {new Date(config.app.version).toLocaleString()}
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default Topbar;
