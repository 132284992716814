import React from 'react';

const Blurb3_1E = () =>
  <>
    <p>
      Attachment describes the emotional bonds between you/your child and the people in your/their life. When we experience safe and stable connections to others, we experience security. We gain confidence that we can safely explore and experience the world around us. Early bonding experiences are foundational to our development as they affect our later relationships and our ability to trust others, shape our beliefs about our own value and self-esteem, and help us manage our emotional responses and regulate our emotions more effectively. Knowing this, we can work toward promoting health and long-lasting attachments.
    </p>
    <p>
      For yourself/your child with FASD, this may mean supporting family cohesion and identifying individuals in your/their life that may fill an attachment role. This also involves understanding any possible long-term impacts of your/your child’s early attachment experiences, while also recognizing that later attachment experiences can also be impactful.
    </p>
  </>;

export default Blurb3_1E;
