import React from 'react';
import PropTypes from 'prop-types';

const SectionTitle = ({children}) =>
  <h2 className='section-title'>
    {children}
  </h2>;
SectionTitle.propTypes = {
  children: PropTypes.any.isRequired
};

export default SectionTitle;
