import React from 'react';

const Blurb4_4C = () =>
  <>
    <p>
      Monitoring and supporting the development of your teen’s social skills will be important, particularly as they begin to transition to high school and beyond when social expectations grow. Planning for social skill-building opportunities during later life stages is encouraged.
    </p>
    <p>
      Community engagement can also provide opportunities where your teen can practice social and related skills and build on existing strengths.
    </p>
  </>;

export default Blurb4_4C;
