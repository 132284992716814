import React from 'react';

const Blurb8_8E = () =>
  <>
    <p>
      Trauma occurs when an individual experiences a distressing or disturbing event with an actual or perceived threat to their safety and well-being. Trauma can include experiences that are passed down through family generations. Experiencing trauma can have long-term effects including impacts to mental health, social functioning, academic achievement, self-regulation, and caregiver relationships. Trauma informed therapy can be helpful to navigate the impacts of trauma on your/your child’s overall mental wellness.
    </p>
  </>;

export default Blurb8_8E;
