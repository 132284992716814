import React from 'react';
import SectionTitle from '../components/SectionTitle';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Variables from '../components/Variables';
import Blurb2_1A from '../../../blurbs/2_Health/Blurb2_1A';
import Blurb2_1B from '../../../blurbs/2_Health/Blurb2_1B';
import Blurb2_1C from '../../../blurbs/2_Health/Blurb2_1C';
import Blurb2_1D from '../../../blurbs/2_Health/Blurb2_1D';
import Blurb2_1E from '../../../blurbs/2_Health/Blurb2_1E';
import Blurb2_2A from '../../../blurbs/2_Health/Blurb2_2A';
import Blurb2_2B from '../../../blurbs/2_Health/Blurb2_2B';
import Blurb2_2C from '../../../blurbs/2_Health/Blurb2_2C';
import Blurb2_2D from '../../../blurbs/2_Health/Blurb2_2D';
import Blurb2_2E from '../../../blurbs/2_Health/Blurb2_2E';
import Blurb2_3 from '../../../blurbs/2_Health/Blurb2_3';
import Blurb2_4 from '../../../blurbs/2_Health/Blurb2_4';
import Blurb2_5A from '../../../blurbs/2_Health/Blurb2_5A';
import Blurb2_5B from '../../../blurbs/2_Health/Blurb2_5B';
import Blurb2_5C from '../../../blurbs/2_Health/Blurb2_5C';
import Blurb2_5D from '../../../blurbs/2_Health/Blurb2_5D';
import Blurb2_5E from '../../../blurbs/2_Health/Blurb2_5E';
import Blurb2_6A from '../../../blurbs/2_Health/Blurb2_6A';
import Blurb2_6B from '../../../blurbs/2_Health/Blurb2_6B';
import Blurb2_6C from '../../../blurbs/2_Health/Blurb2_6C';
import Blurb2_6D from '../../../blurbs/2_Health/Blurb2_6D';
import Blurb2_6E from '../../../blurbs/2_Health/Blurb2_6E';
import Blurb2_7A from '../../../blurbs/2_Health/Blurb2_7A';
import Blurb2_7B from '../../../blurbs/2_Health/Blurb2_7B';
import Blurb2_7C from '../../../blurbs/2_Health/Blurb2_7C';
import Blurb2_7D from '../../../blurbs/2_Health/Blurb2_7D';
import Blurb2_7E from '../../../blurbs/2_Health/Blurb2_7E';
import Blurb2_9 from '../../../blurbs/2_Health/Blurb2_9';
import Blurb2_11 from '../../../blurbs/2_Health/Blurb2_11';
import Blurb2_10 from '../../../blurbs/2_Health/Blurb2_10';
import Blurb2_12A from '../../../blurbs/2_Health/Blurb2_12A';
import Blurb2_12B from '../../../blurbs/2_Health/Blurb2_12B';
import Blurb2_12C from '../../../blurbs/2_Health/Blurb2_12C';
import Blurb2_12D from '../../../blurbs/2_Health/Blurb2_12D';
import Blurb2_12E from '../../../blurbs/2_Health/Blurb2_12E';
import Card from '../components/Card';
import TwoColumnLayout from './TwoColumnLayout';

const Health = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {meds_rec, medicalreferral} = useSelector(state => state.forms[FORMS.RECOMMENDATIONS.id]);
  const {motorskills_brain} = useSelector(state => state.forms[FORMS.BRAIN_DOMAIN_ASSESSMENT.id]);
  const {dcd, congenital, auditory, visual} = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);
  const {associatedfeatures___0, associatedfeatures___1, associatedfeatures___2} = useSelector(state => state.forms[FORMS.OTHER_FEATURES.id]);
  const {stimulant1, antidepressant1, antipsycho1, birthcontrol1, hrt1, antihypertensive1, anticonvulsant1} = useSelector(state => state.forms[FORMS.OTHER_CURRENT_MEDICATION.id]);
  const {omega3, choline, glutamine, aripiprazole, vortioxetine, minocycline, bupropion, buspirone, clozapine, melatonin} = useSelector(state => state.forms[FORMS.MEDICATIONS.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);

  const variables = {diagnosis, meds_rec, motorskills_brain, dcd, congenital, auditory, visual,
    associatedfeatures___0, associatedfeatures___1, associatedfeatures___2, stimulant1, antidepressant1,
    antipsycho1, birthcontrol1, hrt1, antihypertensive1, anticonvulsant1, omega3, choline,
    glutamine, aripiprazole, vortioxetine, minocycline, bupropion, buspirone, clozapine, melatonin, age};

  const HealthBlurb2_1A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_1B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_1C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_1D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_1E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const HealthBlurb2_2A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_2B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_2C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_2D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_2E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const HealthBlurb2_3 = (meds_rec === '1' || omega3 === '1' || choline === '1' || glutamine === '1' || aripiprazole === '1' ||
      vortioxetine === '1' || minocycline === '1' || bupropion === '1' || buspirone === '1' || clozapine === '1' ||
      melatonin === '1' || stimulant1 !== '' || antidepressant1 !== '' || antipsycho1 !== '' ||
      birthcontrol1 !== '' || hrt1 !== '' || antihypertensive1 !== '' || anticonvulsant1 !== '') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const HealthBlurb2_4 = medicalreferral === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const HealthBlurb2_5A = (associatedfeatures___1 === '1' || associatedfeatures___2 === '1') && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_5B = (associatedfeatures___1 === '1' || associatedfeatures___2 === '1') && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_5C = (associatedfeatures___1 === '1' || associatedfeatures___2 === '1') && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_5D = (associatedfeatures___1 === '1' || associatedfeatures___2 === '1') && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_5E = (associatedfeatures___1 === '1' || associatedfeatures___2 === '1') && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const HealthBlurb2_6A = (motorskills_brain === '1' || dcd === '1') &&
    age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_6B = (motorskills_brain === '1' || dcd === '1') &&
    age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_6C = (motorskills_brain === '1' || dcd === '1') &&
    age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_6D = (motorskills_brain === '1' || dcd === '1') &&
    age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_6E = (motorskills_brain === '1' || dcd === '1') &&
    age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const HealthBlurb2_7A = associatedfeatures___0 === '1' &&
    age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_7B = associatedfeatures___0 === '1' &&
    age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_7C = associatedfeatures___0 === '1' &&
    age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_7D = associatedfeatures___0 === '1' &&
    age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_7E = associatedfeatures___0 === '1' &&
    age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const HealthBlurb2_9 = congenital === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_10 = auditory === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_11 = visual === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const HealthBlurb2_12A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_12B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_12C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_12D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HealthBlurb2_12E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  return <section>
    <SectionTitle>Physical Wellbeing</SectionTitle>
    <Variables variableObject={variables}/>
    {
      (HealthBlurb2_1A || HealthBlurb2_1B || HealthBlurb2_1C || HealthBlurb2_1D || HealthBlurb2_1E) &&
      <Card>
        <h3 className='chapter-title'>
          Things to <span className='card-title'>Know</span>
        </h3>
        {HealthBlurb2_1A && <Blurb2_1A/>}
        {HealthBlurb2_1B && <Blurb2_1B/>}
        {HealthBlurb2_1C && <Blurb2_1C/>}
        {HealthBlurb2_1D && <Blurb2_1D/>}
        {HealthBlurb2_1E && <Blurb2_1E/>}
      </Card>
    }
    <>
      {
        (HealthBlurb2_2A || HealthBlurb2_2B || HealthBlurb2_2C || HealthBlurb2_2D || HealthBlurb2_2E ||
          HealthBlurb2_3 || HealthBlurb2_4 ||
          HealthBlurb2_5A || HealthBlurb2_5B || HealthBlurb2_5C || HealthBlurb2_5D || HealthBlurb2_5E ||
          HealthBlurb2_6A || HealthBlurb2_6B || HealthBlurb2_6C || HealthBlurb2_6D || HealthBlurb2_6E ||
          HealthBlurb2_7A || HealthBlurb2_7B || HealthBlurb2_7C || HealthBlurb2_7D || HealthBlurb2_7E ||
          HealthBlurb2_9 || HealthBlurb2_10 || HealthBlurb2_11) &&
        <Card>
          <h3 className='chapter-title'>
            Things to <span className='card-title'>Do Now</span>
          </h3>
          <TwoColumnLayout>
            <>
              {HealthBlurb2_2A && <Blurb2_2A/>}
              {HealthBlurb2_2B && <Blurb2_2B/>}
              {HealthBlurb2_2C && <Blurb2_2C/>}
              {HealthBlurb2_2D && <Blurb2_2D/>}
              {HealthBlurb2_2E && <Blurb2_2E/>}
            </>
            <>
              {HealthBlurb2_3 && <Blurb2_3/>}
              {HealthBlurb2_4 && <Blurb2_4/>}
            </>
            <>
              {HealthBlurb2_5A && <Blurb2_5A/>}
              {HealthBlurb2_5B && <Blurb2_5B/>}
              {HealthBlurb2_5C && <Blurb2_5C/>}
              {HealthBlurb2_5D && <Blurb2_5D/>}
              {HealthBlurb2_5E && <Blurb2_5E/>}
            </>
            <>
              {HealthBlurb2_6A && <Blurb2_6A/>}
              {HealthBlurb2_6B && <Blurb2_6B/>}
              {HealthBlurb2_6C && <Blurb2_6C/>}
              {HealthBlurb2_6D && <Blurb2_6D/>}
              {HealthBlurb2_6E && <Blurb2_6E/>}
            </>
            <>
              {HealthBlurb2_7A && <Blurb2_7A/>}
              {HealthBlurb2_7B && <Blurb2_7B/>}
              {HealthBlurb2_7C && <Blurb2_7C/>}
              {HealthBlurb2_7D && <Blurb2_7D/>}
              {HealthBlurb2_7E && <Blurb2_7E/>}
            </>
            <>
              {HealthBlurb2_9 && <Blurb2_9/>}
              {HealthBlurb2_10 && <Blurb2_10/>}
              {HealthBlurb2_11 && <Blurb2_11/>}
            </>
            {/* <p style={{ */}
            {/*   visibility: 'hidden' */}
            {/* }}>Through</p> */}
            <br/>
          </TwoColumnLayout>
        </Card>
      }
      {
        (HealthBlurb2_12A || HealthBlurb2_12B || HealthBlurb2_12C || HealthBlurb2_12D || HealthBlurb2_12E) &&
        <Card>
          <h3 className='chapter-title'>
            Things to <span className='card-title'>Plan</span> for
          </h3>
          <>
            {HealthBlurb2_12A && <Blurb2_12A/>}
            {HealthBlurb2_12B && <Blurb2_12B/>}
            {HealthBlurb2_12C && <Blurb2_12C/>}
            {HealthBlurb2_12D && <Blurb2_12D/>}
            {HealthBlurb2_12E && <Blurb2_12E/>}
          </>
        </Card>
      }
    </>
  </section>;
};

export default Health;
