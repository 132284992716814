import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const RecommendationsForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.RECOMMENDATIONS.id]);
  const dispatch = useDispatch();
  const recommendations = FORMS.RECOMMENDATIONS.fields;
  const fieldOptions = FORMS.RECOMMENDATIONS.options;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] !== '');
  const isFormComplete = checkedFields.length === Object.keys(formData).length;

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.RECOMMENDATIONS.id, updatedFormData));
  };

  return (
    <>
      <div className='lead group-name'>Which recommendations were made for the individual being assessed?</div>
      <form>
        <div className='grid-x grid-margin-y'>
          <div className='cell large-9'>
            <div className='grid-x'>
              <div className='cell small-4'/>
              <div className='cell small-8'>
                <div className='grid-x'>
                  {
                    Object.values(fieldOptions).map(option =>
                      <div
                        className='auto cell text-center'
                        key={option}>
                        <label className='form-field'>{option}</label>
                      </div>)
                  }
                </div>
              </div>
            </div>
            {Object.keys(recommendations).map(recommendationKey =>
              <div
                className='grid-x'
                key={recommendationKey}>
                <div className='cell small-4'>
                  <label className='form-field'>{recommendations[recommendationKey].title}</label>
                </div>
                <div className='cell small-8'>
                  <div
                    className='grid-x'>
                    {Object.keys(fieldOptions).map(optionKey =>
                      <div
                        className='auto cell text-center'
                        key={optionKey}>
                        <input
                          checked={formData[recommendationKey] === optionKey}
                          className='margin-right-0'
                          id={`answer_${optionKey}`}
                          key={optionKey}
                          name={recommendationKey}
                          onChange={event => handleFormChange({...formData, [recommendationKey]: event.target.value})}
                          type='radio'
                          value={optionKey}
                        />
                      </div>)}
                  </div>
                </div>
              </div>)}
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

RecommendationsForm.propTypes = {
  formIndex: PropTypes.number
};

export default RecommendationsForm;
