import React from 'react';

const Blurb7_25 = () =>
  <>
    <p>
      Developmentally appropriate opportunities for choice with support help to promote interdependence and healthy decision-making. Guardianship is one way of supporting healthy decision-making. A guardian has legal authority and responsibility to make decisions on behalf of another person, such as about healthcare or living arrangements. Some people with FASD can benefit from guardianships with caregivers and trusted others who are FASD-informed to ensure appropriate planning for future decisions.
    </p>
  </>;

export default Blurb7_25;
