import {v4 as uuidv4} from 'uuid';
import FORMS from '../../redux/constants';

const ConvertFormStateToFormData = form => {
  const record = [{
    record_id: uuidv4(),
    surveylanguage: '0',
    siteid: form[FORMS.IDENTIFICATION.id].siteid,
    country: form[FORMS.IDENTIFICATION.id].country,
    country_other: form[FORMS.IDENTIFICATION.id].country_other,
    province: form[FORMS.IDENTIFICATION.id].province,
    assessment_type: form[FORMS.ASSESSMENT.id].assessment_type,
    at_risk: form[FORMS.ASSESSMENT.id].at_risk,
    ref_mth: form[FORMS.REFERRAL_DATE.id].ref_mth,
    ref_yr: form[FORMS.REFERRAL_DATE.id].ref_yr,
    ref_source: form[FORMS.REFERRAL_SOURCE.id].ref_source,
    ref_sourcespecify: form[FORMS.REFERRAL_SOURCE.id].ref_sourcespecify,
    ref_reason___0: form[FORMS.REFERRAL_REASON.id].ref_reason___0,
    ref_reason___1: form[FORMS.REFERRAL_REASON.id].ref_reason___1,
    ref_reason___2: form[FORMS.REFERRAL_REASON.id].ref_reason___2,
    ref_reason___3: form[FORMS.REFERRAL_REASON.id].ref_reason___3,
    ref_reason___4: form[FORMS.REFERRAL_REASON.id].ref_reason___4,
    ref_reason___5: form[FORMS.REFERRAL_REASON.id].ref_reason___5,
    ref_reason___6: form[FORMS.REFERRAL_REASON.id].ref_reason___6,
    ref_reason___7: form[FORMS.REFERRAL_REASON.id].ref_reason___7,
    ref_reason___8: form[FORMS.REFERRAL_REASON.id].ref_reason___8,
    ref_reason___9: form[FORMS.REFERRAL_REASON.id].ref_reason___9,
    ref_reason___10: form[FORMS.REFERRAL_REASON.id].ref_reason___10,
    ref_reason___11: form[FORMS.REFERRAL_REASON.id].ref_reason___11,
    ref_reasonspecify: form[FORMS.REFERRAL_REASON.id].ref_reasonspecify,
    screen: form[FORMS.SCREENING_TOOL.id].screen,
    screen_tool: form[FORMS.SCREENING_TOOL.id].screen_tool,
    screen_who: form[FORMS.SCREENING_TOOL.id].screen_who,
    assessment_mth: form[FORMS.DIAGNOSIS_DATE.id].assessment_mth,
    assessment_yr: form[FORMS.DIAGNOSIS_DATE.id].assessment_yr,
    sex: form[FORMS.SEX.id].sex,
    gender: form[FORMS.GENDER_IDENTITY.id].gender,
    gender_specify: form[FORMS.GENDER_IDENTITY.id].gender_specify,
    dob_mth: form[FORMS.DATE_OF_BIRTH.id].dob_mth,
    dob_yr: form[FORMS.DATE_OF_BIRTH.id].dob_yr,
    ethnicity___0: form[FORMS.ETHNICITY.id].ethnicity___0,
    ethnicity___1: form[FORMS.ETHNICITY.id].ethnicity___1,
    ethnicity___2: form[FORMS.ETHNICITY.id].ethnicity___2,
    ethnicity___3: form[FORMS.ETHNICITY.id].ethnicity___3,
    ethnicity___4: form[FORMS.ETHNICITY.id].ethnicity___4,
    ethnicity___5: form[FORMS.ETHNICITY.id].ethnicity___5,
    ethnicity___6: form[FORMS.ETHNICITY.id].ethnicity___6,
    ethnicity___7: form[FORMS.ETHNICITY.id].ethnicity___7,
    ethnicity___8: form[FORMS.ETHNICITY.id].ethnicity___8,
    ethnicity___9: form[FORMS.ETHNICITY.id].ethnicity___9,
    ethnicity___10: form[FORMS.ETHNICITY.id].ethnicity___10,
    ethnicity___11: form[FORMS.ETHNICITY.id].ethnicity___11,
    ethnicity___12: form[FORMS.ETHNICITY.id].ethnicity___12,
    ethnicity___9999: form[FORMS.ETHNICITY.id].ethnicity___9999,
    ethnicity_specify: form[FORMS.ETHNICITY.id].ethnicity_specify,
    living: form[FORMS.CURRENT_LIVING_SITUATION.id].living,
    living_familyspecify: form[FORMS.CURRENT_LIVING_SITUATION.id].living_familyspecify,
    living_specify: form[FORMS.CURRENT_LIVING_SITUATION.id].living_specify,
    parent_diagnosis: form[FORMS.PARENT_DIAGNOSIS.id].parent_diagnosis,
    sibling_diagnosis: form[FORMS.SIBLING_DIAGNOSIS.id].sibling_diagnosis,
    exposure: form[FORMS.PRENATAL_ALCOHOL_EXPOSURE.id].exposure,
    exposure_specify: form[FORMS.PRENATAL_ALCOHOL_EXPOSURE.id].exposure_specify,
    nicotine: form[FORMS.OTHER_PRENATAL_EXPOSURES.id].nicotine,
    opiates: form[FORMS.OTHER_PRENATAL_EXPOSURES.id].opiates,
    marijuana: form[FORMS.OTHER_PRENATAL_EXPOSURES.id].marijuana,
    cocaine: form[FORMS.OTHER_PRENATAL_EXPOSURES.id].cocaine,
    meth: form[FORMS.OTHER_PRENATAL_EXPOSURES.id].meth,
    prescription_meds: form[FORMS.OTHER_PRENATAL_EXPOSURES.id].prescription_meds,
    exposure_other: form[FORMS.OTHER_PRENATAL_EXPOSURES.id].exposure_other,
    exposure_otherspecify: form[FORMS.OTHER_PRENATAL_EXPOSURES.id].exposure_otherspecify,
    factors___0: form[FORMS.OTHER_FACTORS.id].factors___0,
    factors___1: form[FORMS.OTHER_FACTORS.id].factors___1,
    factors___2: form[FORMS.OTHER_FACTORS.id].factors___2,
    factors___3: form[FORMS.OTHER_FACTORS.id].factors___3,
    factors_specify: form[FORMS.OTHER_FACTORS.id].factors_specify,
    fissurenorms___0: form[FORMS.PALPEBRAL_FISSURE_NORMS.id].fissurenorms___0,
    fissurenorms___1: form[FORMS.PALPEBRAL_FISSURE_NORMS.id].fissurenorms___1,
    fissurenorms___2: form[FORMS.PALPEBRAL_FISSURE_NORMS.id].fissurenorms___2,
    fissurenorms___3: form[FORMS.PALPEBRAL_FISSURE_NORMS.id].fissurenorms___3,
    fissurenorms_specify: form[FORMS.PALPEBRAL_FISSURE_NORMS.id].fissurenorms,
    fissurelength: form[FORMS.PALPEBRAL_FISSURE_LENGTH.id].fissurelength,
    philtrum: form[FORMS.PHILTRUM_SMOOTHNESS.id].philtrum,
    upperlip: form[FORMS.LIP_THINNESS.id].upperlip,
    sentinel: form[FORMS.SENTINEL_FEATURES.id].sentinel,
    motorskills_brain: form[FORMS.BRAIN_DOMAIN_ASSESSMENT.id].motorskills_brain,
    neuroanat_brain: form[FORMS.BRAIN_DOMAIN_ASSESSMENT.id].neuroanat_brain,
    cognition_brain: form[FORMS.BRAIN_DOMAIN_ASSESSMENT.id].cognition_brain,
    language_brain: form[FORMS.BRAIN_DOMAIN_ASSESSMENT.id].language_brain,
    academic_brain: form[FORMS.BRAIN_DOMAIN_ASSESSMENT.id].academic_brain,
    memory_brain: form[FORMS.BRAIN_DOMAIN_ASSESSMENT.id].memory_brain,
    attention_brain: form[FORMS.BRAIN_DOMAIN_ASSESSMENT.id].attention_brain,
    executive_brain: form[FORMS.BRAIN_DOMAIN_ASSESSMENT.id].executive_brain,
    regulation_brain: form[FORMS.BRAIN_DOMAIN_ASSESSMENT.id].regulation_brain,
    adaptive_brain: form[FORMS.BRAIN_DOMAIN_ASSESSMENT.id].adaptive_brain,
    iq: form[FORMS.IQ_SCORE.id].iq,
    diagnosis: form[FORMS.DIAGNOSIS.id].diagnosis,
    fasdcode: form[FORMS.DIAGNOSTIC_SCHEMA.id].fasdcode,
    fasd4digit: form[FORMS.DIAGNOSTIC_SCHEMA.id].fasd4digit,
    associatedfeatures___0: form[FORMS.OTHER_FEATURES.id].associatedfeatures___0,
    associatedfeatures___1: form[FORMS.OTHER_FEATURES.id].associatedfeatures___1,
    associatedfeatures___2: form[FORMS.OTHER_FEATURES.id].associatedfeatures___2,
    associatedfeatures___3: form[FORMS.OTHER_FEATURES.id].associatedfeatures___3,
    associatedfeatures___4: form[FORMS.OTHER_FEATURES.id].associatedfeatures___4,
    associatedfeatures___5: form[FORMS.OTHER_FEATURES.id].associatedfeatures___5,
    associatedfeatures___6: form[FORMS.OTHER_FEATURES.id].associatedfeatures___6,
    associatedfeatures___specify: form[FORMS.OTHER_FEATURES.id].associatedfeatures___specify,
    congenital: form[FORMS.OTHER_DIAGNOSIS.id].congenital,
    intellectual: form[FORMS.OTHER_DIAGNOSIS.id].intellectual,
    add: form[FORMS.OTHER_DIAGNOSIS.id].add,
    attachment: form[FORMS.OTHER_DIAGNOSIS.id].attachment,
    dcd: form[FORMS.OTHER_DIAGNOSIS.id].dcd,
    language: form[FORMS.OTHER_DIAGNOSIS.id].language,
    auditory: form[FORMS.OTHER_DIAGNOSIS.id].auditory,
    visual: form[FORMS.OTHER_DIAGNOSIS.id].visual,
    tourette: form[FORMS.OTHER_DIAGNOSIS.id].tourette,
    anxiety: form[FORMS.OTHER_DIAGNOSIS.id].anxiety,
    autism: form[FORMS.OTHER_DIAGNOSIS.id].autism,
    bipolar: form[FORMS.OTHER_DIAGNOSIS.id].bipolar,
    conduct: form[FORMS.OTHER_DIAGNOSIS.id].conduct,
    mood: form[FORMS.OTHER_DIAGNOSIS.id].mood,
    ocd: form[FORMS.OTHER_DIAGNOSIS.id].ocd,
    personality: form[FORMS.OTHER_DIAGNOSIS.id].personality,
    ptsd: form[FORMS.OTHER_DIAGNOSIS.id].ptsd,
    schizophrenia: form[FORMS.OTHER_DIAGNOSIS.id].schizophrenia,
    substanceabuse: form[FORMS.OTHER_DIAGNOSIS.id].growth,
    suicide: form[FORMS.OTHER_DIAGNOSIS.id].suicide,
    odd: form[FORMS.OTHER_DIAGNOSIS.id].odd,
    therdiagnosis: form[FORMS.OTHER_DIAGNOSIS.id].otherdiagnosis,
    otherdiagnosis_specify: form[FORMS.OTHER_DIAGNOSIS.id].otherdiagnosis_specify,
    growth: form[FORMS.MEDICAL_HISTORY.id].growth,
    growth_specify: form[FORMS.MEDICAL_HISTORY.id].growth_specify,
    microcephaly: form[FORMS.MEDICAL_HISTORY.id].microcephaly,
    ftt: form[FORMS.MEDICAL_HISTORY.id].ftt,
    neurological: form[FORMS.MEDICAL_HISTORY.id].neurological,
    neurological_specify: form[FORMS.MEDICAL_HISTORY.id].neurological_specify,
    mental: form[FORMS.MEDICAL_HISTORY.id].mental,
    mental_specify: form[FORMS.MEDICAL_HISTORY.id].mental_specify,
    headneck: form[FORMS.MEDICAL_HISTORY.id].headneck,
    headneck_specify: form[FORMS.MEDICAL_HISTORY.id].headneck_specify,
    cleft: form[FORMS.MEDICAL_HISTORY.id].cleft,
    cardiovascular: form[FORMS.MEDICAL_HISTORY.id].cardiovascular,
    cardiovascular_specify: form[FORMS.MEDICAL_HISTORY.id].cardiovascular_specify,
    respiratory: form[FORMS.MEDICAL_HISTORY.id].respiratory,
    respiratory_specify: form[FORMS.MEDICAL_HISTORY.id].respiratory_specify,
    endocrine: form[FORMS.MEDICAL_HISTORY.id].endocrine,
    endocrine_specify: form[FORMS.MEDICAL_HISTORY.id].endocrine_specify,
    musculoskeletal: form[FORMS.MEDICAL_HISTORY.id].musculoskeletal,
    musculoskeletal_specify: form[FORMS.MEDICAL_HISTORY.id].musculoskeletal_specify,
    infectiousdiseases: form[FORMS.MEDICAL_HISTORY.id].infectiousdiseases,
    infectiousdiseases_specify: form[FORMS.MEDICAL_HISTORY.id].infectiousdiseases_specify,
    medicalother: form[FORMS.MEDICAL_HISTORY.id].medicalother,
    medicalother_specify: form[FORMS.MEDICAL_HISTORY.id].medicalother_specify,
    omega3: form[FORMS.MEDICATIONS.id].omega3,
    choline: form[FORMS.MEDICATIONS.id].choline,
    glutamine: form[FORMS.MEDICATIONS.id].glutamine,
    aripiprazole: form[FORMS.MEDICATIONS.id].aripiprazole,
    vortioxetine: form[FORMS.MEDICATIONS.id].vortioxetine,
    minocycline: form[FORMS.MEDICATIONS.id].minocycline,
    bupropion: form[FORMS.MEDICATIONS.id].bupropion,
    buspirone: form[FORMS.MEDICATIONS.id].buspirone,
    clozapine: form[FORMS.MEDICATIONS.id].clozapine,
    melatonin: form[FORMS.MEDICATIONS.id].melatonin,
    stimulant1: form[FORMS.OTHER_CURRENT_MEDICATION.id].stimulant1,
    stimulant2: form[FORMS.OTHER_CURRENT_MEDICATION.id].stimulant2,
    stimulant3: form[FORMS.OTHER_CURRENT_MEDICATION.id].stimulant3,
    stimulant4: form[FORMS.OTHER_CURRENT_MEDICATION.id].stimulant4,
    stimulant5: form[FORMS.OTHER_CURRENT_MEDICATION.id].stimulant5,
    stimulant6: form[FORMS.OTHER_CURRENT_MEDICATION.id].stimulant6,
    stimulant7: form[FORMS.OTHER_CURRENT_MEDICATION.id].stimulant7,
    stimulant8: form[FORMS.OTHER_CURRENT_MEDICATION.id].stimulant8,
    stimulant9: form[FORMS.OTHER_CURRENT_MEDICATION.id].stimulant9,
    stimulant10: form[FORMS.OTHER_CURRENT_MEDICATION.id].stimulant10,
    antidepressant1: form[FORMS.OTHER_CURRENT_MEDICATION.id].antidepressant1,
    antidepressant2: form[FORMS.OTHER_CURRENT_MEDICATION.id].antidepressant2,
    antidepressant3: form[FORMS.OTHER_CURRENT_MEDICATION.id].antidepressant3,
    antidepressant4: form[FORMS.OTHER_CURRENT_MEDICATION.id].antidepressant4,
    antidepressant5: form[FORMS.OTHER_CURRENT_MEDICATION.id].antidepressant5,
    antidepressant6: form[FORMS.OTHER_CURRENT_MEDICATION.id].antidepressant6,
    antidepressant7: form[FORMS.OTHER_CURRENT_MEDICATION.id].antidepressant7,
    antidepressant8: form[FORMS.OTHER_CURRENT_MEDICATION.id].antidepressant8,
    antidepressant9: form[FORMS.OTHER_CURRENT_MEDICATION.id].antidepressant9,
    antidepressant10: form[FORMS.OTHER_CURRENT_MEDICATION.id].antidepressant10,
    antipsycho1: form[FORMS.OTHER_CURRENT_MEDICATION.id].antipsycho1,
    antipsycho2: form[FORMS.OTHER_CURRENT_MEDICATION.id].antipsycho2,
    antipsycho3: form[FORMS.OTHER_CURRENT_MEDICATION.id].antipsycho3,
    antipsycho4: form[FORMS.OTHER_CURRENT_MEDICATION.id].antipsycho4,
    antipsycho5: form[FORMS.OTHER_CURRENT_MEDICATION.id].antipsycho5,
    antipsycho6: form[FORMS.OTHER_CURRENT_MEDICATION.id].antipsycho6,
    antipsycho7: form[FORMS.OTHER_CURRENT_MEDICATION.id].antipsycho7,
    antipsycho8: form[FORMS.OTHER_CURRENT_MEDICATION.id].antipsycho8,
    antipsycho9: form[FORMS.OTHER_CURRENT_MEDICATION.id].antipsycho9,
    antipsycho10: form[FORMS.OTHER_CURRENT_MEDICATION.id].antipsycho10,
    birthcontrol1: form[FORMS.OTHER_CURRENT_MEDICATION.id].birthcontrol1,
    birthcontrol2: form[FORMS.OTHER_CURRENT_MEDICATION.id].birthcontrol2,
    birthcontrol3: form[FORMS.OTHER_CURRENT_MEDICATION.id].birthcontrol3,
    birthcontrol4: form[FORMS.OTHER_CURRENT_MEDICATION.id].birthcontrol4,
    birthcontrol5: form[FORMS.OTHER_CURRENT_MEDICATION.id].birthcontrol5,
    birthcontrol6: form[FORMS.OTHER_CURRENT_MEDICATION.id].birthcontrol6,
    birthcontrol7: form[FORMS.OTHER_CURRENT_MEDICATION.id].birthcontrol7,
    birthcontrol8: form[FORMS.OTHER_CURRENT_MEDICATION.id].birthcontrol8,
    birthcontrol9: form[FORMS.OTHER_CURRENT_MEDICATION.id].birthcontrol9,
    birthcontrol10: form[FORMS.OTHER_CURRENT_MEDICATION.id].birthcontrol10,
    hrt1: form[FORMS.OTHER_CURRENT_MEDICATION.id].hrt1,
    hrt2: form[FORMS.OTHER_CURRENT_MEDICATION.id].hrt2,
    hrt3: form[FORMS.OTHER_CURRENT_MEDICATION.id].hrt3,
    hrt4: form[FORMS.OTHER_CURRENT_MEDICATION.id].hrt4,
    hrt5: form[FORMS.OTHER_CURRENT_MEDICATION.id].hrt5,
    hrt6: form[FORMS.OTHER_CURRENT_MEDICATION.id].hrt6,
    hrt7: form[FORMS.OTHER_CURRENT_MEDICATION.id].hrt7,
    hrt8: form[FORMS.OTHER_CURRENT_MEDICATION.id].hrt8,
    hrt9: form[FORMS.OTHER_CURRENT_MEDICATION.id].hrt9,
    hrt10: form[FORMS.OTHER_CURRENT_MEDICATION.id].hrt10,
    antihypertensive1: form[FORMS.OTHER_CURRENT_MEDICATION.id].antihypertensive1,
    antihypertensive2: form[FORMS.OTHER_CURRENT_MEDICATION.id].antihypertensive2,
    antihypertensive3: form[FORMS.OTHER_CURRENT_MEDICATION.id].antihypertensive3,
    antihypertensive4: form[FORMS.OTHER_CURRENT_MEDICATION.id].antihypertensive4,
    antihypertensive5: form[FORMS.OTHER_CURRENT_MEDICATION.id].antihypertensive5,
    antihypertensive6: form[FORMS.OTHER_CURRENT_MEDICATION.id].antihypertensive6,
    antihypertensive7: form[FORMS.OTHER_CURRENT_MEDICATION.id].antihypertensive7,
    antihypertensive8: form[FORMS.OTHER_CURRENT_MEDICATION.id].antihypertensive8,
    antihypertensive9: form[FORMS.OTHER_CURRENT_MEDICATION.id].antihypertensive9,
    antihypertensive10: form[FORMS.OTHER_CURRENT_MEDICATION.id].antihypertensive10,
    anticonvulsant1: form[FORMS.OTHER_CURRENT_MEDICATION.id].anticonvulsant1,
    anticonvulsant2: form[FORMS.OTHER_CURRENT_MEDICATION.id].anticonvulsant2,
    anticonvulsant3: form[FORMS.OTHER_CURRENT_MEDICATION.id].anticonvulsant3,
    anticonvulsant4: form[FORMS.OTHER_CURRENT_MEDICATION.id].anticonvulsant4,
    anticonvulsant5: form[FORMS.OTHER_CURRENT_MEDICATION.id].anticonvulsant5,
    anticonvulsant6: form[FORMS.OTHER_CURRENT_MEDICATION.id].anticonvulsant6,
    anticonvulsant7: form[FORMS.OTHER_CURRENT_MEDICATION.id].anticonvulsant7,
    anticonvulsant8: form[FORMS.OTHER_CURRENT_MEDICATION.id].anticonvulsant8,
    anticonvulsant9: form[FORMS.OTHER_CURRENT_MEDICATION.id].anticonvulsant9,
    anticonvulsant10: form[FORMS.OTHER_CURRENT_MEDICATION.id].anticonvulsant10,
    othermeds1: form[FORMS.OTHER_CURRENT_MEDICATION.id].othermeds1,
    othermeds2: form[FORMS.OTHER_CURRENT_MEDICATION.id].othermeds2,
    othermeds3: form[FORMS.OTHER_CURRENT_MEDICATION.id].othermeds3,
    othermeds4: form[FORMS.OTHER_CURRENT_MEDICATION.id].othermeds4,
    othermeds5: form[FORMS.OTHER_CURRENT_MEDICATION.id].othermeds5,
    othermeds6: form[FORMS.OTHER_CURRENT_MEDICATION.id].othermeds6,
    othermeds7: form[FORMS.OTHER_CURRENT_MEDICATION.id].othermeds7,
    othermeds8: form[FORMS.OTHER_CURRENT_MEDICATION.id].othermeds8,
    othermeds9: form[FORMS.OTHER_CURRENT_MEDICATION.id].othermeds9,
    othermeds10: form[FORMS.OTHER_CURRENT_MEDICATION.id].othermeds10,
    alcohol_misuse: form[FORMS.SUBSTANCE.id].alcohol_misuse,
    tobacco_misuse: form[FORMS.SUBSTANCE.id].tobacco_misuse,
    marijuana_misuse: form[FORMS.SUBSTANCE.id].marijuana_misuse,
    opiates_misuse: form[FORMS.SUBSTANCE.id].opiates_misuse,
    solvents_misuse: form[FORMS.SUBSTANCE.id].solvents_misuse,
    crack_misuse: form[FORMS.SUBSTANCE.id].crack_misuse,
    other_misuses: form[FORMS.SUBSTANCE.id].other_misuses,
    other_misuse_specify: form[FORMS.SUBSTANCE.id].other_misuse_specify,
    alcohol_treat: form[FORMS.TREATMENT_ACCESS.id].alcohol_treat,
    tobacco_treat: form[FORMS.TREATMENT_ACCESS.id].tobacco_treat,
    marijuana_treat: form[FORMS.TREATMENT_ACCESS.id].marijuana_treat,
    othersubstances_treat: form[FORMS.TREATMENT_ACCESS.id].othersubstances_treat,
    othersubstances_treat_specify: form[FORMS.TREATMENT_ACCESS.id].othersubstances_treat_specify,
    teacherassistant: form[FORMS.EXPERIENCE.id].teacherassistant,
    expulsion: form[FORMS.EXPERIENCE.id].expulsion,
    employment: form[FORMS.EXPERIENCE.id].employment,
    helpliving: form[FORMS.EXPERIENCE.id].helpliving,
    assistedhousing: form[FORMS.EXPERIENCE.id].assistedhousing,
    victim: form[FORMS.EXPERIENCE.id].victim,
    offender: form[FORMS.EXPERIENCE.id].offender,
    custody: form[FORMS.EXPERIENCE.id].custody,
    specialcourts: form[FORMS.EXPERIENCE.id].specialcourts,
    regularcourts: form[FORMS.EXPERIENCE.id].regularcourts,
    incarceration: form[FORMS.EXPERIENCE.id].incarceration,
    coaching: form[FORMS.RECOMMENDATIONS.id].coaching,
    support: form[FORMS.RECOMMENDATIONS.id].support,
    communication: form[FORMS.RECOMMENDATIONS.id].communication,
    earlyintervention: form[FORMS.RECOMMENDATIONS.id].earlyintervention,
    supportgroup: form[FORMS.RECOMMENDATIONS.id].supportgroup,
    individualtherapy: form[FORMS.RECOMMENDATIONS.id].individualtherapy,
    familycounselling: form[FORMS.RECOMMENDATIONS.id].familycounselling,
    abusecounselling: form[FORMS.RECOMMENDATIONS.id].abusecounselling,
    respite: form[FORMS.RECOMMENDATIONS.id].respite,
    sexed: form[FORMS.RECOMMENDATIONS.id].sexed,
    anger: form[FORMS.RECOMMENDATIONS.id].anger,
    childprotection: form[FORMS.RECOMMENDATIONS.id].childprotection,
    spousalabuse: form[FORMS.RECOMMENDATIONS.id].spousalabuse,
    mentalhealth: form[FORMS.RECOMMENDATIONS.id].mentalhealth,
    incomesupport: form[FORMS.RECOMMENDATIONS.id].incomesupport,
    foodbank: form[FORMS.RECOMMENDATIONS.id].foodbank,
    emergencyhousing: form[FORMS.RECOMMENDATIONS.id].emergencyhousing,
    daycare: form[FORMS.RECOMMENDATIONS.id].daycare,
    guardianship: form[FORMS.RECOMMENDATIONS.id].guardianship,
    poa: form[FORMS.RECOMMENDATIONS.id].poa,
    personaldirective: form[FORMS.RECOMMENDATIONS.id].personaldirective,
    legalaid: form[FORMS.RECOMMENDATIONS.id].legalaid,
    civilcourt: form[FORMS.RECOMMENDATIONS.id].civilcourt,
    familycourt: form[FORMS.RECOMMENDATIONS.id].familycourt,
    slp: form[FORMS.RECOMMENDATIONS.id].slp,
    aba: form[FORMS.RECOMMENDATIONS.id].aba,
    meds_rec: form[FORMS.RECOMMENDATIONS.id].meds_rec,
    ot: form[FORMS.RECOMMENDATIONS.id].ot,
    accommodations: form[FORMS.RECOMMENDATIONS.id].accommodations,
    guidance: form[FORMS.RECOMMENDATIONS.id].guidance,
    safety: form[FORMS.RECOMMENDATIONS.id].safety,
    reassessment: form[FORMS.RECOMMENDATIONS.id].reassessment,
    othersubstitute: form[FORMS.RECOMMENDATIONS.id].othersubstitute,
    legalservices: form[FORMS.RECOMMENDATIONS.id].legalservices,
    medicalreferral: form[FORMS.RECOMMENDATIONS.id].medicalreferral,
    fasdspecific: form[FORMS.RECOMMENDATIONS.id].fasdspecific
  }];

  const data = new FormData();
  data.append('format', 'json');
  data.append('content', 'record');
  data.append('type', 'flat');
  data.append('data', JSON.stringify(record));

  return data;
};

export default ConvertFormStateToFormData;
