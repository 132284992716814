import React from 'react';

const Blurb8_5E = () =>
  <>
    <p>
      You/your child may be experiencing difficulties with mental health, which can affect your/your child’s emotions, thoughts, behaviours, and relationships. Mental health needs can include physical symptoms, such as headaches or difficulty sleeping. Some challenges may be less visible whereas others may impact behaviour and interactions with others and may be more visible. It is important for you/ your child to seek FASD-informed mental health care to identify and support any challenges.
    </p>
  </>;

export default Blurb8_5E;
