import React from 'react';

const Blurb8_4A = () =>
  <>
    <p>
      Your child may benefit from mental health services, such as individualized therapy, group therapy, medication, and/or family intervention. Your child’s mental health care provider should be licensed with local professional bodies, and knowledgeable about FASD-informed interventions that will work for your child.
    </p>
  </>;

export default Blurb8_4A;
