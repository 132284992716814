import React from 'react';

const Blurb1_2C = () =>
  <>
    <p>
      The assessment provided an opportunity to understand how your teen’s unique needs and behaviours can
      be supported through intervention. Prenatal alcohol exposure (PAE) impacts the brains and bodies of
      people in different ways. Your teen’s assessment indicated that they have Fetal Alcohol Spectrum
      Disorder (FASD) with Sentinel Facial Features. Some individuals with FASD, like your teen, have
      distinct physical features that are unique to PAE. However, these features do not necessarily provide
      information about your teen’s unique brain- or body-based needs. Through individualized ongoing supports,
      individuals with FASD can experience success and achieve healthy outcomes.
    </p>
  </>;

export default Blurb1_2C;
