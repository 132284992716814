import React from 'react';

const Blurb5_2E = () =>
  <>
    <p>
      Access to <span className='text-bold'>FASD education</span> for teachers and other school supports may help to build educator skills and create well suited environments for learners with FASD. The Canada FASD Research Network offers free online FASD resources and training: <a href='https://estore.canfasd.ca/foundations-in-fasd'>https://estore.canfasd.ca/foundations-in-fasd</a>
    </p>
    <p>
      Sharing information regarding your/your child’s unique needs may help supports to identify approaches best suited to you/your child. A shared understanding among supporters can ensure they are equipped to address challenges and build on strengths to support academic achievement and broader learning.
    </p>
  </>;

export default Blurb5_2E;
