import React from 'react';

const Blurb8_1D = () =>
  <>
    <p>
      Mental health is fundamental to daily living and involves emotional and psychological well-being and regulation. People with FASD often have more mental health needs than might be expected, so ongoing monitoring of your mental health is important. Receiving information and supports that promotes mental health and regulation will also be essential.
    </p>
  </>;

export default Blurb8_1D;
