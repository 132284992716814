import React from 'react';

const Blurb5_13E = () =>
  <>
    <p>
      You/your child may benefit from support from a speech-language pathologist (SLP) who is FASD-informed. SLPs can help with many areas, including speech and language delays, fluency disorders, cognitive communication, voice, swallowing, fluency, and other related disorders.
    </p>
  </>;

export default Blurb5_13E;
