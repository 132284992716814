import React from 'react';
import coverBackground from '../../../../assets/Images/Report/coverBackground.png';
import compassStar from '../../../../assets/Images/Report/compassIcon.png';
import backgroundCompass from '../../../../assets/Images/Report/backgroundCompass.png';
import backgroundCompass2 from '../../../../assets/Images/Report/backgroundCompass2.png';

const Cover = () =>
  <section>
    <div className='cover-images'>
      <img
        alt='Compass logo'
        className='compass-background-bottom'
        src={backgroundCompass2}/>
      <img
        alt='Compass logo'
        className='compass-background'
        src={backgroundCompass}/>
      <img
        className='compass-star'
        src={compassStar}
        width='40%'/>
      <img
        className='cover-background'
        src={coverBackground}
        width='100%'/>
    </div>
  </section>;

export default Cover;
