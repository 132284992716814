import React from 'react';

const Blurb8_6C = () =>
  <>
    <p>
      Along with FASD, your teen has also been diagnosed with autism, which is a neurodevelopmental disorder related to social emotional and behavioural functioning. Just like FASD, every teen with autism is unique. Researchers are still learning how autism and FASD are related and interact. The first step to understanding and supporting your teen with FASD and autism is to recognize their unique patterns of strengths and needs. You may also find it helpful to connect with autism support services in your community.
    </p>
  </>;

export default Blurb8_6C;
