import React from 'react';

const Blurb11_2C = () =>
  <>
    <p>
      Parenting can be very challenging. It requires diverse skills, including problem-solving, empathy, patience, stress management, communication, and adaptability. Healthy parenting skills are important to promote positive, stable relationships between family members. If your teen with FASD is or becomes a parent, it will be important to consider supports that best fit their needs so they can experience parenting success. Providing consistent, stable support for your teen can help promote healthy relationships.
    </p>
  </>;

export default Blurb11_2C;
