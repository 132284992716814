import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import RadioButton from '../../../components/RadioButton';
import instructions from '../../../../locales/instructions';

const UniqueBrain = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const dispatch = useDispatch();
  const uniqueBrainFields = FORMS.UNIQUE_BRAIN.fields;
  const ageOptions = FORMS.UNIQUE_BRAIN.fields.age.options;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] !== '');
  const isFormComplete = Object.keys(uniqueBrainFields)
    .filter(field => !['name', 'my_unique_brain'].includes(field))
    .every(field => checkedFields.includes(field));

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.UNIQUE_BRAIN.id, updatedFormData));
  };

  return (
    <>
      <div className='lead group-name'>Entered by clinician</div>
      <form>
        <div className='grid-x grid-margin-x grid-margin-y large-up-2'>
          <div className='cell'>
            <div>
              <label
                className='form-field'
                htmlFor='my_unique_brain'>{FORMS.UNIQUE_BRAIN.fields.my_unique_brain.title}</label>
              <p className='form-instructions'>
                {instructions.my_unique_brain}
              </p>
              <textarea
                id='my_unique_brain'
                maxLength={3000}
                onChange={event => handleFormChange({...formData, my_unique_brain: event.target.value})}
                rows={4}
                value={formData.my_unique_brain}/>
            </div>
          </div>

          <div className='cell'>
            <label
              className='form-field'
              htmlFor='age'>{FORMS.UNIQUE_BRAIN.fields.age.title}</label>
            <p className='form-instructions'>
              {instructions.age}
            </p>
            <div className='grid-x small-up-2 medium-up-3'>
              {Object.keys(ageOptions).map(optionKey =>
                <RadioButton
                  checked={formData.age === optionKey}
                  className='cell'
                  key={optionKey}
                  label={ageOptions[optionKey]}
                  onChange={event => handleFormChange({...formData, age: event.target.value})}
                  value={optionKey}/>)}
            </div>
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

export default UniqueBrain;

UniqueBrain.propTypes = {
  formIndex: PropTypes.number.isRequired
};
