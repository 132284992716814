import React from 'react';
import SectionTitle from '../components/SectionTitle';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Variables from '../components/Variables';
import Card from '../components/Card';
import PaddedPrintLayout from '../../../components/PaddedPrintLayout';

const AdultTearoff = () => {
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const variable = {age, diagnosis};
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS.id]);

  if (formData.adult_adolescent_tearoff) {
    return <PaddedPrintLayout>
      <section className='color-pdf-background'>
        <SectionTitle>Tailored Feedback - Adult/Adolescent</SectionTitle>
        <Variables variableObject={variable}/>
        <Card>
          <h3 className='chapter-title'>
            My Unique <span className='card-title'>Needs</span>
          </h3>
          {formData.adult_adolescent_tearoff && <p>
            {formData.adult_adolescent_tearoff}
          </p>}
        </Card>
      </section>
    </PaddedPrintLayout>;
  }

  return null;
};

export default AdultTearoff;
