import React from 'react';

const Blurb4_3D = () =>
  <>
    <p>
      For support in developing your social skills, it may be helpful to work with a supportive person who can model appropriate conversations and help you navigate new social situations. Mentorship programming may be one way to assist with social skill development for adults with FASD in a relational way and can serve as a protective factor as you navigate peer influences and support you in building positive friendships.
    </p>
  </>;

export default Blurb4_3D;
