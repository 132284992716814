import React from 'react';
import MyUniqueNeeds from './Sections/MyUniqueNeeds';
import Health from './Sections/Health';
import AttachmentsAndRelationships from './Sections/AttachmentsAndRelationships';
import Cover from './Sections/Cover';
import SocialCompetency from './Sections/SocialCompetency';
import EducationAndSkillbuilding from './Sections/EducationAndSkillbuilding';
import Identity from './Sections/Identity';
import Independence from './Sections/Independence';
import MentalHealthAndRegulation from './Sections/MentalHealthAndRegulation';
import CommunityTearoff from './Sections/CommunityTearoff';
import EducationTearoff from './Sections/EducationTearoff';
import ClinicianTearoff from './Sections/ClinicianTearoff';
import Employment from './Sections/Employment';
import Housing from './Sections/Housing';
import ParentingSkills from './Sections/ParentingSkills';
import AdultTearoff from './Sections/AdultTearoff';
import JusticeTearoff from './Sections/JusticeTearoff';
import NoDiagnosisTearoff from './Sections/NoDiagnosisTearoff';
import ROUTES from '../../../routes';
import {useSelector} from 'react-redux';
import MetaHelmet from '../../components/MetaHelmet';
import FORMS, {IMAGES} from '../../../redux/constants';
import Quote from './Templates/Quote.js';
import PaddedPrintLayout from '../../components/PaddedPrintLayout';

const Report = () => {
  const formProgress = useSelector(state => state.formProgress);
  const isFormCompleted = Object.values(formProgress).every(value => value === true);
  const selectedTearoffs = useSelector(state => state.forms[FORMS.TEAROFFS_SELECTORS.id]);

  if (!isFormCompleted) {
    window.location.replace(ROUTES.ROOT);
  }

  return <div className='report'>
    <MetaHelmet
      title='Fetal Alcohol Spectrum Disorders Assessment Report'/>
    <Cover/>
    <PaddedPrintLayout>
      <MyUniqueNeeds/>
    </PaddedPrintLayout>
    <Quote
      backgroundImage={IMAGES.QUOTE_BACKGROUND1}
      calloutStyles={{
        top: '90%'
      }}
      quoteStyles={{
        marginBottom: '22rem'
      }}
      text={<p>
        The COMPASS report is structured and based on the Towards Healthy Outcomes (THO) 2.0 Framework which promotes consistent, goal-oriented intervention planning across the lifespan. Check-out the THO 2.0 framework to find additional resources and information based on the areas of need highlighted throughout this COMPASS report: <a href='https://canfasd.ca/wp-content/uploads/publications/Towards-Healthy-Outcomes-2.0.pdf'>https://canfasd.ca/wp-content/uploads/publications/Towards-Healthy-Outcomes-2.0.pdf</a>
      </p>}/>
    <PaddedPrintLayout>
      <Health/>
    </PaddedPrintLayout>
    <Quote
      backgroundImage={IMAGES.QUOTE_BACKGROUND2}
      quoteStyles={{
        marginBottom: '18rem'
      }}
      text={
        <p>
          Education on sexual health and wellbeing is a fundamental right for all and begin as early as late childhood. Misinformation and misconceptions about pregnancy, sexually transmitted diseases, protection, and consent contribute to poor sexual health outcomes, so early education is essential. Sex & U provides information and resources for sexual and reproduction wellbeing for caregivers, educators, and teachers of children from birth up to 18 years of age and can be found here: <a href='https://www.sexandu.ca'>https://www.sexandu.ca</a>
        </p>
      }/>
    <PaddedPrintLayout>
      <AttachmentsAndRelationships/>
    </PaddedPrintLayout>
    <PaddedPrintLayout>
      <SocialCompetency/>
    </PaddedPrintLayout>
    <PaddedPrintLayout>
      <EducationAndSkillbuilding/>
    </PaddedPrintLayout>
    <PaddedPrintLayout>
      <Identity/>
    </PaddedPrintLayout>
    <PaddedPrintLayout>
      <Independence/>
    </PaddedPrintLayout>
    <Quote
      backgroundImage={IMAGES.QUOTE_BACKGROUND4}
      calloutStyles={{
        top: '85%'
      }}
      quoteBackgroundStyles={{marginLeft: '0', width: '100%', paddingBottom: '200px'}}
      quoteClassNames='shadow'
      quoteStyles={{
        marginBottom: '27rem'
      }}
      text={
        <p>
          From early childhood through adolescence and into adulthood, we
          all grow and evolve in ways that influence and change our
          interactions with the community. Life transitions can create tensions
          for individuals with FASD who are seeking to develop their
          autonomy while navigating increasingly complex, unstructured, and
          unfamiliar environments. Recommendations for caregivers looking
          to support individual’s with FASD through periods of transition are
          presented in the infographic above.
        </p>
      }/>
    <PaddedPrintLayout>
      <MentalHealthAndRegulation/>
    </PaddedPrintLayout>
    <Quote
      backgroundImage={IMAGES.QUOTE_BACKGROUND3}
      quoteStyles={{
        marginBottom: '22rem'
      }}
      text={
        <p>
          FASD is a complex neurodevelopmental disorder where biological and environmental risks interact uniquely to impact mental wellbeing. People with FASD and their support systems can work collaboratively to ensure behaviours are monitored, treatment is accessed, strengths and resources are incorporated, and strategies are co-created. Doing so allows for healthy living and goal-achievement. Check-out this guide and tool-kit to supporting mental wellness in individuals with FASD from CanFASD: <a href='https://canfasd.ca/mental-health-toolkit/mental-healthtoolkit-introduction/#1695990942772-1cb3e53c-696f'>https://canfasd.ca/mental-health-toolkit/mental-healthtoolkit-introduction/#1695990942772-1cb3e53c-696f</a>
        </p>
      }/>
    <PaddedPrintLayout>
      <Employment/>
    </PaddedPrintLayout>
    <PaddedPrintLayout>
      <Housing/>
    </PaddedPrintLayout>
    <PaddedPrintLayout>
      <ParentingSkills/>
    </PaddedPrintLayout>
    {selectedTearoffs.community_tearoff === '1' && <CommunityTearoff/>}
    {selectedTearoffs.education_tearoff === '1' && <EducationTearoff/>}
    {selectedTearoffs.clinician_tearoff === '1' && <ClinicianTearoff/>}
    {selectedTearoffs.adult_adolescent_tearoff === '1' && <AdultTearoff/>}
    {selectedTearoffs.justice_tearoff === '1' && <JusticeTearoff/>}
    {selectedTearoffs.no_diagnosis_tearoff === '1' && <NoDiagnosisTearoff/>}
  </div>;
};

export default Report;
