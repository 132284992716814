import React from 'react';

const Blurb3_3E = () =>
  <>
    <p>
      You/your child have encountered one or more disrupted attachment experiences. Even in circumstances where attachment is disrupted, it is possible to re-establish secure and strong relationships at all developmental periods.
    </p>
  </>;

export default Blurb3_3E;
