import React from 'react';

const Blurb2_1A = () =>
  <>
    <p>
      Physical health is fundamental to daily living and is a first step towards healthy outcomes across the lifespan.
    </p>
    <p>
      Childhood is an important period for development. Ongoing monitoring of your child&apos;s physical health,
      including their hearing, vision, mobility, and diet, is important to your child’s overall well-being. Children
      with FASD may experience more physical health needs at earlier ages than might typically be expected. As your
      child continues to grow and develop, it will also be important they be provided with developmentally appropriate
      information, such as education on sexual health, pregnancy, and healthy relationships.
    </p>
  </>;

export default Blurb2_1A;
