import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const LipThinnessForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.LIP_THINNESS.id]);
  const isFormComplete = formData.upperlip !== '';
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.LIP_THINNESS.id, updatedFormData));
  };

  const lipThinnessOptions = FORMS.LIP_THINNESS.fields.upperlip.options;
  return (

    <>
      <form>
        <div className='grid-x'>
          <div className='cell large-7'>
            <label className='form-field'>{FORMS.LIP_THINNESS.fields.upperlip.title}</label>
            <div className='grid-x small-up-3'>
              {Object.keys(lipThinnessOptions).map(optionKey =>
                <RadioButton
                  checked={formData.upperlip === optionKey}
                  className='cell'
                  key={optionKey}
                  label={lipThinnessOptions[optionKey]}
                  onChange={event => handleFormChange({...formData, upperlip: event.target.value})}
                  value={optionKey}/>)}
            </div>
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

LipThinnessForm.propTypes = {
  formIndex: PropTypes.number
};

export default LipThinnessForm;
