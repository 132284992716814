import React from 'react';

const Blurb7_251 = () =>
  <>
    <p>
      A personal directive is recommended based on the results of the current assessment. A personal directive allows a person to appoint someone else to make decisions on their behalf should they be unable to do so. Peopl
    </p>
  </>;

export default Blurb7_251;
