import React from 'react';

const Blurb9_4E = () =>
  <>
    <p>
      If already employed/or seeking employment, you/ your child would likely benefit from coaching and education surrounding the expectations of your position. You may want to access supports for yourself/ child with the practical components of a job. This might include help remembering when you/they work (e.g., creating a schedule together), help planning transportation to and from the job, or practicing what to do in certain situations. Co-workers and employers that work with you/ your child would likely benefit from education surrounding FASD to help create a positive and safe work environment. This <span className='text-bold'>FASD practical guide</span> might be helpful to give to your you/ your child’s employer: <a href='https://drive.google.com/file/d/1MIdXwlXOWOgvPSQwn4TwVHPNbslTLMX3/view'>
      https://drive.google.com/file/d/1MIdXwlXOWOgvPSQwn4TwVHPNbslTLMX3/view</a>
    </p>
  </>;

export default Blurb9_4E;
