import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import SpecifyTextBox from '../../../components/SpecifyTextBox';

const ReferralSourceForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.REFERRAL_SOURCE.id]);
  const isFormComplete = formData.ref_source !== '' &&
    (formData.ref_source === FORMS.REFERRAL_SOURCE.fields.ref_source.specify_other.condition
      ? formData.ref_sourcespecify.length > 0 : true);
  const dispatch = useDispatch();
  const {ref_source} = FORMS.REFERRAL_SOURCE.fields;

  const handleFormChange = updatedFormData => {
    if (formData.ref_source !== updatedFormData.ref_source) {
      updatedFormData.ref_sourcespecify = ref_source.specify_other.value;
    }

    dispatch(submitForm(FORMS.REFERRAL_SOURCE.id, updatedFormData));
  };

  return (

    <>
      <div>
        <form>
          <div className='grid-x'>
            <div className='cell'>
              <label className='form-field'>{FORMS.REFERRAL_SOURCE.fields.ref_source.title}</label>
              {Object.keys(ref_source.options).map(optionKey =>
                <RadioButton
                  checked={formData.ref_source === optionKey}
                  key={optionKey}
                  label={ref_source.options[optionKey]}
                  onChange={() => handleFormChange({...formData, ref_source: optionKey})}
                  value={optionKey}
                />)}
            </div>
            <div className='cell large-6'>
              {
                formData.ref_source === ref_source.specify_other.condition && <>
                  <SpecifyTextBox
                    fieldId={ref_source.specify_other.id}
                    formId={FORMS.REFERRAL_SOURCE.id}
                    handleFormChange={handleFormChange}
                    title={ref_source.specify_other.title}/>
                </>
              }
            </div>
          </div>
        </form>
      </div>

      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

ReferralSourceForm.propTypes = {
  formIndex: PropTypes.number
};

export default ReferralSourceForm;
