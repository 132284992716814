import React from 'react';

const Blurb5_1D = () =>
  <>
    <p>
      Education is not limited to the school years alone. There are many ways adults can seek out education opportunities, like participating in training for jobs and taking courses to learn new skills. For adults with FASD, like you, it is possible to learn and grow outside of your school years. Finding opportunities for learning that allow you to reach your goals and establish purpose in your daily life can be important for your overall wellbeing.
    </p>
  </>;

export default Blurb5_1D;
