import React from 'react';

const Blurb6_1D = () =>
  <>
    <p>
      A person’s identity develops over the lifespan with early foundations laid in childhood. Identity is shaped by many factors, including education/career goals, disabilities, spiritual experiences, and self-expression. This can result in a broad spectrum of unique qualities, values, goals, strengths, and needs that are woven together.
    </p>
    <p>
      How you see yourself matters –appreciating who you are, including all your strengths and needs, helps to support success in many parts of your life. You can experience meaning and purpose and contribute in important ways to your community just like any other adult.
    </p>
  </>;

export default Blurb6_1D;
