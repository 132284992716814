import React from 'react';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import SectionTitle from '../components/SectionTitle';
import Variables from '../components/Variables';
import Blurb16_1B from '../../../blurbs/16_JusticeTearoff/Blurb16_1B';
import Blurb16_1C from '../../../blurbs/16_JusticeTearoff/Blurb16_1C';
import Blurb16_1D from '../../../blurbs/16_JusticeTearoff/Blurb16_1D';
import Blurb16_1E from '../../../blurbs/16_JusticeTearoff/Blurb16_1E';
import Blurb16_2B from '../../../blurbs/16_JusticeTearoff/Blurb16_2B';
import Blurb16_2C from '../../../blurbs/16_JusticeTearoff/Blurb16_2C';
import Blurb16_2D from '../../../blurbs/16_JusticeTearoff/Blurb16_2D';
import Blurb16_2E from '../../../blurbs/16_JusticeTearoff/Blurb16_2E';
import Blurb16_3B from '../../../blurbs/16_JusticeTearoff/Blurb16_3B';
import Blurb16_3C from '../../../blurbs/16_JusticeTearoff/Blurb16_3C';
import Blurb16_3D from '../../../blurbs/16_JusticeTearoff/Blurb16_3D';
import Blurb16_3E from '../../../blurbs/16_JusticeTearoff/Blurb16_3E';
import Blurb16_4B from '../../../blurbs/16_JusticeTearoff/Blurb16_4B';
import Blurb16_4C from '../../../blurbs/16_JusticeTearoff/Blurb16_4C';
import Blurb16_4D from '../../../blurbs/16_JusticeTearoff/Blurb16_4D';
import Blurb16_4E from '../../../blurbs/16_JusticeTearoff/Blurb16_4E';
import Blurb16_5E from '../../../blurbs/16_JusticeTearoff/Blurb16_5E';
import Blurb16_5B from '../../../blurbs/16_JusticeTearoff/Blurb16_5B';
import Blurb16_5C from '../../../blurbs/16_JusticeTearoff/Blurb16_5C';
import Blurb16_5D from '../../../blurbs/16_JusticeTearoff/Blurb16_5D';
import Blurb16_6B from '../../../blurbs/16_JusticeTearoff/Blurb16_6B';
import Blurb16_6C from '../../../blurbs/16_JusticeTearoff/Blurb16_6C';
import Blurb16_6D from '../../../blurbs/16_JusticeTearoff/Blurb16_6D';
import Blurb16_6E from '../../../blurbs/16_JusticeTearoff/Blurb16_6E';
import Blurb16_7B from '../../../blurbs/16_JusticeTearoff/Blurb16_7B';
import Blurb16_7D from '../../../blurbs/16_JusticeTearoff/Blurb16_7D';
import Blurb16_7C from '../../../blurbs/16_JusticeTearoff/Blurb16_7C';
import Blurb16_7E from '../../../blurbs/16_JusticeTearoff/Blurb16_7E';
import Blurb16_8B from '../../../blurbs/16_JusticeTearoff/Blurb16_8B';
import Blurb16_8C from '../../../blurbs/16_JusticeTearoff/Blurb16_8C';
import Blurb16_8D from '../../../blurbs/16_JusticeTearoff/Blurb16_8D';
import Blurb16_8E from '../../../blurbs/16_JusticeTearoff/Blurb16_8E';
import Blurb16_9B from '../../../blurbs/16_JusticeTearoff/Blurb16_9B';
import Blurb16_9C from '../../../blurbs/16_JusticeTearoff/Blurb16_9C';
import Blurb16_9D from '../../../blurbs/16_JusticeTearoff/Blurb16_9D';
import Blurb16_9E from '../../../blurbs/16_JusticeTearoff/Blurb16_9E';
import Card from '../components/Card';
import TwoColumnLayout from './TwoColumnLayout';
import PaddedPrintLayout from '../../../components/PaddedPrintLayout';

const JusticeTearoff = () => {
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {legalaid, familycourt, civilcourt} = useSelector(state => state.forms[FORMS.RECOMMENDATIONS.id]);
  const {offender, custody, specialcourts, regularcourts, incarceration} = useSelector(state => state.forms[FORMS.EXPERIENCE.id]);
  const {ref_reason___2} = useSelector(state => state.forms[FORMS.REFERRAL_REASON.id]);
  const variable = {age, diagnosis, ref_reason___2, legalaid, familycourt, civilcourt, offender,
    custody, specialcourts, regularcourts, incarceration};
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS.id]);

  const justiceTearoffBlurb16_1BCondition = age === '2' && (ref_reason___2 === '1' || legalaid === '1' || offender === '1' ||
    custody === '1' || familycourt === '1' || specialcourts === '1' || regularcourts === '1' ||
    civilcourt === '1' || incarceration === '1');
  const justiceTearoffBlurb16_1CCondition = age === '3' && (ref_reason___2 === '1' || legalaid === '1' || offender === '1' ||
    custody === '1' || familycourt === '1' || specialcourts === '1' || regularcourts === '1' ||
    civilcourt === '1' || incarceration === '1');
  const justiceTearoffBlurb16_1DCondition = age === '4' && (ref_reason___2 === '1' || legalaid === '1' || offender === '1' ||
    custody === '1' || familycourt === '1' || specialcourts === '1' || regularcourts === '1' ||
    civilcourt === '1' || incarceration === '1');
  const justiceTearoffBlurb16_1ECondition = age === '5' && (ref_reason___2 === '1' || legalaid === '1' || offender === '1' ||
    custody === '1' || familycourt === '1' || specialcourts === '1' || regularcourts === '1' ||
    civilcourt === '1' || incarceration === '1');

  const justiceTearoffBlurb16_2BCondition = ref_reason___2 === '1' && age === '2';
  const justiceTearoffBlurb16_2CCondition = ref_reason___2 === '1' && age === '3';
  const justiceTearoffBlurb16_2DCondition = ref_reason___2 === '1' && age === '4';
  const justiceTearoffBlurb16_2ECondition = ref_reason___2 === '1' && age === '5';

  const justiceTearoffBlurb16_3BCondition = legalaid === '1' && age === '2';
  const justiceTearoffBlurb16_3CCondition = legalaid === '1' && age === '3';
  const justiceTearoffBlurb16_3DCondition = legalaid === '1' && age === '4';
  const justiceTearoffBlurb16_3ECondition = legalaid === '1' && age === '5';

  const justiceTearoffBlurb16_4BCondition = offender === '1' && age === '2';
  const justiceTearoffBlurb16_4CCondition = offender === '1' && age === '3';
  const justiceTearoffBlurb16_4DCondition = offender === '1' && age === '4';
  const justiceTearoffBlurb16_4ECondition = offender === '1' && age === '5';

  const justiceTearoffBlurb16_5BCondition = custody === '1' || familycourt === '1' && age === '2';
  const justiceTearoffBlurb16_5CCondition = custody === '1' || familycourt === '1' && age === '3';
  const justiceTearoffBlurb16_5DCondition = custody === '1' || familycourt === '1' && age === '4';
  const justiceTearoffBlurb16_5ECondition = custody === '1' || familycourt === '1' && age === '5';

  const justiceTearoffBlurb16_6BCondition = specialcourts === '1' && age === '2';
  const justiceTearoffBlurb16_6CCondition = specialcourts === '1' && age === '3';
  const justiceTearoffBlurb16_6DCondition = specialcourts === '1' && age === '4';
  const justiceTearoffBlurb16_6ECondition = specialcourts === '1' && age === '5';

  const justiceTearoffBlurb16_7BCondition = regularcourts === '1' && age === '2';
  const justiceTearoffBlurb16_7CCondition = regularcourts === '1' && age === '3';
  const justiceTearoffBlurb16_7DCondition = regularcourts === '1' && age === '4';
  const justiceTearoffBlurb16_7ECondition = regularcourts === '1' && age === '5';

  const justiceTearoffBlurb16_8BCondition = civilcourt === '1' && age === '2';
  const justiceTearoffBlurb16_8CCondition = civilcourt === '1' && age === '3';
  const justiceTearoffBlurb16_8DCondition = civilcourt === '1' && age === '4';
  const justiceTearoffBlurb16_8ECondition = civilcourt === '1' && age === '5';

  const justiceTearoffBlurb16_9BCondition = incarceration === '1' && age === '2';
  const justiceTearoffBlurb16_9CCondition = incarceration === '1' && age === '3';
  const justiceTearoffBlurb16_9DCondition = incarceration === '1' && age === '4';
  const justiceTearoffBlurb16_9ECondition = incarceration === '1' && age === '5';

  if (formData.justice_tearoff ||
    justiceTearoffBlurb16_1BCondition || justiceTearoffBlurb16_1CCondition || justiceTearoffBlurb16_1DCondition || justiceTearoffBlurb16_1ECondition ||
    justiceTearoffBlurb16_2BCondition || justiceTearoffBlurb16_2CCondition || justiceTearoffBlurb16_2DCondition || justiceTearoffBlurb16_2ECondition) {
    return <PaddedPrintLayout>
      <section>
        <SectionTitle>Tailored Feedback - Justice</SectionTitle>
        <Variables variableObject={variable}/>
        <Card>
          <h3 className='chapter-title'>
            <span className='card-title'>Justice</span>
          </h3>
          <>
            {justiceTearoffBlurb16_1BCondition && <Blurb16_1B/>}
            {justiceTearoffBlurb16_1CCondition && <Blurb16_1C/>}
            {justiceTearoffBlurb16_1DCondition && <Blurb16_1D/>}
            {justiceTearoffBlurb16_1ECondition && <Blurb16_1E/>}
          </>
          {
            formData.justice_tearoff && <p>
              {formData.justice_tearoff}
            </p>
          }
          <TwoColumnLayout>
            <>
              {justiceTearoffBlurb16_2BCondition && <Blurb16_2B/>}
              {justiceTearoffBlurb16_2CCondition && <Blurb16_2C/>}
              {justiceTearoffBlurb16_2DCondition && <Blurb16_2D/>}
              {justiceTearoffBlurb16_2ECondition && <Blurb16_2E/>}
            </>
            <>
              {justiceTearoffBlurb16_3BCondition && <Blurb16_3B/>}
              {justiceTearoffBlurb16_3CCondition && <Blurb16_3C/>}
              {justiceTearoffBlurb16_3DCondition && <Blurb16_3D/>}
              {justiceTearoffBlurb16_3ECondition && <Blurb16_3E/>}
            </>
            <>
              {justiceTearoffBlurb16_4BCondition && <Blurb16_4B/>}
              {justiceTearoffBlurb16_4CCondition && <Blurb16_4C/>}
              {justiceTearoffBlurb16_4DCondition && <Blurb16_4D/>}
              {justiceTearoffBlurb16_4ECondition && <Blurb16_4E/>}
            </>
            <>
              {justiceTearoffBlurb16_5BCondition && <Blurb16_5B/>}
              {justiceTearoffBlurb16_5CCondition && <Blurb16_5C/>}
              {justiceTearoffBlurb16_5DCondition && <Blurb16_5D/>}
              {justiceTearoffBlurb16_5ECondition && <Blurb16_5E/>}
            </>
            <>
              {justiceTearoffBlurb16_6BCondition && <Blurb16_6B/>}
              {justiceTearoffBlurb16_6CCondition && <Blurb16_6C/>}
              {justiceTearoffBlurb16_6DCondition && <Blurb16_6D/>}
              {justiceTearoffBlurb16_6ECondition && <Blurb16_6E/>}
            </>
            <>
              {justiceTearoffBlurb16_7BCondition && <Blurb16_7B/>}
              {justiceTearoffBlurb16_7CCondition && <Blurb16_7C/>}
              {justiceTearoffBlurb16_7DCondition && <Blurb16_7D/>}
              {justiceTearoffBlurb16_7ECondition && <Blurb16_7E/>}
            </>
            <>
              {justiceTearoffBlurb16_8BCondition && <Blurb16_8B/>}
              {justiceTearoffBlurb16_8CCondition && <Blurb16_8C/>}
              {justiceTearoffBlurb16_8DCondition && <Blurb16_8D/>}
              {justiceTearoffBlurb16_8ECondition && <Blurb16_8E/>}
            </>
            <>
              {justiceTearoffBlurb16_9BCondition && <Blurb16_9B/>}
              {justiceTearoffBlurb16_9CCondition && <Blurb16_9C/>}
              {justiceTearoffBlurb16_9DCondition && <Blurb16_9D/>}
              {justiceTearoffBlurb16_9ECondition && <Blurb16_9E/>}
            </>
          </TwoColumnLayout>
        </Card>
      </section>
    </PaddedPrintLayout>;
  }

  return null;
};

export default JusticeTearoff;
