import React from 'react';

const Blurb10_8 = () =>
  <>
    <p>
      Access to safe and stable housing with appropriate supports can increase feelings of choice, control, freedom, and equality. Housing supports look different for everyone. Some people with FASD can access and maintain housing and complete daily living tasks with little to no support and others needing more directive assistance. Supporting individuals with FASD to access and maintain safe and stable housing can look like strategies around time management, safety, self-care, food preparation, and financial knowledge. All of these strategies can support interdependence.
    </p>
  </>;

export default Blurb10_8;
