import React from 'react';

const Blurb1_2A = () =>
  <>
    <p>
      Early intervention is important to support your child’s unique needs and behaviours. Prenatal alcohol
      exposure (PAE) impacts the brains and bodies of people in different ways. Your child’s assessment
      indicated that they have Fetal Alcohol Spectrum Disorder (FASD) with Sentinel Facial Features.
      This means that your child has distinct physical features that are unique to PAE Individualized
      ongoing supports can help all individuals with FASD to experience success and achieve healthy outcomes.
    </p>
  </>;

export default Blurb1_2A;
