import React from 'react';

const Blurb2_3 = () =>
  <>
    <p>
      Medications may reduce the impact of physical and/or mental health needs and allow for greater engagement with learning, socializing, and other opportunities for growth.
    </p>
    <p>
      Through regular communication with a primary health care provider, medications should be monitored for side effects, and adapted proactively through developmental periods. It can sometimes be difficult to find the best suited medication for an individual with FASD, so <span className='text-bold'>Dr. Mansfield Mela developed this tool to help guide conversations and decision-making: </span><a href='https://canfasd.ca/algorithm/'>https://canfasd.ca/algorithm/</a>
    </p>
  </>;

export default Blurb2_3;
