import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import {useSelector} from 'react-redux';
import FORMS, {IMAGES} from '../../../../redux/constants';
import Blurb6_1A from '../../../blurbs/6_Identity/Blurb6_1A';
import Blurb6_1B from '../../../blurbs/6_Identity/Blurb6_1B';
import Blurb6_1C from '../../../blurbs/6_Identity/Blurb6_1C';
import Blurb6_1D from '../../../blurbs/6_Identity/Blurb6_1D';
import Blurb6_1E from '../../../blurbs/6_Identity/Blurb6_1E';
import Blurb6_2A from '../../../blurbs/6_Identity/Blurb6_2A';
import Blurb6_2B from '../../../blurbs/6_Identity/Blurb6_2B';
import Blurb6_2C from '../../../blurbs/6_Identity/Blurb6_2C';
import Blurb6_2D from '../../../blurbs/6_Identity/Blurb6_2D';
import Blurb6_2E from '../../../blurbs/6_Identity/Blurb6_2E';
import Blurb6_3A from '../../../blurbs/6_Identity/Blurb6_3A';
import Blurb6_3B from '../../../blurbs/6_Identity/Blurb6_3B';
import Blurb6_3C from '../../../blurbs/6_Identity/Blurb6_3C';
import Blurb6_3D from '../../../blurbs/6_Identity/Blurb6_3D';
import Blurb6_3E from '../../../blurbs/6_Identity/Blurb6_3E';
import Blurb6_4A from '../../../blurbs/6_Identity/Blurb6_4A';
import Blurb6_4B from '../../../blurbs/6_Identity/Blurb6_4B';
import Blurb6_4C from '../../../blurbs/6_Identity/Blurb6_4C';
import Blurb6_4D from '../../../blurbs/6_Identity/Blurb6_4D';
import Blurb6_4E from '../../../blurbs/6_Identity/Blurb6_4E';
import Variables from '../components/Variables';
import TwoColumnLayout from './TwoColumnLayout';

const Identity = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {associatedfeatures___5} = useSelector(state => state.forms[FORMS.OTHER_FEATURES.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);

  const variables = {diagnosis, age, associatedfeatures___5};

  const IdentityBlurb6_1A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_1B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_1C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_1D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_1E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const IdentityBlurb6_2A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_2B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_2C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_2D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_2E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const IdentityBlurb6_3A = associatedfeatures___5 === '1' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_3B = associatedfeatures___5 === '1' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_3C = associatedfeatures___5 === '1' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_3D = associatedfeatures___5 === '1' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_3E = associatedfeatures___5 === '1' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const IdentityBlurb6_4A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_4B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_4C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_4D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IdentityBlurb6_4E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  return <section>
    <SectionTitle>Identity</SectionTitle>
    <Variables variableObject={variables}/>
    {
      (IdentityBlurb6_1A || IdentityBlurb6_1B || IdentityBlurb6_1C || IdentityBlurb6_1D || IdentityBlurb6_1E) &&
      <Card>
        <h3 className='chapter-title'>
          Things to <span className='card-title'>Know</span>
        </h3>
        <>
          {IdentityBlurb6_1A && <Blurb6_1A/>}
          {IdentityBlurb6_1B && <Blurb6_1B/>}
          {IdentityBlurb6_1C && <Blurb6_1C/>}
          {IdentityBlurb6_1D && <Blurb6_1D/>}
          {IdentityBlurb6_1E && <Blurb6_1E/>}
        </>
      </Card>
    }
    <TwoColumnLayout>
      <img
        src={IMAGES.IMAGE6}
      />
      <div className='avoid-break-inside'>
        {
          /*
           * IdentityBlurb6_3A-6_3E is not included in the following if statement because it is empty,
           * even if it meets the condition, It will not add to the text in this section.
           */
          (IdentityBlurb6_2A || IdentityBlurb6_2B || IdentityBlurb6_2C || IdentityBlurb6_2D || IdentityBlurb6_2E) &&
          <Card>
            <h3 className='chapter-title'>
              Things to <span className='card-title'>Do Now</span>
            </h3>
            <>
              {IdentityBlurb6_2A && <Blurb6_2A/>}
              {IdentityBlurb6_2B && <Blurb6_2B/>}
              {IdentityBlurb6_2C && <Blurb6_2C/>}
              {IdentityBlurb6_2D && <Blurb6_2D/>}
              {IdentityBlurb6_2E && <Blurb6_2E/>}
            </>
            <>
              {IdentityBlurb6_3A && <Blurb6_3A/>}
              {IdentityBlurb6_3B && <Blurb6_3B/>}
              {IdentityBlurb6_3C && <Blurb6_3C/>}
              {IdentityBlurb6_3D && <Blurb6_3D/>}
              {IdentityBlurb6_3E && <Blurb6_3E/>}
            </>
          </Card>
        }
        {
          (IdentityBlurb6_4A || IdentityBlurb6_4B || IdentityBlurb6_4C || IdentityBlurb6_4D || IdentityBlurb6_4E) &&
          <Card>
            <h3 className='chapter-title'>
              Things to <span className='card-title'>Plan</span> for
            </h3>
            <>
              {IdentityBlurb6_4A && <Blurb6_4A/>}
              {IdentityBlurb6_4B && <Blurb6_4B/>}
              {IdentityBlurb6_4C && <Blurb6_4C/>}
              {IdentityBlurb6_4D && <Blurb6_4D/>}
              {IdentityBlurb6_4E && <Blurb6_4E/>}
            </>
          </Card>
        }
      </div>
    </TwoColumnLayout>
  </section>;
};

export default Identity;
