import React from 'react';

const Blurb2_9 = () =>
  <>
    <p>
      Physical or structural differences in you/your child’s body may add to difficulties experienced in daily functioning. It will be important to address treatment and support options with a primary care provider.
    </p>
  </>;

export default Blurb2_9;
