import React from 'react';
import FORMS from '../../../../redux/constants';
import {useDispatch, useSelector} from 'react-redux';
import {submitForm} from '../../../../redux/actions/forms';
import PropTypes from 'prop-types';
import MonthYear from '../../../components/MonthYear';
import {FormNav} from '../../../components/FormNav';

const DiagnosisDateForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.DIAGNOSIS_DATE.id]);
  const dispatch = useDispatch();
  const assessmentYearOptions = FORMS.DIAGNOSIS_DATE.fields.assessment_yr.options;
  const assessmentMonthOptions = FORMS.DIAGNOSIS_DATE.fields.assessment_mth.options;
  const isFormComplete = formData.assessment_yr !== '' && formData.assessment_mth !== '';

  const handleFormChange = dateVal => {
    dispatch(submitForm(FORMS.DIAGNOSIS_DATE.id, {...formData, assessment_mth: dateVal.assessment_mth, assessment_yr: dateVal.assessment_yr}));
  };

  return (
    <>
      <div>
        <MonthYear
          formData={formData}
          monthId='assessment_mth'
          monthOptions={assessmentMonthOptions}
          onChange={handleFormChange}
          title='Date of diagnostic assessment'
          yearId='assessment_yr'
          yearOptions={assessmentYearOptions}
        />
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

DiagnosisDateForm.propTypes = {
  formIndex: PropTypes.number
};

export default DiagnosisDateForm;
