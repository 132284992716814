import React from 'react';

const Blurb11_1B = () =>
  <>
    <p>
      Although your child is young, they may eventually become a parent. Many parents need support, including those with FASD. Supporting someone who becomes a parent can be rewarding and challenging. It requires consistency, strength, and resilience. With appropriate supports, people with FASD can be successful and fulfilled as a parent.
    </p>
  </>;

export default Blurb11_1B;
