import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../../../assets/Images/logo.png';

const Quote = ({backgroundImage, text, quoteStyles, calloutStyles, quoteBackgroundStyles, quoteClassNames}) => <section
  className='padding-0'>
  <div
    className='quote-images'
    style={quoteStyles}>
    <img
      alt='Quote Background'
      className={`quote-background ${quoteClassNames}`}
      src={backgroundImage}
      style={quoteBackgroundStyles}
      width='100%'/>
    <div
      className='quote-callout shadow grid-x'
      style={calloutStyles}>
      <div className='cell shrink'>
        <img
          alt='logo of the website'
          className='cover-logo margin-1'
          height='30px'
          src={logo}
          width='30px'/>
      </div>
      <div className='auto cell'>
        <p className='quote-heading'>DID YOU KNOW?</p>
        <p className='quote-text'>{text}</p>
      </div>
    </div>
  </div>
</section>;

Quote.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  calloutStyles: PropTypes.object,
  quoteBackgroundStyles: PropTypes.object,
  quoteClassNames: PropTypes.string,
  quoteStyles: PropTypes.object,
  text: PropTypes.object.isRequired
};

export default Quote;
