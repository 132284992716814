import React from 'react';

const Blurb1_5D = () =>
  <>
    <p>
      Tailored intervention is important to support your daily needs and behaviours. Based on the assessment
      results, you did not meet diagnostic criteria for Fetal Alcohol Spectrum Disorder (FASD) at this time.
      It will be important to continue to monitor your behaviours and functioning to support healthy outcomes
      throughout the lifespan.
    </p>
  </>;

export default Blurb1_5D;
