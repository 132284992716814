import React from 'react';

const Blurb6_2E = () =>
  <>
    <p>
      To help promote you/your child’s ongoing identity development, provide them with opportunities to explore their unique interests, beliefs, and values. Conversations, modelling, and play can all be important way to explore identity and can lead to greater self-awareness and insight. When supporters understand your/your child’s unique identity, you/they are better equipped to address challenges and build on strengths as you your teen continues to develop their sense of identity.
    </p>
  </>;

export default Blurb6_2E;
