import React from 'react';

const Blurb5_16E = () =>
  <>
    <p>
      Education is an important part of development that informs goal planning and achievement throughout childhood, adolescence, and adulthood. As you/your child continues their education journey, it will be important for educators to consider their unique strengths, needs, and goals when developing an individualized and supportive education plan. They may benefit from adaptations to programming that allow for more active participation in “hands-on experiences,” time to process and engage with content, and opportunities to share their knowledge with others. In this way they can remain engaged at school and build skills to support their academic achievement. This will help you/your child to stay engaged and build skills to support your/their educational journey.
    </p>
  </>;

export default Blurb5_16E;
