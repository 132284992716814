import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const DiagnosticSchemaForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.DIAGNOSTIC_SCHEMA.id]);
  const isFormComplete = formData.fasdcode !== '' &&
    (formData.fasdcode === FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.specify_other.condition
      ? formData.fasd4digit.length > 0 && formData.fasd4digit < 10000 && formData.fasd4digit > -1 : true);
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    if (formData.fasdcode !== updatedFormData.fasdcode) {
      updatedFormData[FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.specify_other.id] = FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.specify_other.value;
    }

    dispatch(submitForm(FORMS.DIAGNOSTIC_SCHEMA.id, updatedFormData));
  };

  const diagnosticSchemaOptions = FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.options;
  return (

    <>
      <form>
        <div className='grid-x grid-margin-y'>
          <div className='cell large-7'>
            <label className='form-field'>{FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.title}</label>
            <div className='grid-x small-up-2 medium-up-4'>
              {Object.keys(diagnosticSchemaOptions).map(optionKey =>
                <RadioButton
                  checked={formData.fasdcode === optionKey}
                  className='cell'
                  key={optionKey}
                  label={diagnosticSchemaOptions[optionKey]}
                  onChange={event => handleFormChange({...formData, fasdcode: event.target.value})}
                  value={optionKey}/>)}
            </div>
          </div>
          <div className='cell large-7'>
            {
              formData.fasdcode === FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.specify_other.condition && <>
                <label
                  className='form-field'
                  htmlFor={FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.specify_other.id}>{FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.specify_other.title}</label>
                <input
                  id={FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.specify_other.id}
                  max={FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.specify_other.max}
                  min={FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.specify_other.min}
                  onChange={event => handleFormChange({
                    ...formData,
                    [FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.specify_other.id]: event.target.value
                  })}
                  type={FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.specify_other.type}
                  value={formData[FORMS.DIAGNOSTIC_SCHEMA.fields.fasdcode.specify_other.id]}/>
              </>
            }
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

DiagnosticSchemaForm.propTypes = {
  formIndex: PropTypes.number
};

export default DiagnosticSchemaForm;
