import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const ScreeningToolForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.SCREENING_TOOL.id]);
  const isFormComplete = formData.screen !== '' &&
    (formData.screen === FORMS.SCREENING_TOOL.fields.screen_tool.condition
      ? formData.screen_tool.length > 0 && formData.screen_who.length > 0 : true);
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    if (formData.screen !== updatedFormData.screen) {
      updatedFormData.screen_who = FORMS.SCREENING_TOOL.fields.screen_who.value;
      updatedFormData.screen_tool = FORMS.SCREENING_TOOL.fields.screen_tool.value;
    }

    dispatch(submitForm(FORMS.SCREENING_TOOL.id, updatedFormData));
  };

  const screeningToolUsedOptions = FORMS.SCREENING_TOOL.fields.screen.options;
  return (
    <>
      <form>
        <div className='grid-x large-up-2'>
          <div className='cell'>
            <label className='form-field'>{FORMS.SCREENING_TOOL.fields.screen.title}</label>
            <div className='grid-x small-up-2 medium-up-4'>
              {Object.keys(screeningToolUsedOptions).map(optionKey =>
                <RadioButton
                  checked={formData.screen === optionKey}
                  className='cell'
                  key={optionKey}
                  label={screeningToolUsedOptions[optionKey]}
                  onChange={event => handleFormChange({...formData, screen: event.target.value})}
                  value={optionKey}/>)}
            </div>
          </div>
          <div className='cell'>
            {
              formData.screen === FORMS.SCREENING_TOOL.fields.screen_tool.condition && <>
                <label
                  className='form-field'
                  htmlFor={FORMS.SCREENING_TOOL.fields.screen_tool.id}>{FORMS.SCREENING_TOOL.fields.screen_tool.title}</label>
                <input
                  id={FORMS.SCREENING_TOOL.fields.screen_tool.id}
                  onChange={event => handleFormChange({
                    ...formData,
                    [FORMS.SCREENING_TOOL.fields.screen_tool.id]: event.target.value
                  })}
                  type='text'
                  value={formData[FORMS.SCREENING_TOOL.fields.screen_tool.id]}/>
              </>
            }
            {
              formData.screen === FORMS.SCREENING_TOOL.fields.screen_who.condition && <>
                <label
                  className='form-field'
                  htmlFor={FORMS.SCREENING_TOOL.fields.screen_who.id}>{FORMS.SCREENING_TOOL.fields.screen_who.title}</label>
                <input
                  id={FORMS.SCREENING_TOOL.fields.screen_who.id}
                  onChange={event => handleFormChange({
                    ...formData,
                    [FORMS.SCREENING_TOOL.fields.screen_who.id]: event.target.value
                  })}
                  type='text'
                  value={formData[FORMS.SCREENING_TOOL.fields.screen_who.id]}/>
              </>
            }
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

ScreeningToolForm.propTypes = {
  formIndex: PropTypes.number
};

export default ScreeningToolForm;
