import React from 'react';

const Blurb1_1 = () =>
  <>
    <p>
      Welcome! A specialized FASD assessment provides information about diagnosis, unique areas of strength
      and difficulty, and recommendations for support. This COMPASS report is intended to support planning
      and communicating information from this assessment. It is based on the Towards Healthy Outcomes
      framework, a model that helps organize recommendations according to developmental needs across
      the lifespan. For more information on Towards Healthy Outcomes, you can visit or click on the following
      link: <a href='https://canfasd.ca/publications/towards-healthy-outcomes/'>https://canfasd.ca/publications/
      towards-healthy-outcomes/</a>. If you have questions about this report, please get in touch with your
      FASD support team.
    </p>
  </>;

export default Blurb1_1;
