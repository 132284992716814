import React from 'react';

const Blurb5_13D = () =>
  <>
    <p>
      You may benefit from support from a speech-language pathologist (SLP) who is FASD-informed.
      SLPs work with a variety of clients and can help with many areas of speech and language including fluency disorders, cognitive communication, voice, fluency, and other speech related concerns.
    </p>
  </>;

export default Blurb5_13D;
