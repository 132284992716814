import React from 'react';

const Blurb5_3E = () =>
  <>
    <p>
      Your/your child’s intellectual functioning, or how you/they learn, reason, use language, and solve problems may be different than others your/your child’s age. This may in turn impact your/their experiences of success in learning. To support your learning, modified programming, appropriate accommodations and strategies will be important. Identified needs in this area may also be part of an individualized support plan where unique learning needs and accommodations are outlined.
    </p>
  </>;

export default Blurb5_3E;
