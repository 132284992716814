const values = {
  welcome1: 'Welcome to COMPASS! Please enter information related to your client as you have done in the past using DataForm. All fields are mandatory for the purpose of COMPASS except for entering specific prescribed medication information. Please note that the data you enter is not sent to the Database.',
  welcome2: 'Throughout entry, additional instructions will appear on your screen and prompt you to decide whether to enter additional information in an open text box that is not captured by the Database variables. For example, you may choose to enter client-specific information or resources/services that are unique to your area that may be helpful for your client.',
  disclaimer: {
    title: 'Disclaimer for clinicians using the website to generate reports:',
    termsTitle: 'Terms of Use',
    termsDescription: 'Welcome! This Client Oriented Mapping for Point of Care Access to Supports and Services (COMPASS) website and report generator was developed by researchers at the University of Alberta, University of Guelph, and the University of Ottawa in partnership with the Canada Fetal Alcohol Spectrum Disorder Researcher Network. Any use of the COMPASS entry website and distribution of the COMPASS report is subject to the terms and conditions set forth below. It is important that you read all the terms and conditions of this agreement carefully. By scrolling and clicking “I Accept,” you agree to be bound by the terms and conditions of this agreement, even if you have not read them.',
    userResponsibilitiesTitle: 'User Responsibilities',
    userResponsibilitiesDescription: 'You are responsible for all activities conducted on the COMPASS website and when distributing the report to clients, families, and/or other professionals. You will not use COMPASS outside of its intended purposes, including without limitation: illegal or inappropriate purposes; sending spam; perpetration of security beaches; hacking; privacy breaches; fraudulent activity; distribution of viruses or other harmful code; transmission, distribution or storage of any material protected by copyright, trade-mark, trade secret or other intellectual property right without proper authorization or rights, or material that is infringing, obscene, defamatory, fraudulent, untruthful, disrespectful, discriminatory, or abusive; any unauthorized access, alteration, theft, corruption or destruction of files, data, transmission facilities or equipment; or to interfere with any other person’s use and enjoyment of COMPASS; recruiting members as participants in a research project or school assignment of any kind, identifying persons to interview for a media project of any kind, interviewing members for a publication, recruiting volunteers for any service or organization, or soliciting members for any other purpose.',
    dataEntryTitle: 'Data Entry',
    dataEntryDescription: 'Data entered is used to generate the report and is used as a part of the CanFASD’s National FASD Database (“Dataform”) Project. Ethics approval for the original study was granted by the Ottawa Health Science Network Research Ethics Board (Protocol ID-20160423-01H) on August 30th, 2016, and the application is renewed yearly. Data entry follows the same Database entry procedures. Data is entered through the COMPASS website and entries are sent to the existing Database REDCap. REDCap is a secure, web-based application designed exclusively to support data capture for research studies and is developed and maintained by a team at Vanderbilt University. The data will be housed on servers located in a secure server within the PolicyWise office in Edmonton (601-9925 109 Street). The Director of Data Initiatives at the PolicyWise office will provide support to access the data. The database server will be under Canadian Jurisdiction. Only staff associated with the study will have access to the data.',
    dataEntryDescription2: 'Our research team used the existing Dataform because many variables within this entry survey are used to generate the report; however, not all entered fields go to Database. Throughout entry, additional instructions will appear on your screen and prompt you to decide whether to enter additional information in an open text box that is not captured by the Database variables. For example, you may choose to enter client-specific information or resources/services that are unique to your area that may be helpful for your client.',
    dataEntryDescription3: 'To ensure optimal performance of the COMPASS website and report, it is important to periodically clear your browser’s cookies and cache. This practice prevents issues related to data retrieval and safeguards your privacy. This can be completed under “Settings” in your browsing platform such as Google Chrome and Safari.',
    dataEntryDescription4: 'Additionally, if you are using the COMPASS website for more than one entry, always select the "Reset Form" button at the beginning for each new client entry. This action ensures all a clean form and avoids potential issues related to previously stored data. Failure to perform these steps may result in incorrect report generation or system errors.',
    contentTitle: 'Content',
    contentDescription: 'While our research team uses reasonable efforts to update and maintain the content within the COMPASS report in an up-to-date fashion, it does not warrant the completeness, timeliness, or accuracy of any information contained within the report. We may make changes to the report content at any time without notice. All information contained herein is provided to users “as is,” “with all faults,” “as available”, and at your sole risk. The information includes both clinical and non-clinical support content. The clinical content is based on an evaluation of the scientific literature and medical experience; it describes principles of practice generally applicable in most circumstances. The non-clinical support content addresses key concepts and practices applicable to client wellbeing and/or teamwork and communication. This information should not be deemed inclusive of all, or exclusive, of any methods of care or best practice. The ultimate judgment regarding the care of a client must be made by the clinician or health care provider as a result of informed consultation with the client, family member, or other supportive persons, as a result of informed consultations with all relevant parties, considering all the circumstances relevant to the client’s presentation, the diagnostic and treatment options available, and access to the necessary support resources. The content is provided for educational purposes only and is to be used as a tool in assessing the knowledge and skill of the user for development and training purposes. As such, the COMPASS website and report should not be used as a diagnostic tool for Fetal Alcohol Spectrum Disorder (FASD) or any other condition(s). It is your responsibility to communicate and confirm understanding of this information to your clients prior to providing them with the COMPASS report.',
    externalLinksTitle: 'External Links to Other Websites',
    externalLinksDescription: 'Any third-party sites that are linked within the COMPASS report are not under the research team or CanFASD’s control. We are not responsible for anything on the linked sites, including without limitation any content, links to other sites, any changes to those sites, or any policies those sites may have. The links are provided for educational purposes only and such links do not imply any endorsement by the research team or CanFASD of those sites.',
    infringingContentTitle: 'Infringing Content',
    infringingContentDescription: 'If you believe anything posted on the COMPASS website or information contained within the report offends your copyright or other intellectual property rights, is defamatory, or otherwise infringes your rights, you may contact members of the research team. We will take actions we believe are appropriate in the circumstances.',
    infringingContentDescription2: 'By clicking “I Accept,” you agree to be bound by the terms and conditions of this agreement and confirm your understanding of the information outlined above and accept your rights and responsibilities as a user of COMPASS even if you have not read the above information.',
    subTitle2: 'Disclaimer Information for Report (Included at the beginning and directed towards recipients of the report).',
    disclaimerTitle: 'DISCLAIMER: THE COMPASS REPORT DOES NOT PROVIDE MEDICAL ADVICE.',
    disclaimerDescription: 'This Client Oriented Mapping for Point of Care Access to Supports and Services (COMPASS) report was developed by researchers at the University of Alberta, University of Guelph, and the University of Ottawa in partnership with the Canada Fetal Alcohol Spectrum Disorder Researcher Network. The COMPASS report is intended to be an additional resource given to you following an FASD diagnostic assessment and is to be used for educational and informational purposes only. The diagnostic report provided to you by your assessment team including information regarding assessment results, diagnostic conclusions, and recommendations remains the main source of information regarding the assessment. Always seek the advice of your physician or other qualified health care provider with any questions you may have regarding a medical condition, diagnosis, or treatment, and never disregard professional health care advice or delay in seeking it because of something you have read in the COMPASS report. The ultimate judgement regarding your care must be made through consultation with your clinician or members of the assessment team.',
    disclaimerDescription2: 'The information, including but not limited to text, graphics, images, weblinks, and other material contained within the COMPASS report are for informational and educational purposes only. No material in this report is intended to be a substitute for professional medical advice, diagnosis, or treatment. While the research team takes every possible step to update and maintain the content within the COMPASS report in an up-to-date fashion, it does not warrant the completeness, timeliness, or accuracy of any information contained within the report. We may make changes to the report content at any time without notice. All information contained within the report is provided to users “as is,” “with all faults,” “as available,” and at your sole risk.'
  },
  age: 'Please select from the following age group that best defines your client. The universal age group is intended for unique circumstances, and general information for each of the domains within the report will be generated that is not specific to age.',
  name: 'Optionally, enter the patient name to show on the printed report to make it more personalized (this field is never transmitted over the Internet).',
  my_unique_brain: 'This text box is optional. You may choose to enter information here that is client-specific and unique to their assessment results.',
  tear_offs: 'Please select the tailored feedback sheet(s) that you would like to be generated for your client. Please note that all these sheets can allow you to enter the community or client-specific information in an open text box. The open-text boxes are optional. If you choose not to enter text, the tailored feedback sheet will still be generated if you check the box.',
  diagnosis: 'Please select the diagnostic category that best applies to your client. It is important to note that only the first page (i.e., my unique needs/brain) will be the only page that populates for the no-diagnosis category. There is the option to select the no-diagnosis tear-off if you would like additional information to be provided to your client. This tear-off also has the option for an open textbox.',
  sliding_scale: 'Below are six sliding scales that cover different domains or skills of functioning that were assessed by your team. A graph will populate, highlighting your client’s unique areas of strengths and needs according to these domains. The domains are defined as follows, with the guidance of tests and/or additional information that you can use to inform your ratings on the sliding scales. We have also included a guide for rating your clients’ strengths versus areas of need. The guide includes suggested descriptor ranges based on the sliding scale marker values. These are suggestions and clinical judgment can be used to further inform your ratings on the scale.',
  verbal_and_language: {
    text: 'Skills related to verbal reasoning, verbal concept formation, and acquired verbal knowledge or language use.',
    suggestedSource: 'VCI, Expressive/Receptive abilities, Adaptive Communication (ABAS, Vineland)'
  },
  visual: {
    text: 'Skills related to visual-spatial processing, visual-motor integration, and visual problem-solving.',
    suggestedSource: 'VSI, PRI'
  },
  attentional_control: {
    text: 'Skills related to maintaining concentration and shifting between tasks.',
    suggestedSource: 'WMI, DKEFS, NEPSY'
  },
  processing_speed: {
    text: 'Skills related to one’s ability to process cognitive information quickly and efficiently.',
    suggestedSource: 'PSI'
  },
  adaptive_skills: {
    text: ' The practical and conceptual skills associated with',
    suggestedSource: 'ABAS, Vineland'
  },
  relational_skills: {
    text: 'The adaptive skills required to navigate relationships and environments to form connectedness with others.',
    suggestedSource: 'ABAS (Social); Vineland (Social); Information reported during intake.'
  },
  community_tearoff: 'You may find it helpful to use the open text box below to provide your client and/or their caregiver(s) with more information about local community organizations, services, activities, or supports that may provide helpful opportunities to foster their community engagement. This can also provide you with an opportunity to collaboratively plan for success in this area and identify FASD-informed programs and services.',
  education_tearoff: 'You may find it helpful to use the open text box below to provide your client and/or caregivers and educators additional information regarding education opportunities or particular areas of need that were identified within the assessment.',
  my_unique_needs: 'This open text box may be used to communicate specific areas of need based on the assessment results to other clinicians who may follow-up with your client.',
  my_unique_brain2: 'For this open text box, you may choose to include information related to the brain domains and the assessment results. The information could be helpful for other clinicians who may follow-up with your client.',
  adult_adolescent_tearoff: 'You may find it helpful to use the open text box below to provide your client with useful information regarding their diagnosis and unique areas of strength and need. For adolescent or adult clients it may be helpful to provide additional information on what a diagnosis of FASD means and which supports will be helpful moving forward.',
  justice_tearoff: 'You may find it helpful to include justice specific information for your clients in the open text box below.',
  no_diagnosis_tearoff: 'You may find it helpful to use this open text box to provide other recommendations or resources for your client who has not received an FASD diagnosis.'
};

export default values;
