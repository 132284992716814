import React from 'react';

const Blurb10_5 = () =>
  <>
    <p>
      Some people with FASD may experience challenges living on their own. Housing support can be seen as a spectrum, with individuals at one end needing lots of assistance and those at the other end needing little or no support. Different housing programs offer structured support based on the unique needs of the individual. Accessing FASD-informed housing programs can help to ensure each individual’s unique needs are met and lead to housing success.
    </p>
  </>;

export default Blurb10_5;
