import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const ParentDiagnosisForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.PARENT_DIAGNOSIS.id]);
  const isFormComplete = formData.parent_diagnosis !== '';
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.PARENT_DIAGNOSIS.id, updatedFormData));
  };

  const parentDiagnosisOptions = FORMS.PARENT_DIAGNOSIS.fields.parent_diagnosis.options;

  return (
    <>
      <form>
        <div className='grid-x grid-margin-y'>
          <div className='cell'>
            <label className='form-field'>{FORMS.PARENT_DIAGNOSIS.fields.parent_diagnosis.title}</label>
            <div className='grid-x small-up-2 medium-up-4'>
              {Object.keys(parentDiagnosisOptions).map(optionKey =>
                <RadioButton
                  checked={formData.parent_diagnosis === optionKey}
                  key={optionKey}
                  label={parentDiagnosisOptions[optionKey]}
                  onChange={event => handleFormChange({...formData, parent_diagnosis: event.target.value})}
                  value={optionKey}/>)}
            </div>
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

ParentDiagnosisForm.propTypes = {
  formIndex: PropTypes.number
};

export default ParentDiagnosisForm;
