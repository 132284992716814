import React from 'react';

const Blurb11_3B = () =>
  <>
    <p>
      If or when your child with FASD becomes a parent, it will be critical to help them use their strengths and develop parenting skills. Potential supports to consider include in-home services, advocacy, counselling, education, and pregnancy programs.
    </p>
    <p>
      People with FASD can have a hard time with regulation, which can make the unpredictability and stress of parenting even harder. Building skills to help stay calm under pressure while also learning to be flexible can help all parents, including those with FASD. Self-care will also be essential for your child’s parenting journey and will help them to care for their children. This may look like mental health and parenting support, access to support groups, self-care activities, or hobbies that bring them joy.
    </p>
  </>;

export default Blurb11_3B;
