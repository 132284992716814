import React from 'react';

const Blurb1_5E = () =>
  <>
    <p>
      Tailored intervention is important to support your/your child’s daily needs and behaviours. Based on the
      assessment results, you/your child did not meet diagnostic criteria for Fetal Alcohol Spectrum Disorder
      (FASD) at this time. It will be important to continue to monitor your/your child’s behaviours and
      functioning to support healthy outcomes throughout the lifespan.
    </p>
  </>;

export default Blurb1_5E;
