import React from 'react';

const Blurb10_4E = () =>
  <>
    <p>
      When it comes time to consider housing, it will be important to work together with the individual with FASD, their family/caregiver(s), and service providers. Collaboration can help to prioritize their unique housing needs, preferences, and goals, while also considering unique strengths, life experiences, and social supports.
    </p>
  </>;

export default Blurb10_4E;
