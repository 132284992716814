import React from 'react';

const Blurb3_3C = () =>
  <>
    <p>
      Your teen has encountered one or more disrupted attachment experiences. Even in circumstances where attachment is disrupted, it is possible to re-establish secure and strong relationships at all developmental periods.
    </p>
    <p>
      Providing support that specifically focuses on your teen’s relationship with their caregiver(s) may be an effective way to help them learn to read and respond to cues such as difficulties regulating emotions. This targeted support can also help identify appropriate ways to respond to your child’s cues based on their individual needs and in ways that make sense to them. Therapy that focuses on helping caregivers understand their teen’s unique brain, balance teen and caregiver needs, and adjust behaviours can support more secure attachment bonds between teens with FASD and their caregiver(s).
    </p>
  </>;

export default Blurb3_3C;
