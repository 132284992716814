import React from 'react';

const Blurb4_3C = () =>
  <>
    <p>
      There are many approaches you can take to help your teen improve their social abilities. Directly teaching social skills through modelling, conversations, and hangouts, can act as a protective factor as your teen navigates peer influences and may also promote overall well-being.
    </p>
  </>;

export default Blurb4_3C;
