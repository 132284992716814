import React from 'react';

const Blurb4_1C = () =>
  <>
    <p>
      Our social skills can influence our success in school and work, the relationships we have, and the way we see ourselves. The development of social skills is complex, with many more moving parts than we may realize! Social success requires the application of cognitive, behavioural, and emotional skills and knowledge, and is further impacted by environmental factors. Social strengths and needs are woven together in unique ways for each person.
    </p>
    <p>
      It is important to support the development of social skills across the lifespan because they can contribute to successful communication, interpersonal functioning, and adaptive behaviours. This means that your teen’s skills in these domains can be used to support their functioning in other, related domains, and prepare them for adulthood.
    </p>
  </>;

export default Blurb4_1C;
