import React from 'react';

const Blurb2_6E = () =>
  <>
    <p>
      Motor skills are required for our daily activities. Gross motor skills help us to walk, play sports, and exercise, while fine motor skills help us draw or use a smartphone or tablet. Motor difficulties can impact your ability to complete these everyday tasks. Motor skills can vary greatly from one person to another: some tasks may come easily, while others may be difficult.
    </p>
    <p>
      Understanding your/your child’s motor skills can help us to build successes and respond to difficulties. Motor functioning can be hard to predict sometimes. Observing and recording where you/your child may struggle can help us to understand and then provide the right supports in environments, like home, school, and work, that reduce stress and increase success.
    </p>
  </>;

export default Blurb2_6E;
