import React from 'react';

const Blurb8_2B = () =>
  <>
    <p>
      Nurturing mental health and well-being may look different for each child and can shift as children grow. Proactively monitoring for mental health challenges in your child allows you to identify possible needs or concerns early and respond effectively. Increasing positive experiences, reducing stress, and promoting well suited environments for children with FASD also promote mental wellness. It will be important to follow-up with mental health supports as your child grows through developmental stages and milestones. Here is a short guide to supporting mental well-being in individuals with FASD from CanFASD: <a href='https://canfasd.ca/2020/05/08/mental-health-for-individuals-with-fasd/'>
      https://canfasd.ca/2020/05/08/mental-health-for-individuals-with-fasd/</a>
    </p>
  </>;

export default Blurb8_2B;
