import React from 'react';

const Blurb8_12 = () =>
  <>
    <p>
      You/your child may benefit from targeted substance use support, which can look different for everyone. It will be important to access treatment services that are FASD-informed and wrap-around, including managing symptoms of withdrawal, building positive coping skills, and maintaining progress made during treatment. Considering your own goals for treatment and areas of strength will allow you to work with treatment specialists to tailor treatment to your own unique needs.
    </p>
  </>;

export default Blurb8_12;
