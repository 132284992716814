import React from 'react';

const Blurb5_2D = () =>
  <>
    <p>
      Sharing information regarding your unique needs may help supporters to identify educational approaches best suited to you in different learning environments. This may include self-advocacy or sharing <span className='text-bold'>FASD education</span> resources for your instructors and other supports to build skills and create environments that help you learn and develop skills. The Canada FASD Research Network offers free online FASD resources and training: <a href='https://estore.canfasd.ca/foundations-in-fasd'>https://estore.canfasd.ca/foundations-in-fasd</a>
    </p>
  </>;

export default Blurb5_2D;
