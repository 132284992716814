import React from 'react';

const Blurb8_13B = () =>
  <>
    <p>
      Children with FASD, like your child, often require ongoing support with mental health and regulation as they grow and transition, such as starting elementary school. Planning helps us to provide proactive supports and think about goals we hope to achieve, instead of only focusing on problems to be managed. In monitoring and supporting your child’s mental well-being, it can be helpful to identify areas of potential strengths and needs, and provide appropriate supports and accommodations, such as therapy or opportunities for skill-building.
    </p>
  </>;

export default Blurb8_13B;
