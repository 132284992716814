import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import TearoffTextBox from '../../../components/TearoffTextBox';
import Slider from '../../../components/Slider';
import instructions from '../../../../locales/instructions';
import SlidingScale from '../../../components/SlidingScale';

const TearOffForm = ({formIndex}) => {
  const selectedTearoffs = useSelector(state => state.forms[FORMS.TEAROFFS_SELECTORS.id]);
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS.id]);
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    // updatedFormData.my_unique_brain = FORMS.UNIQUE_BRAIN.fields.my_unique_brain.value;
    dispatch(submitForm(FORMS.TEAROFFS.id, updatedFormData));
  };

  return (
    <>
      <form>
        <div className='lead group-name'>Entered by clinician</div>
        <div className='grid-x grid-margin-x grid-margin-y'>
          <div className='cell large-5'>
            {selectedTearoffs.clinician_tearoff === '1' && <>
              <TearoffTextBox
                formData={formData}
                handleFormChange={handleFormChange}
                tearoffType='my_unique_needs'/>
              <TearoffTextBox
                formData={formData}
                handleFormChange={handleFormChange}
                tearoffType='my_unique_brain2'/>
            </>}
            {selectedTearoffs.community_tearoff === '1' && <TearoffTextBox
              formData={formData}
              handleFormChange={handleFormChange}
              tearoffType='community_tearoff'/>}
            {selectedTearoffs.education_tearoff === '1' && <TearoffTextBox
              formData={formData}
              handleFormChange={handleFormChange}
              tearoffType='education_tearoff'/>}
            {selectedTearoffs.adult_adolescent_tearoff === '1' && <TearoffTextBox
              formData={formData}
              handleFormChange={handleFormChange}
              tearoffType='adult_adolescent_tearoff'/>}
            {selectedTearoffs.justice_tearoff === '1' && <TearoffTextBox
              formData={formData}
              handleFormChange={handleFormChange}
              tearoffType='justice_tearoff'/>}
            {selectedTearoffs.no_diagnosis_tearoff === '1' && <TearoffTextBox
              formData={formData}
              handleFormChange={handleFormChange}
              tearoffType='no_diagnosis_tearoff'/>}

          </div>
          <div className='cell large-7'>
            <label className='instruction-heading'/>
            <p className='form-instructions'>
              {instructions.sliding_scale}
            </p>
            <div className='grid-x grid-margin-x grid-margin-y'>
              <div className='cell'>
                <SlidingScale/>
              </div>
              <div className='cell large-'>
                <Slider
                  formData={formData}
                  handleFormChange={handleFormChange}
                  sliderType='verbal_and_language'/>
                <Slider
                  formData={formData}
                  handleFormChange={handleFormChange}
                  sliderType='visual'/>
                <Slider
                  formData={formData}
                  handleFormChange={handleFormChange}
                  sliderType='attentional_control'/>
                <Slider
                  formData={formData}
                  handleFormChange={handleFormChange}
                  sliderType='processing_speed'/>
                <Slider
                  formData={formData}
                  handleFormChange={handleFormChange}
                  sliderType='adaptive_skills'/>
                <Slider
                  formData={formData}
                  handleFormChange={handleFormChange}
                  sliderType='relational_skills'/>
              </div>
            </div>
          </div>
        </div>

      </form>

      <FormNav
        canSubmit
        formIndex={formIndex}/>
    </>
  );
};

export default TearOffForm;

TearOffForm.propTypes = {
  formIndex: PropTypes.number.isRequired
};
