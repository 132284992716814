import React from 'react';

const Blurb8_13D = () =>
  <>
    <p>
      Adults with FASD, often require ongoing support with mental health and regulation as they navigate adulthood and major life events. Planning helps us to provide proactive supports and think about goals we hope to achieve, instead of only focusing on problems to be managed. You may find it helpful to speak to important people in your life or your supportive networks who can help you to plan and find opportunities to engage in therapy or build skills that will support you in coping with your unique mental wellness needs.
    </p>
  </>;

export default Blurb8_13D;
