import React from 'react';

const Blurb2_2C = () =>
  <>
    <p>
      It will be important to connect with primary health care providers (e.g., doctor, nurse practitioner) as your teen grows through developmental stages and milestones.
    </p>
  </>;

export default Blurb2_2C;
