import React from 'react';

const Blurb11_1C = () =>
  <>
    <p>
      Your teen may eventually become a parent, or they may already be one. Many parents need support, including those with FASD. Supporting someone who becomes a parent can be rewarding and challenging. It requires consistency, strength, and resilience. With appropriate supports, people with FASD can be successful and fulfilled as a parent.
    </p>
  </>;

export default Blurb11_1C;
