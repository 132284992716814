import React from 'react';
import PropTypes from 'prop-types';

const PaddedPrintLayout = ({children}) =>
  <table className='border-none'>
    <thead/>
    <tbody className='border-none'>
      {children}
    </tbody>
    <tfoot/>
  </table>;

PaddedPrintLayout.propTypes = {
  children: PropTypes.any.isRequired
};

export default PaddedPrintLayout;
