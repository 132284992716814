import React from 'react';
import SectionTitle from '../components/SectionTitle';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Variables from '../components/Variables';
import Blurb1_2B from '../../../blurbs/1_MyUniqueNeeds/Blurb1_2B';
import Blurb1_2C from '../../../blurbs/1_MyUniqueNeeds/Blurb1_2C';
import Blurb1_2D from '../../../blurbs/1_MyUniqueNeeds/Blurb1_2D';
import Blurb1_2E from '../../../blurbs/1_MyUniqueNeeds/Blurb1_2E';
import Blurb1_3A from '../../../blurbs/1_MyUniqueNeeds/Blurb1_3A';
import Blurb1_3B from '../../../blurbs/1_MyUniqueNeeds/Blurb1_3B';
import Blurb1_3C from '../../../blurbs/1_MyUniqueNeeds/Blurb1_3C';
import Blurb1_3D from '../../../blurbs/1_MyUniqueNeeds/Blurb1_3D';
import Blurb1_3E from '../../../blurbs/1_MyUniqueNeeds/Blurb1_3E';
import Blurb1_4A from '../../../blurbs/1_MyUniqueNeeds/Blurb1_4A';
import Blurb1_4B from '../../../blurbs/1_MyUniqueNeeds/Blurb1_4B';
import Blurb1_4C from '../../../blurbs/1_MyUniqueNeeds/Blurb1_4C';
import Blurb1_4D from '../../../blurbs/1_MyUniqueNeeds/Blurb1_4D';
import Blurb1_4E from '../../../blurbs/1_MyUniqueNeeds/Blurb1_4E';
import Blurb1_5A from '../../../blurbs/1_MyUniqueNeeds/Blurb1_5A';
import Blurb1_5B from '../../../blurbs/1_MyUniqueNeeds/Blurb1_5B';
import Blurb1_5C from '../../../blurbs/1_MyUniqueNeeds/Blurb1_5C';
import Blurb1_5D from '../../../blurbs/1_MyUniqueNeeds/Blurb1_5D';
import Blurb1_5E from '../../../blurbs/1_MyUniqueNeeds/Blurb1_5E';
import Blurb1_6 from '../../../blurbs/1_MyUniqueNeeds/Blurb1_6';
import Blurb1_9 from '../../../blurbs/1_MyUniqueNeeds/Blurb1_9';
import Blurb1_1 from '../../../blurbs/1_MyUniqueNeeds/Blurb1_1';
import Blurb1_2A from '../../../blurbs/1_MyUniqueNeeds/Blurb1_2A';
import Blurb1_7B from '../../../blurbs/1_MyUniqueNeeds/Blurb1_7B';
import Blurb1_7C from '../../../blurbs/1_MyUniqueNeeds/Blurb1_7C';
import Blurb1_7D from '../../../blurbs/1_MyUniqueNeeds/Blurb1_7D';
import Blurb1_8B from '../../../blurbs/1_MyUniqueNeeds/Blurb1_8B';
import Blurb1_8C from '../../../blurbs/1_MyUniqueNeeds/Blurb1_8C';
import Blurb1_8D from '../../../blurbs/1_MyUniqueNeeds/Blurb1_8D';
import Chart from '../../../components/Chart';
import Card from '../components/Card';
import TwoColumnLayout from './TwoColumnLayout';

const MyUniqueNeeds = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {cognition_brain, attention_brain, adaptive_brain, executive_brain, language_brain, memory_brain, academic_brain} = useSelector(state => state.forms[FORMS.BRAIN_DOMAIN_ASSESSMENT.id]);
  const {iq} = useSelector(state => state.forms[FORMS.IQ_SCORE.id]);
  const {intellectual} = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);
  const variables = {diagnosis, age, cognition_brain, attention_brain, adaptive_brain, language_brain, executive_brain, academic_brain, memory_brain, iq, intellectual};
  const formData = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);

  const MyUniqueNeedsBlurb1_2A = diagnosis === '0' && age === '1';
  const MyUniqueNeedsBlurb1_2B = diagnosis === '0' && age === '2';
  const MyUniqueNeedsBlurb1_2C = diagnosis === '0' && age === '3';
  const MyUniqueNeedsBlurb1_2D = diagnosis === '0' && age === '4';
  const MyUniqueNeedsBlurb1_2E = diagnosis === '0' && age === '5';

  const MyUniqueNeedsBlurb1_3A = diagnosis === '1' && age === '1';
  const MyUniqueNeedsBlurb1_3B = diagnosis === '1' && age === '2';
  const MyUniqueNeedsBlurb1_3C = diagnosis === '1' && age === '3';
  const MyUniqueNeedsBlurb1_3D = diagnosis === '1' && age === '4';
  const MyUniqueNeedsBlurb1_3E = diagnosis === '1' && age === '5';

  const MyUniqueNeedsBlurb1_4A = diagnosis === '2' && age === '1';
  const MyUniqueNeedsBlurb1_4B = diagnosis === '2' && age === '2';
  const MyUniqueNeedsBlurb1_4C = diagnosis === '2' && age === '3';
  const MyUniqueNeedsBlurb1_4D = diagnosis === '2' && age === '4';
  const MyUniqueNeedsBlurb1_4E = diagnosis === '2' && age === '5';

  const MyUniqueNeedsBlurb1_5A = diagnosis === '3' && age === '1';
  const MyUniqueNeedsBlurb1_5B = diagnosis === '3' && age === '2';
  const MyUniqueNeedsBlurb1_5C = diagnosis === '3' && age === '3';
  const MyUniqueNeedsBlurb1_5D = diagnosis === '3' && age === '4';
  const MyUniqueNeedsBlurb1_5E = diagnosis === '3' && age === '5';

  const MyUniqueNeedsBlurb1_6 = diagnosis === '0' || diagnosis === '1' || diagnosis === '2';

  const MyUniqueNeedsBlurb1_7B = (attention_brain === '1' && executive_brain === '1' ||
      executive_brain === '1' && adaptive_brain === '1' ||
      adaptive_brain === '1' && attention_brain === '1') &&
    ((iq === '1' || iq === '2' || iq === '3') &&
      cognition_brain === '0' && intellectual === '0') &&
    age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const MyUniqueNeedsBlurb1_7C = (attention_brain === '1' && executive_brain === '1' ||
      executive_brain === '1' && adaptive_brain === '1' ||
      adaptive_brain === '1' && attention_brain === '1') &&
    ((iq === '1' || iq === '2' || iq === '3') &&
      cognition_brain === '0' && intellectual === '0') &&
    age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const MyUniqueNeedsBlurb1_7D = (attention_brain === '1' && executive_brain === '1' ||
      executive_brain === '1' && adaptive_brain === '1' ||
      adaptive_brain === '1' && attention_brain === '1') &&
    ((iq === '1' || iq === '2' || iq === '3') &&
      cognition_brain === '0' && intellectual === '0') &&
    age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const MyUniqueNeedsBlurb1_8B = cognition_brain === '1' && adaptive_brain === '1' && academic_brain === '1' &&
    (language_brain === '1' && memory_brain === '1' ||
      memory_brain === '1' && attention_brain === '1' ||
      attention_brain === '1' && language_brain === '1') &&
    age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const MyUniqueNeedsBlurb1_8C = cognition_brain === '1' && adaptive_brain === '1' && academic_brain === '1' &&
    (language_brain === '1' && memory_brain === '1' ||
      memory_brain === '1' && attention_brain === '1' ||
      attention_brain === '1' && language_brain === '1') &&
    age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const MyUniqueNeedsBlurb1_8D = cognition_brain === '1' && adaptive_brain === '1' && academic_brain === '1' &&
    (language_brain === '1' && memory_brain === '1' ||
      memory_brain === '1' && attention_brain === '1' ||
      attention_brain === '1' && language_brain === '1') &&
    age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const MyUniqueNeedsBlurb1_9 = age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  return <section>
    <SectionTitle>My Unique Needs</SectionTitle>
    <Variables variableObject={variables}/>
    <Card>
      <h3 className='chapter-title'>
        <span className='card-title'>Welcome</span>
      </h3>
      <Blurb1_1/>
    </Card>
    {
      (MyUniqueNeedsBlurb1_2A || MyUniqueNeedsBlurb1_2B || MyUniqueNeedsBlurb1_2C || MyUniqueNeedsBlurb1_2D || MyUniqueNeedsBlurb1_2E ||
        MyUniqueNeedsBlurb1_3A || MyUniqueNeedsBlurb1_3B || MyUniqueNeedsBlurb1_3C || MyUniqueNeedsBlurb1_3D || MyUniqueNeedsBlurb1_3E ||
        MyUniqueNeedsBlurb1_4A || MyUniqueNeedsBlurb1_4B || MyUniqueNeedsBlurb1_4C || MyUniqueNeedsBlurb1_4D || MyUniqueNeedsBlurb1_4E ||
        MyUniqueNeedsBlurb1_5A || MyUniqueNeedsBlurb1_5B || MyUniqueNeedsBlurb1_5C || MyUniqueNeedsBlurb1_5D || MyUniqueNeedsBlurb1_5E) &&
      <Card>
        <h3 className='chapter-title'>
          My Unique <span className='card-title'>Needs</span>
        </h3>
        <TwoColumnLayout>
          <>
            {MyUniqueNeedsBlurb1_2A && <Blurb1_2A/>}
            {MyUniqueNeedsBlurb1_2B && <Blurb1_2B/>}
            {MyUniqueNeedsBlurb1_2C && <Blurb1_2C/>}
            {MyUniqueNeedsBlurb1_2D && <Blurb1_2D/>}
            {MyUniqueNeedsBlurb1_2E && <Blurb1_2E/>}
          </>
          <>
            {MyUniqueNeedsBlurb1_3A && <Blurb1_3A/>}
            {MyUniqueNeedsBlurb1_3B && <Blurb1_3B/>}
            {MyUniqueNeedsBlurb1_3C && <Blurb1_3C/>}
            {MyUniqueNeedsBlurb1_3D && <Blurb1_3D/>}
            {MyUniqueNeedsBlurb1_3E && <Blurb1_3E/>}
          </>
          <>
            {MyUniqueNeedsBlurb1_4A && <Blurb1_4A/>}
            {MyUniqueNeedsBlurb1_4B && <Blurb1_4B/>}
            {MyUniqueNeedsBlurb1_4C && <Blurb1_4C/>}
            {MyUniqueNeedsBlurb1_4D && <Blurb1_4D/>}
            {MyUniqueNeedsBlurb1_4E && <Blurb1_4E/>}
          </>
          <>
            {MyUniqueNeedsBlurb1_5A && <Blurb1_5A/>}
            {MyUniqueNeedsBlurb1_5B && <Blurb1_5B/>}
            {MyUniqueNeedsBlurb1_5C && <Blurb1_5C/>}
            {MyUniqueNeedsBlurb1_5D && <Blurb1_5D/>}
            {MyUniqueNeedsBlurb1_5E && <Blurb1_5E/>}
          </>
        </TwoColumnLayout>
      </Card>
    }
    <Chart/>
    {
      (MyUniqueNeedsBlurb1_6 ||
        MyUniqueNeedsBlurb1_7B || MyUniqueNeedsBlurb1_7C || MyUniqueNeedsBlurb1_7D ||
        MyUniqueNeedsBlurb1_8B || MyUniqueNeedsBlurb1_8C || MyUniqueNeedsBlurb1_8D ||
        MyUniqueNeedsBlurb1_9) &&
      <Card>
        <h3 className='chapter-title'>
          My Unique <span className='card-title'>Brain</span>
        </h3>
        <TwoColumnLayout>

          <>{MyUniqueNeedsBlurb1_6 && <Blurb1_6/>}</>
          {
            formData.my_unique_brain && <p>
              {formData.my_unique_brain}
            </p>
          }
          <>
            {MyUniqueNeedsBlurb1_7B && <Blurb1_7B/>}
            {MyUniqueNeedsBlurb1_7C && <Blurb1_7C/>}
            {MyUniqueNeedsBlurb1_7D && <Blurb1_7D/>}
          </>
          <>
            {MyUniqueNeedsBlurb1_8B && <Blurb1_8B/>}
            {MyUniqueNeedsBlurb1_8C && <Blurb1_8C/>}
            {MyUniqueNeedsBlurb1_8D && <Blurb1_8D/>}
          </>
          <>
            {MyUniqueNeedsBlurb1_9 && <Blurb1_9/>}
          </>
        </TwoColumnLayout>
      </Card>
    }
  </section>;
};

export default MyUniqueNeeds;
