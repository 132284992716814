import React from 'react';

const Blurb6_2B = () =>
  <>
    <p>
      To help promote your child’s identity development, provide them with opportunities to explore their unique interests, beliefs, and values. Conversations, modelling, and play can all be important ways to explore identity and can lead to greater self-awareness and insight. When supporters understand your child’s unique identity, they are better equipped to address challenges and build on strengths as your child continues to grow and develop.
    </p>
  </>;

export default Blurb6_2B;
