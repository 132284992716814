import React from 'react';

const Blurb7_22C = () =>
  <>
    <p>
      Accessing mentoring or coaching resources in the community may help your teen. Supports like this can help teens develop a sense of belonging and connection to others and to grow adaptive skills. Learning by doing is often based on relationships, a common area of strength for teens with FASD. Social and recreational activities can also help your teen to create and maintain relationships and regulate their feelings.
    </p>
  </>;

export default Blurb7_22C;
