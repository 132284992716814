import React from 'react';

const Blurb7_24E = () =>
  <>
    <p>
      To support food security, it may be helpful to contact community organizations and your/your child’s support team about accessing your local food bank or other services.
    </p>
  </>;

export default Blurb7_24E;
