import React from 'react';

const Blurb14_2A = () =>
  <>
    <p>
      Fetal Alcohol Spectrum Disorder (FASD) is a diagnostic term that describes the impacts on the brains and bodies of people who have prenatal alcohol exposure (PAE). At least 4% of people living in Canada likely have FASD, and experience a range of neurodevelopmental challenges, mental and physical health impacts, and elevated rates of environmental adversity. This unique and diverse population is often referred to clinical services in many areas, such as building developmental skills (e.g., speech, language, or motor skills) mental health supports (e.g., psychologist, psychiatrist, or mental health specialist), and physical health needs, which often appear at ages much younger than expected.
    </p>
    <p>
      Clinicians’ knowledge of FASD can help guide treatment planning and clinical decision-making and facilitate early intervention efforts for proactive support implementation. Clinicians can also help in advancing understandings, coordinating services, and advocating for integrated and cohesive support across settings. Ideally clinicians can build relationships with individuals and families and embed lived expertise into support planning to support people with FASD in experiencing healthy outcomes.
    </p>
    <p>
      Training resources and information is available
      at: <a href='https://canfasd.ca/publications/towards-healthy-outcomes/'>
      https://canfasd.ca/publications/towards-healthy-outcomes/</a> and <a href='https://canfasd.ca/online-learners/'>
      https://canfasd.ca/online-learners/</a>
    </p>
    <p>
      Your client was diagnosed with FASD without Sentinel Facial Features. This means that while some individuals may have physical features that are unique to PAE, other individuals like your client, do not. These features do not necessarily provide information about your client’s unique brain- or body-based needs. Through individualized, ongoing supports, individuals with FASD can experience success and achieve healthy outcomes.
    </p>
  </>;

export default Blurb14_2A;
