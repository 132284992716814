import React from 'react';

const Blurb5_12C = () =>
  <>
    <p>
      Your teen would benefit from educational re-assessment as they progress through the school system. With up to date understanding of your teen’s strengths and needs, targeted support to address challenges with learning and academic achievement is possible.
    </p>
  </>;

export default Blurb5_12C;
