import React from 'react';

const Blurb5_6C = () =>
  <>
    <p>
      Your teen may struggle with academics, such as difficulties with problem-solving, complex ideas, reasoning, language, achievement, and memory. Without these skills, learning and responding to challenges within educational (and other) settings can be hard. Understanding your teen’s unique areas of difficulty and strengths is an important first step in working collaboratively with educators and other systems to implement well suited supports and interventions. Adolescents can function at their best when supported in growing skills within reasonable expectations.
    </p>
  </>;

export default Blurb5_6C;
