import React from 'react';

const Blurb4_3E = () =>
  <>
    <p>
      There are many approaches you/your child can take to help yourself/your child to improve your/their social skills. For children and teens, directly teaching social skills through modelling, conversations, and instruction, can be a protective factor as your child navigates peer influences and establishes long-lasting, prosocial friendships. For adults,
      mentorship programming may be one way to assist with social skill development, often while engaging in desired activities.
    </p>
  </>;

export default Blurb4_3E;
