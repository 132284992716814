import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const MedicationsForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.MEDICATIONS.id]);
  const dispatch = useDispatch();
  const medications = FORMS.MEDICATIONS.fields;
  const fieldOptions = FORMS.MEDICATIONS.options;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] !== '');
  const isFormComplete = checkedFields.length === Object.keys(formData).length;

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.MEDICATIONS.id, updatedFormData));
  };

  return (
    <>
      <form>
        <label className='form-field padding-bottom-2 note'>Note: Please select at least one/all that apply.</label>
        <div className='grid-x grid-margin-y'>
          <div className='cell medium-9 large-6'>
            <div className='grid-x'>
              <div className='cell small-4'/>
              <div className='cell small-8'>
                <div className='grid-x small-up-1 medium-up-3'>
                  {
                    Object.values(fieldOptions).map(fieldOption =>
                      <div
                        className='auto cell text-center'
                        key={fieldOption}>
                        <label className='form-field'>{fieldOption}</label>
                      </div>)
                  }
                </div>
              </div>
            </div>
            {Object.keys(medications).map(medicationKey =>
              <div
                className='grid-x grid-margin-x grid-margin-y align-middle'
                key={medicationKey}>
                <div className='cell small-4'>
                  <label className='form-field'>{medications[medicationKey].title}</label>
                </div>
                <div className='cell small-8'>
                  <div
                    className='grid-x grid-margin-x'>
                    {Object.keys(fieldOptions).map(optionKey =>
                      <div
                        className='auto cell text-center'
                        key={optionKey}>
                        <input
                          checked={formData[medicationKey] === optionKey}
                          className='margin-right-0'
                          id={`answer_${optionKey}`}
                          key={optionKey}
                          name={medicationKey}
                          onChange={event => handleFormChange({...formData, [medicationKey]: event.target.value})}
                          type='radio'
                          value={optionKey}
                        />
                      </div>)}
                  </div>
                </div>
              </div>)}
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

MedicationsForm.propTypes = {
  formIndex: PropTypes.number
};

export default MedicationsForm;
