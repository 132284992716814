import React from 'react';

const Blurb1_3D = () =>
  <>
    <p>
      The assessment provided an opportunity to understand how your needs and behaviours can be supported
      through intervention. Prenatal alcohol exposure (PAE) impacts the brains and bodies of people in
      different ways. Your assessment team indicated that you have Fetal Alcohol Spectrum Disorder (FASD)
      without Sentinel Facial Features. Although some individuals may have physical features that are
      unique to PAE, other individuals like yourself, do not. Individualized, ongoing supports can help all
      individuals with FASD to experience success and achieve healthy outcomes.
    </p>
  </>;

export default Blurb1_3D;
