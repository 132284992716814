import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import {useSelector} from 'react-redux';
import Variables from '../components/Variables';
import FORMS from '../../../../redux/constants';
import Blurb13_1A from '../../../blurbs/13_EducationTearoff/Blurb13_1A';
import Blurb13_1B from '../../../blurbs/13_EducationTearoff/Blurb13_1B';
import Blurb13_1C from '../../../blurbs/13_EducationTearoff/Blurb13_1C';
import Blurb13_1D from '../../../blurbs/13_EducationTearoff/Blurb13_1D';
import Blurb13_1E from '../../../blurbs/13_EducationTearoff/Blurb13_1E';
import Blurb13_12A from '../../../blurbs/13_EducationTearoff/Blurb13_12A';
import Blurb13_12B from '../../../blurbs/13_EducationTearoff/Blurb13_12B';
import Blurb13_12C from '../../../blurbs/13_EducationTearoff/Blurb13_12C';
import Blurb13_12D from '../../../blurbs/13_EducationTearoff/Blurb13_12D';
import Blurb13_12E from '../../../blurbs/13_EducationTearoff/Blurb13_12E';
import Blurb13_22A from '../../../blurbs/13_EducationTearoff/Blurb13_22A';
import Blurb13_22B from '../../../blurbs/13_EducationTearoff/Blurb13_22B';
import Blurb13_22C from '../../../blurbs/13_EducationTearoff/Blurb13_22C';
import Blurb13_22D from '../../../blurbs/13_EducationTearoff/Blurb13_22D';
import Blurb13_22E from '../../../blurbs/13_EducationTearoff/Blurb13_22E';
import Blurb13_33A from '../../../blurbs/13_EducationTearoff/Blurb13_33A';
import Blurb13_33B from '../../../blurbs/13_EducationTearoff/Blurb13_33B';
import Blurb13_33C from '../../../blurbs/13_EducationTearoff/Blurb13_33C';
import Blurb13_33D from '../../../blurbs/13_EducationTearoff/Blurb13_33D';
import Blurb13_33E from '../../../blurbs/13_EducationTearoff/Blurb13_33E';
import TwoColumnLayout from './TwoColumnLayout';
import PaddedPrintLayout from '../../../components/PaddedPrintLayout';

const EducationTearoff = () => {
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {intellectual} = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);
  const {iq} = useSelector(state => state.forms[FORMS.IQ_SCORE.id]);
  const {academic_brain} = useSelector(state => state.forms[FORMS.BRAIN_DOMAIN_ASSESSMENT.id]);
  const {odd, ptsd, conduct, personality, schizophrenia, anxiety, mood, bipolar} = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS.id]);

  const EducationTearoffBlurb13_1A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_1B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_1C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_1D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_1E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationTearoffBlurb13_12A = intellectual === '1' || iq === '0' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_12B = intellectual === '1' || iq === '0' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_12C = intellectual === '1' || iq === '0' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_12D = intellectual === '1' || iq === '0' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_12E = intellectual === '1' || iq === '0' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationTearoffBlurb13_22A = academic_brain === '1' && age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_22B = academic_brain === '1' && age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_22C = academic_brain === '1' && age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_22D = academic_brain === '1' && age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_22E = academic_brain === '1' && age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EducationTearoffBlurb13_33A = (ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' || anxiety === '1' ||
      mood === '1' || bipolar === '1' || odd === '1') && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_33B = (ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' || anxiety === '1' ||
      mood === '1' || bipolar === '1' || odd === '1') && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_33C = (ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' || anxiety === '1' ||
      mood === '1' || bipolar === '1' || odd === '1') && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_33D = (ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' || anxiety === '1' ||
      mood === '1' || bipolar === '1' || odd === '1') && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EducationTearoffBlurb13_33E = (ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' || anxiety === '1' ||
      mood === '1' || bipolar === '1' || odd === '1') && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const variables = {age, diagnosis, iq, intellectual, academic_brain, odd, ptsd, conduct,
    personality, schizophrenia, anxiety, mood, bipolar};

  if (formData.education_tearoff ||
    EducationTearoffBlurb13_1A || EducationTearoffBlurb13_1B || EducationTearoffBlurb13_1C || EducationTearoffBlurb13_1D || EducationTearoffBlurb13_1E) {
    return <PaddedPrintLayout>
      <section>
        <SectionTitle>Tailored Feedback - Education</SectionTitle>
        <Variables variableObject={variables}/>
        <Card>
          <h3 className='chapter-title'>
            <span className='card-title'>Education</span>
          </h3>
          <TwoColumnLayout>
            <>
              {EducationTearoffBlurb13_1A && <Blurb13_1A/>}
              {EducationTearoffBlurb13_1B && <Blurb13_1B/>}
              {EducationTearoffBlurb13_1C && <Blurb13_1C/>}
              {EducationTearoffBlurb13_1D && <Blurb13_1D/>}
              {EducationTearoffBlurb13_1E && <Blurb13_1E/>}
            </>
            {
              formData.education_tearoff && <p>
                {formData.education_tearoff}
              </p>
            }
            <>
              {EducationTearoffBlurb13_12A && <Blurb13_12A/>}
              {EducationTearoffBlurb13_12B && <Blurb13_12B/>}
              {EducationTearoffBlurb13_12C && <Blurb13_12C/>}
              {EducationTearoffBlurb13_12D && <Blurb13_12D/>}
              {EducationTearoffBlurb13_12E && <Blurb13_12E/>}
            </>
            <>
              {EducationTearoffBlurb13_22A && <Blurb13_22A/>}
              {EducationTearoffBlurb13_22B && <Blurb13_22B/>}
              {EducationTearoffBlurb13_22C && <Blurb13_22C/>}
              {EducationTearoffBlurb13_22D && <Blurb13_22D/>}
              {EducationTearoffBlurb13_22E && <Blurb13_22E/>}
            </>
            <>
              {EducationTearoffBlurb13_33A && <Blurb13_33A/>}
              {EducationTearoffBlurb13_33B && <Blurb13_33B/>}
              {EducationTearoffBlurb13_33C && <Blurb13_33C/>}
              {EducationTearoffBlurb13_33D && <Blurb13_33D/>}
              {EducationTearoffBlurb13_33E && <Blurb13_33E/>}
            </>
          </TwoColumnLayout>
        </Card>
      </section>
    </PaddedPrintLayout>;
  }

  return null;
};

export default EducationTearoff;
