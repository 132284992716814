import React from 'react';

const Blurb3_4A = () =>
  <>
    <p>
      Your child may have had past traumatic experiences, which can influence your child’s attachment, how they perceive themselves and others, and their relationships with caregivers. Attachment-based therapy can help children with FASD to develop healthy coping skills, and support attachment relationships, family cohesion, and overall well-being.
    </p>
  </>;

export default Blurb3_4A;
