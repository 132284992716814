import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const SiblingDiagnosisForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.SIBLING_DIAGNOSIS.id]);
  const isFormComplete = formData.sibling_diagnosis !== '';
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.SIBLING_DIAGNOSIS.id, updatedFormData));
  };

  const siblingDiagnosisOptions = FORMS.SIBLING_DIAGNOSIS.fields.sibling_diagnosis.options;

  return (
    <>
      <form>
        <div className='grid-x grid-margin-y'>
          <div className='cell large-7'>
            <label className='form-field'>{FORMS.SIBLING_DIAGNOSIS.fields.sibling_diagnosis.title}</label>
            <div className='grid-x small-up-2'>
              {Object.keys(siblingDiagnosisOptions).map(optionKey =>
                <RadioButton
                  checked={formData.sibling_diagnosis === optionKey}
                  className='cell'
                  key={optionKey}
                  label={siblingDiagnosisOptions[optionKey]}
                  onChange={event => handleFormChange({...formData, sibling_diagnosis: event.target.value})}
                  value={optionKey}/>)}
            </div>
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

SiblingDiagnosisForm.propTypes = {
  formIndex: PropTypes.number
};

export default SiblingDiagnosisForm;
