import React from 'react';

const Blurb8_3C = () =>
  <>
    <p>
      Your teen may be experiencing some challenges with self-regulation, and struggle to manage their behaviour, emotions, and attention. This can result in difficulty with behaviours, focus, and relationships. Helpful self-regulation strategies may include mindfulness or physical activities. Strategies that are co-created with your teen, and that are individualized, targeted, and with clear structure and routine may also be helpful. Learn more about helping your teen self-regulate with this resource: <a href='https://canfasd.ca/2020/09/07/supporting-self-regulation-for-back-to-school/'>
      https://canfasd.ca/2020/09/07/supporting-self-regulation-for-back-to-school/</a>
    </p>
  </>;

export default Blurb8_3C;
