import React from 'react';

const Blurb8_1B = () =>
  <>
    <p>
      Mental health is fundamental to daily living and involves emotional and psychological well-being and regulation. Children with FASD often have more mental health needs than might be expected, so ongoing monitoring of your child’s mental health is important. As your child continues to grow and develop, they should be provided with developmentally appropriate information and support that promotes mental health and regulation.
    </p>
  </>;

export default Blurb8_1B;
