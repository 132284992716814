import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const SexForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.SEX.id]);
  const isFormComplete = formData.sex !== '';
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.SEX.id, updatedFormData));
  };

  const sexOptions = FORMS.SEX.fields.sex.options;

  return (
    <>
      <form>
        <div className='grid-x large-up-2'>
          <div className='cell'>
            <label className='form-field'>{FORMS.SEX.fields.sex.title}</label>
            <div className='grid-x small-up-2 medium-up-4'>
              {Object.keys(sexOptions).map(optionKey =>
                <RadioButton
                  checked={formData.sex === optionKey}
                  className='cell'
                  key={optionKey}
                  label={sexOptions[optionKey]}
                  onChange={event => handleFormChange({...formData, sex: event.target.value})}
                  value={optionKey}/>)}
            </div>
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

SexForm.propTypes = {
  formIndex: PropTypes.number
};

export default SexForm;
