import React from 'react';

const Blurb9_1E = () =>
  <>
    <p>
      People with FASD bring many strengths to the workplace but can also experience brain-based differences and barriers on the pathway to successful employment, especially in work environments that are not set up to support success. Employers may not understand FASD, leading to possible stigma. With the right supports, people with FASD can obtain and maintain employment, which can support healthy development, and promote a great sense of purpose, self-identity, self-understanding, self-worth, and feelings of connection to community and others.
    </p>
  </>;

export default Blurb9_1E;
