import React from 'react';

const Blurb5_14D = () =>
  <>
    <p>
      You may benefit from FASD-informed occupational therapy. This often involves working with an occupational therapist who can identify and address functioning with day-to-day activities and support your success at home, work, or community contexts.
    </p>
  </>;

export default Blurb5_14D;
