import React from 'react';

const Blurb7_1A = () =>
  <>
    <p>
      As people grow and enter adulthood, they need new skills to meet the increased desire for personal autonomy and choice. People with FASD show a wide range of abilities and needs that inform the types of support they need. Balancing autonomy with support is interdependence, which can look different for each person.
    </p>
    <p>
      Two important pieces of interdependence are community engagement and adaptive functioning. Community engagement means a person feels emotionally and physically part of a community. It can look like access to social activities, medical and social services, and autonomy. Adaptive skills are practical, everyday skills that evolve as people grow and develop. They can include self-care, personal hygiene, money management, and social functioning. Everyone, including your child, has a unique range of community engagement and adaptive strengths and needs.
    </p>
  </>;

export default Blurb7_1A;
