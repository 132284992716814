import React from 'react';

const Blurb5_5D = () =>
  <>
    <p>
      You may struggle with processing speed, or quickly and efficiently taking in, interpreting, and acting on new information. These difficulties can impact learning and classroom behaviours as it may take more time to make sense of instructions or to complete a task. Processing speed may also vary based on slight changes to task expectations more than expected. Support strategies can include giving more time to complete tasks, simplifying the number and nature of inputs, repeating instructions, and/or pairing verbal and visual instructions.
    </p>
  </>;

export default Blurb5_5D;
