import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const IQScoreForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.IQ_SCORE.id]);
  const isFormComplete = formData.iq !== '';
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.IQ_SCORE.id, updatedFormData));
  };

  const iQOptions = FORMS.IQ_SCORE.fields.iq.options;

  return (

    <>
      <form>
        <div className='grid-x'>
          <div className='cell large-7'>
            <label className='form-field'>{FORMS.IQ_SCORE.fields.iq.title}</label>
            <div className='grid-x small-up-2 medium-up-3'>
              {Object.keys(iQOptions).map(optionKey =>
                <RadioButton
                  checked={formData.iq === optionKey}
                  className='cell'
                  key={optionKey}
                  label={iQOptions[optionKey]}
                  onChange={event => handleFormChange({...formData, iq: event.target.value})}
                  value={optionKey}/>)}
            </div>
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

IQScoreForm.propTypes = {
  formIndex: PropTypes.number
};

export default IQScoreForm;
