import React from 'react';
import FORMS from '../../redux/constants';
import {FORM_ROUTES} from '../../routes';
import {useParams} from 'react-router-dom';

const ProgressBar = () => {
  const {url} = useParams();
  const formIndex = FORM_ROUTES.findIndex(route => route.url === url);
  const completedFormsPercentage = Math.ceil((formIndex + 1) / Object.keys(FORMS).length * 100);
  return (
    <div className='collapse-grid-container'>
      <div
        aria-valuemax='100'
        aria-valuemin='0'
        aria-valuenow={completedFormsPercentage}
        className='warning progress'
        role='progressbar'
        style={{width: '100%'}}>
        <span
          className='progress-meter'
          style={{width: `${completedFormsPercentage}%`}}>
          <span className='progress-meter-text'>{`${completedFormsPercentage}%`}</span>
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
