import React from 'react';

const Blurb5_15D = () =>
  <>
    <p>
      You may benefit from specialized and FASD-informed behavioural support which may include intervention for self-regulation, cognition, and adaptive functioning. Behavioural specialists work with adults and teach techniques and strategies to improve success across a variety of settings like home and work.
    </p>
  </>;

export default Blurb5_15D;
