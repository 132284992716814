import React from 'react';

const Blurb10_2 = () =>
  <>
    <p>
      Housing supports can look different for everyone. Some people can live with little to no support, whereas others need help to ensure they can maintain safe housing. Potential supports may include financial aid and help with daily living, such as reminders about paying rent and bills, buying groceries, cooking, and cleaning. It may be helpful to talk to your local FASD clinic or advocacy group to learn about resources in your area.
    </p>
  </>;

export default Blurb10_2;
