import React from 'react';

const Blurb8_4D = () =>
  <>
    <p>
      You may benefit from mental health services, such as individualized therapy, group therapy, medication, and/or family intervention. Your mental health care provider should be licensed with local professional bodies, and knowledgeable about FASD-informed interventions that will work for you.
    </p>
  </>;

export default Blurb8_4D;
