import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import SpecifyTextBox from '../../../components/SpecifyTextBox';

const PrenatalAlcoholForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.PRENATAL_ALCOHOL_EXPOSURE.id]);
  const isFormComplete = formData.exposure !== '' &&
    (formData.exposure === FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.condition
      ? formData.exposure_specify.length > 0 : true);
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    if (formData.exposure !== updatedFormData.exposure) {
      updatedFormData[FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.id] = FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.value;
    }

    dispatch(submitForm(FORMS.PRENATAL_ALCOHOL_EXPOSURE.id, updatedFormData));
  };

  const prenatalAlcoholExposureOptions = FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.options;

  return (

    <>
      <form>
        <div className='grid-x grid-margin-y'>
          <div className='cell large-7'>
            <label className='form-field'>{FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.title}</label>
            <div className='grid-x'>
              <div className='grid-x small-up-2'>

                {Object.keys(prenatalAlcoholExposureOptions).map(optionKey =>
                  <RadioButton
                    checked={formData.exposure === optionKey}
                    className='cell'
                    key={optionKey}
                    label={prenatalAlcoholExposureOptions[optionKey]}
                    onChange={event => handleFormChange({...formData, exposure: event.target.value})}
                    value={optionKey}/>)}
              </div>
            </div>
          </div>
          <div className='cell large-7'>
            {
              formData.exposure === FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.condition && <>
                <SpecifyTextBox
                  fieldId={FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.id}
                  formId={FORMS.PRENATAL_ALCOHOL_EXPOSURE.id}
                  handleFormChange={handleFormChange}
                  title={FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.title}/>
              </>
            }
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

PrenatalAlcoholForm.propTypes = {
  formIndex: PropTypes.number
};

export default PrenatalAlcoholForm;
