import React from 'react';

const Blurb8_13E = () =>
  <>
    <p>
      Individuals with FASD, like you/your child, often require ongoing support with mental health and regulation as they transition and navigate life experiences. Planning helps us to provide proactive supports and think about goals we hope to achieve, instead of only focusing on problems to be managed. Continued monitoring of your/your child’s mental health and well-being will be helpful to identify areas of potential strengths and needs, and provide appropriate supports and accommodations, such as therapy or opportunities for skill-building.
    </p>
  </>;

export default Blurb8_13E;
