import React from 'react';
import ROUTES from '../../../routes';
import {Link} from 'react-router-dom';
import CompassLogo from '../../../assets/Images/Compass-logo.jpg';
import MetaHelmet from '../../components/MetaHelmet';
import instructions from '../../../locales/instructions';

const Home = () => <>
  <MetaHelmet
    title='CanFASD: COMPASS Report Generator'/>
  <div className='text-left'>
    <div className='margin-vertical-3'>
      <img
        alt='Compass Logo'
        className='align-center'
        src={CompassLogo}/>
    </div>
    <p className='lead home-page-heading'>COMPASS Report Generator</p>
    <p className='text'>
      {instructions.welcome1}
    </p>
    <p className='text margin-bottom-2'>
      {instructions.welcome2}
    </p>
    <div className='grid-x align-left'>
      <Link
        className='cell medium-2 large button'
        to={ROUTES.DISCLAIMER}>
          Start
      </Link>
    </div>
  </div></>;

export default Home;
