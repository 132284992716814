import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import {useSelector} from 'react-redux';
import FORMS, {IMAGES} from '../../../../redux/constants';
import Blurb9_1A from '../../../blurbs/9_Employment/Blurb9_1A';
import Blurb9_1B from '../../../blurbs/9_Employment/Blurb9_1B';
import Blurb9_1C from '../../../blurbs/9_Employment/Blurb9_1C';
import Blurb9_1D from '../../../blurbs/9_Employment/Blurb9_1D';
import Blurb9_1E from '../../../blurbs/9_Employment/Blurb9_1E';
import Blurb9_2A from '../../../blurbs/9_Employment/Blurb9_2A';
import Blurb9_2B from '../../../blurbs/9_Employment/Blurb9_2B';
import Blurb9_2C from '../../../blurbs/9_Employment/Blurb9_2C';
import Blurb9_2D from '../../../blurbs/9_Employment/Blurb9_2D';
import Blurb9_2E from '../../../blurbs/9_Employment/Blurb9_2E';
import Blurb9_4A from '../../../blurbs/9_Employment/Blurb9_4A';
import Blurb9_3 from '../../../blurbs/9_Employment/Blurb9_3';
import Blurb9_4B from '../../../blurbs/9_Employment/Blurb9_4B';
import Blurb9_4C from '../../../blurbs/9_Employment/Blurb9_4C';
import Blurb9_4D from '../../../blurbs/9_Employment/Blurb9_4D';
import Blurb9_4E from '../../../blurbs/9_Employment/Blurb9_4E';
import Variables from '../components/Variables';
import TwoColumnLayout from './TwoColumnLayout';

const Employment = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {employment} = useSelector(state => state.forms[FORMS.EXPERIENCE.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const variables = {diagnosis, employment, age};

  const EmploymentBlurbs9_1A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EmploymentBlurbs9_1B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EmploymentBlurbs9_1C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EmploymentBlurbs9_1D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EmploymentBlurbs9_1E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EmploymentBlurbs9_2A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EmploymentBlurbs9_2B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EmploymentBlurbs9_2C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EmploymentBlurbs9_2D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EmploymentBlurbs9_2E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EmploymentBlurbs9_3 = employment === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const EmploymentBlurbs9_4A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EmploymentBlurbs9_4B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EmploymentBlurbs9_4C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EmploymentBlurbs9_4D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const EmploymentBlurbs9_4E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  return <section>
    <SectionTitle>Employment</SectionTitle>
    <Variables variableObject={variables}/>
    <Card>
      <h3 className='chapter-title'>
        Things to <span className='card-title'>Know</span>
      </h3>
      <>
        {EmploymentBlurbs9_1A && <Blurb9_1A/>}
        {EmploymentBlurbs9_1B && <Blurb9_1B/>}
        {EmploymentBlurbs9_1C && <Blurb9_1C/>}
        {EmploymentBlurbs9_1D && <Blurb9_1D/>}
        {EmploymentBlurbs9_1E && <Blurb9_1E/>}
      </>
    </Card>
    <TwoColumnLayout>

      <Card>
        <h3 className='chapter-title'>
          Things to <span className='card-title'>Do Now</span>
        </h3>
        <>
          {EmploymentBlurbs9_2A && <Blurb9_2A/>}
          {EmploymentBlurbs9_2B && <Blurb9_2B/>}
          {EmploymentBlurbs9_2C && <Blurb9_2C/>}
          {EmploymentBlurbs9_2D && <Blurb9_2D/>}
          {EmploymentBlurbs9_2E && <Blurb9_2E/>}
        </>
        <>
          {EmploymentBlurbs9_3 && <Blurb9_3/>}
        </>
      </Card>
      <Card>
        <h3 className='chapter-title'>
          Things to <span className='card-title'>Plan</span> for
        </h3>
        <>
          {EmploymentBlurbs9_4A && <Blurb9_4A/>}
          {EmploymentBlurbs9_4B && <Blurb9_4B/>}
          {EmploymentBlurbs9_4C && <Blurb9_4C/>}
          {EmploymentBlurbs9_4D && <Blurb9_4D/>}
          {EmploymentBlurbs9_4E && <Blurb9_4E/>}
        </>
      </Card>
    </TwoColumnLayout>
    <img src={IMAGES.IMAGE9}/>
  </section>;
};

export default Employment;
