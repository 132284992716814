import React from 'react';

const Blurb5_16A = () =>
  <>
    <p>
      Education is an important part of your child’s development that informs goal planning and achievement throughout the lifespan. As your child enters elementary school, it will be important for educators to consider their unique strengths, needs, and goals when developing their education plan. Your child may benefit from adaptations that allow for more “hands-on experiences,” time to process and think, and opportunities to share their knowledge with others. In this way your child can remain engaged at school and build skills to support their transition to elementary school and beyond.
    </p>
  </>;

export default Blurb5_16A;
