import React from 'react';

const Blurb8_5C = () =>
  <>
    <p>
      Your teen may be experiencing difficulties in their mental health, which can affect emotions, thoughts, behaviours, and relationships. Mental health needs can include physical symptoms, such as headaches or difficulty sleeping. Some challenges may be less visible whereas others may impact behaviour and interactions with others and may be more visible. It is important to seek FASD-informed mental health care for your teen to identify and support any challenges.
    </p>
  </>;

export default Blurb8_5C;
