import React from 'react';

const Blurb5_14E = () =>
  <>
    <p>
      You/ your child may benefit from FASD-informed occupational therapy. This often involves working with your teen, caregiver(s), and family, to identify and address day-to-day functioning and activities to support success in school, home, and community contexts.
    </p>
  </>;

export default Blurb5_14E;
