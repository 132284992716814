import React from 'react';

const Blurb2_7E = () =>
  <>
    <p>
      People with FASD often have difficulty sleeping well. This can impact mood, behaviour, and learning. Working with a physician and/or psychologist can help to identify additional strategies and resources to support healthy sleep. For example, a consistent nighttime routine can improve overall functioning and well-being.
    </p>
    <p>
      For children, <span className='text-bold'>Dr. Hanlon-Dearman and colleagues have adapted a Better Nights, Better Days program and resources for children with FASD, which can be found here:</span> <a href='https://ndd.betternightsbetterdays.ca/'>https://ndd.betternightsbetterdays.ca/</a>
    </p>
    <p>
      For adolescents and adults, cognitive behavioural therapy for insomnia (CBT-I) may be helpful.
    </p>
  </>;

export default Blurb2_7E;
