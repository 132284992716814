import React from 'react';

const Blurb8_3E = () =>
  <>
    <p>
      You/your child may be experiencing some challenges with self-regulation, and struggle to manage your/their behaviour, emotions, and attention. This can result in difficulty with behaviours, focus, and relationships. Helpful self-regulation strategies may include mindfulness techniques, or physical activity. You/they might also benefit from individualized programming like working with a mental health professional to build self-regulation strategies that work for you/them.
    </p>
  </>;

export default Blurb8_3E;
