import React from 'react';

const Blurb3_4D = () =>
  <>
    <p>
      You may have had past traumatic experiences, which can influence your attachment, how you perceive yourself and others, and your relationship with caregivers and other people in your life. Exploring mental health supports to promote healthy coping skills and support attachment relationships, family cohesion, and overall well-being may be helpful.
    </p>
  </>;

export default Blurb3_4D;
