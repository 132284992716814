import React from 'react';
import SectionTitle from '../components/SectionTitle';
import {useSelector} from 'react-redux';
import FORMS, {IMAGES} from '../../../../redux/constants';
import Blurb3_1A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_1A';
import Blurb3_1B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_1B';
import Blurb3_1C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_1C';
import Blurb3_1D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_1D';
import Blurb3_1E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_1E';
import Blurb3_2A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_2A';
import Blurb3_2B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_2B';
import Blurb3_2C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_2C';
import Blurb3_2D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_2D';
import Blurb3_2E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_2E';
import Blurb3_3A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_3A';
import Blurb3_3B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_3B';
import Blurb3_3C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_3C';
import Blurb3_3D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_3D';
import Blurb3_3E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_3E';
import Blurb3_4A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_4A';
import Blurb3_4B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_4B';
import Blurb3_4C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_4C';
import Blurb3_4D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_4D';
import Blurb3_4E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_4E';
import Blurb3_5A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_5A';
import Blurb3_5B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_5B';
import Blurb3_5C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_5C';
import Blurb3_5D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_5D';
import Blurb3_5E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_5E';
import Blurb3_6A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_6A';
import Blurb3_6B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_6B';
import Blurb3_6C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_6C';
import Blurb3_6D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_6D';
import Blurb3_6E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_6E';
import Blurb3_7 from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_7';
import Blurb3_8 from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_8';
import Blurb3_9 from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_9';
import Blurb3_10A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_10A';
import Blurb3_10B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_10B';
import Blurb3_10C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_10C';
import Blurb3_10D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_10D';
import Blurb3_10E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_10E';
import Variables from '../components/Variables';
import Card from '../components/Card';
import TwoColumnLayout from './TwoColumnLayout';

const AttachmentsAndRelationships = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {attachment} = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);
  const {living} = useSelector(state => state.forms[FORMS.CURRENT_LIVING_SITUATION.id]);
  const {factors___0, factors___1} = useSelector(state => state.forms[FORMS.OTHER_FACTORS.id]);
  const {associatedfeatures___3} = useSelector(state => state.forms[FORMS.OTHER_FEATURES.id]);
  const {respite, daycare, familycounselling} = useSelector(state => state.forms[FORMS.RECOMMENDATIONS.id]);

  const variables = {diagnosis, age, attachment, living, factors___0, factors___1,
    associatedfeatures___3, familycounselling, respite, daycare};

  const AttachmentsAndRelationshipsBlurbs3_1A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_1B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_1C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_1D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_1E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const AttachmentsAndRelationshipsBlurbs3_2A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_2B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_2C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_2D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_2E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const AttachmentsAndRelationshipsBlurbs3_3A = (attachment === '1' || factors___1 === '1') && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_3B = (attachment === '1' || factors___1 === '1') && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_3C = (attachment === '1' || factors___1 === '1') && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_3D = (attachment === '1' || factors___1 === '1') && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_3E = (attachment === '1' || factors___1 === '1') && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const AttachmentsAndRelationshipsBlurbs3_4A = (associatedfeatures___3 === '1' || factors___0 === '1') &&
    age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_4B = (associatedfeatures___3 === '1' || factors___0 === '1') &&
    age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_4C = (associatedfeatures___3 === '1' || factors___0 === '1') &&
    age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_4D = (associatedfeatures___3 === '1' || factors___0 === '1') &&
    age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_4E = (associatedfeatures___3 === '1' || factors___0 === '1') &&
    age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const AttachmentsAndRelationshipsBlurbs3_5A = respite === '1' && age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_5B = respite === '1' && age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_5C = respite === '1' && age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_5D = respite === '1' && age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_5E = respite === '1' && age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const AttachmentsAndRelationshipsBlurbs3_6A = daycare === '1' && age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_6B = daycare === '1' && age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_6C = daycare === '1' && age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_6D = daycare === '1' && age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_6E = daycare === '1' && age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const AttachmentsAndRelationshipsBlurbs3_7 = familycounselling === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_8 = living === '0' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_9 = living === '8' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const AttachmentsAndRelationshipsBlurbs3_10A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_10B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_10C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_10D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const AttachmentsAndRelationshipsBlurbs3_10E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  return <section>
    <SectionTitle>Attachments and Relationships</SectionTitle>
    <Variables variableObject={variables}/>
    <>
      {
        (AttachmentsAndRelationshipsBlurbs3_1A || AttachmentsAndRelationshipsBlurbs3_1B ||
          AttachmentsAndRelationshipsBlurbs3_1C || AttachmentsAndRelationshipsBlurbs3_1D ||
          AttachmentsAndRelationshipsBlurbs3_1E) &&
        <Card>
          <h3 className='chapter-title'>
            Things to <span className='card-title'>Know</span>
          </h3>
          <>
            {AttachmentsAndRelationshipsBlurbs3_1A && <Blurb3_1A/>}
            {AttachmentsAndRelationshipsBlurbs3_1B && <Blurb3_1B/>}
            {AttachmentsAndRelationshipsBlurbs3_1C && <Blurb3_1C/>}
            {AttachmentsAndRelationshipsBlurbs3_1D && <Blurb3_1D/>}
            {AttachmentsAndRelationshipsBlurbs3_1E && <Blurb3_1E/>}
          </>
        </Card>
      }
      {
        (AttachmentsAndRelationshipsBlurbs3_2A || AttachmentsAndRelationshipsBlurbs3_2B || AttachmentsAndRelationshipsBlurbs3_2C || AttachmentsAndRelationshipsBlurbs3_2D || AttachmentsAndRelationshipsBlurbs3_2E ||
          AttachmentsAndRelationshipsBlurbs3_3A || AttachmentsAndRelationshipsBlurbs3_3B || AttachmentsAndRelationshipsBlurbs3_3C || AttachmentsAndRelationshipsBlurbs3_3D || AttachmentsAndRelationshipsBlurbs3_3E ||
          AttachmentsAndRelationshipsBlurbs3_4A || AttachmentsAndRelationshipsBlurbs3_4B || AttachmentsAndRelationshipsBlurbs3_4C || AttachmentsAndRelationshipsBlurbs3_4D || AttachmentsAndRelationshipsBlurbs3_4E ||
          AttachmentsAndRelationshipsBlurbs3_7) &&
        <Card>
          <h3 className='chapter-title'>
            Things to <span className='card-title'>Do Now</span>
          </h3>
          <TwoColumnLayout>
            <>
              {AttachmentsAndRelationshipsBlurbs3_2A && <Blurb3_2A/>}
              {AttachmentsAndRelationshipsBlurbs3_2B && <Blurb3_2B/>}
              {AttachmentsAndRelationshipsBlurbs3_2C && <Blurb3_2C/>}
              {AttachmentsAndRelationshipsBlurbs3_2D && <Blurb3_2D/>}
              {AttachmentsAndRelationshipsBlurbs3_2E && <Blurb3_2E/>}
            </>
            <>
              {AttachmentsAndRelationshipsBlurbs3_3A && <Blurb3_3A/>}
              {AttachmentsAndRelationshipsBlurbs3_3B && <Blurb3_3B/>}
              {AttachmentsAndRelationshipsBlurbs3_3C && <Blurb3_3C/>}
              {AttachmentsAndRelationshipsBlurbs3_3D && <Blurb3_3D/>}
              {AttachmentsAndRelationshipsBlurbs3_3E && <Blurb3_3E/>}
            </>
            <>
              {AttachmentsAndRelationshipsBlurbs3_4A && <Blurb3_4A/>}
              {AttachmentsAndRelationshipsBlurbs3_4B && <Blurb3_4B/>}
              {AttachmentsAndRelationshipsBlurbs3_4C && <Blurb3_4C/>}
              {AttachmentsAndRelationshipsBlurbs3_4D && <Blurb3_4D/>}
              {AttachmentsAndRelationshipsBlurbs3_4E && <Blurb3_4E/>}
            </>
            <>
              {AttachmentsAndRelationshipsBlurbs3_5A && <Blurb3_5A/>}
              {AttachmentsAndRelationshipsBlurbs3_5B && <Blurb3_5B/>}
              {AttachmentsAndRelationshipsBlurbs3_5C && <Blurb3_5C/>}
              {AttachmentsAndRelationshipsBlurbs3_5D && <Blurb3_5D/>}
              {AttachmentsAndRelationshipsBlurbs3_5E && <Blurb3_5E/>}
            </>
            <>
              {AttachmentsAndRelationshipsBlurbs3_6A && <Blurb3_6A/>}
              {AttachmentsAndRelationshipsBlurbs3_6B && <Blurb3_6B/>}
              {AttachmentsAndRelationshipsBlurbs3_6C && <Blurb3_6C/>}
              {AttachmentsAndRelationshipsBlurbs3_6D && <Blurb3_6D/>}
              {AttachmentsAndRelationshipsBlurbs3_6E && <Blurb3_6E/>}
            </>
            <>
              {AttachmentsAndRelationshipsBlurbs3_7 && <Blurb3_7/>}
            </>
            <>
              {AttachmentsAndRelationshipsBlurbs3_8 && <Blurb3_8/>}
              {AttachmentsAndRelationshipsBlurbs3_9 && <Blurb3_9/>}
            </>
          </TwoColumnLayout>
        </Card>
      }
      {
        (AttachmentsAndRelationshipsBlurbs3_10A || AttachmentsAndRelationshipsBlurbs3_10B || AttachmentsAndRelationshipsBlurbs3_10C || AttachmentsAndRelationshipsBlurbs3_10D || AttachmentsAndRelationshipsBlurbs3_10E) &&
        <Card>
          <h3 className='chapter-title'>
            Things to <span className='card-title'>Plan</span> for
          </h3>
          <>
            {AttachmentsAndRelationshipsBlurbs3_10A && <Blurb3_10A/>}
            {AttachmentsAndRelationshipsBlurbs3_10B && <Blurb3_10B/>}
            {AttachmentsAndRelationshipsBlurbs3_10C && <Blurb3_10C/>}
            {AttachmentsAndRelationshipsBlurbs3_10D && <Blurb3_10D/>}
            {AttachmentsAndRelationshipsBlurbs3_10E && <Blurb3_10E/>}
          </>
        </Card>
      }
    </>
    <img src={IMAGES.IMAGE3}/>
  </section>;
};

export default AttachmentsAndRelationships;
