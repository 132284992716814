import React from 'react';

const Blurb2_12A = () =>
  <>
    <p>
      Children with FASD, like your child, require ongoing health support as they transition into elementary school. Planning ahead helps us be proactive and focus on setting up supports and achieving goals, rather than just responding to problems. Talk to your doctor about FASD. To help with proactive planning, Dr. Hanlon-Dearman and colleagues created an <span className='text-bold'>anticipatory</span> guide for primary health care providers working with children and adolescents who have FASD or PAE: <a href='https://jptcp.com/index.php/jptcp/article/view/275/228'>https://jptcp.com/index.php/jptcp/article/view/275/228</a>
    </p>
  </>;

export default Blurb2_12A;
