import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import {useSelector} from 'react-redux';
import Blurb12_1A from '../../../blurbs/12_CommunityTearoffs/Blurb12_1A';
import Blurb12_1B from '../../../blurbs/12_CommunityTearoffs/Blurb12_1B';
import Blurb12_1C from '../../../blurbs/12_CommunityTearoffs/Blurb12_1C';
import Blurb12_1D from '../../../blurbs/12_CommunityTearoffs/Blurb12_1D';
import Blurb12_1E from '../../../blurbs/12_CommunityTearoffs/Blurb12_1E';
import Variables from '../components/Variables';
import FORMS from '../../../../redux/constants';
import TwoColumnLayout from './TwoColumnLayout';
import PaddedPrintLayout from '../../../components/PaddedPrintLayout';

const CommunityTearoff = () => {
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const variables = {age};
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS.id]);

  const CommunityTearoffBlurb12_1A = age === '1';
  const CommunityTearoffBlurb12_1B = age === '2';
  const CommunityTearoffBlurb12_1C = age === '3';
  const CommunityTearoffBlurb12_1D = age === '4';
  const CommunityTearoffBlurb12_1E = age === '5';

  if (formData.community_tearoff ||
    CommunityTearoffBlurb12_1A ||
    CommunityTearoffBlurb12_1B ||
    CommunityTearoffBlurb12_1C ||
    CommunityTearoffBlurb12_1D ||
    CommunityTearoffBlurb12_1E) {
    return <PaddedPrintLayout>
      <section>
        <SectionTitle>Tailored Feedback - Community</SectionTitle>
        <Variables variableObject={variables}/>
        <Card>
          <h3 className='chapter-title'>
          My Community <span className='card-title'>Needs</span>
          </h3>
          <TwoColumnLayout>
            <>
              {CommunityTearoffBlurb12_1A && <Blurb12_1A/>}
              {CommunityTearoffBlurb12_1B && <Blurb12_1B/>}
              {CommunityTearoffBlurb12_1C && <Blurb12_1C/>}
              {CommunityTearoffBlurb12_1D && <Blurb12_1D/>}
              {CommunityTearoffBlurb12_1E && <Blurb12_1E/>}
            </>
            <>
              {
                formData.community_tearoff && <p>{formData.community_tearoff}</p>
              }
            </>
          </TwoColumnLayout>
        </Card>
      </section>
    </PaddedPrintLayout>;
  }

  return null;
};

export default CommunityTearoff;
