import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import {useSelector} from 'react-redux';
import FORMS, {IMAGES} from '../../../../redux/constants';
import Variables from '../components/Variables';
import Blurb11_1A from '../../../blurbs/11_ParentingSkills/Blurb11_1A';
import Blurb11_1B from '../../../blurbs/11_ParentingSkills/Blurb11_1B';
import Blurb11_1C from '../../../blurbs/11_ParentingSkills/Blurb11_1C';
import Blurb11_1D from '../../../blurbs/11_ParentingSkills/Blurb11_1D';
import Blurb11_1E from '../../../blurbs/11_ParentingSkills/Blurb11_1E';
import Blurb11_2A from '../../../blurbs/11_ParentingSkills/Blurb11_2A';
import Blurb11_2B from '../../../blurbs/11_ParentingSkills/Blurb11_2B';
import Blurb11_2C from '../../../blurbs/11_ParentingSkills/Blurb11_2C';
import Blurb11_2D from '../../../blurbs/11_ParentingSkills/Blurb11_2D';
import Blurb11_2E from '../../../blurbs/11_ParentingSkills/Blurb11_2E';
import Blurb11_3A from '../../../blurbs/11_ParentingSkills/Blurb11_3A';
import Blurb11_3B from '../../../blurbs/11_ParentingSkills/Blurb11_3B';
import Blurb11_3C from '../../../blurbs/11_ParentingSkills/Blurb11_3C';
import Blurb11_3D from '../../../blurbs/11_ParentingSkills/Blurb11_3D';
import Blurb11_3E from '../../../blurbs/11_ParentingSkills/Blurb11_3E';
import TwoColumnLayout from './TwoColumnLayout';

const ParentingSkills = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {employment} = useSelector(state => state.forms[FORMS.EXPERIENCE.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const variables = {diagnosis, employment, age};

  const ParentingSkillsBlurbs11_1A_Blurb11_2A_Blurb11_3A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const ParentingSkillsBlurbs11_1B_Blurb11_2B_Blurb11_3B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const ParentingSkillsBlurbs11_1C_Blurb11_2C_Blurb11_3C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const ParentingSkillsBlurbs11_1D_Blurb11_2D_Blurb11_3D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const ParentingSkillsBlurbs11_1E_Blurb11_2E_Blurb11_3E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  if (ParentingSkillsBlurbs11_1A_Blurb11_2A_Blurb11_3A ||
    ParentingSkillsBlurbs11_1B_Blurb11_2B_Blurb11_3B ||
    ParentingSkillsBlurbs11_1C_Blurb11_2C_Blurb11_3C ||
    ParentingSkillsBlurbs11_1D_Blurb11_2D_Blurb11_3D ||
    ParentingSkillsBlurbs11_1E_Blurb11_2E_Blurb11_3E) {
    return <section>
      <SectionTitle>Parenting Skills</SectionTitle>
      <Variables variableObject={variables}/>
      <TwoColumnLayout>
        <Card>
          <h3 className='chapter-title'>
            Things to <span className='card-title'>Know</span>
          </h3>
          <>
            {ParentingSkillsBlurbs11_1A_Blurb11_2A_Blurb11_3A && <Blurb11_1A/>}
            {ParentingSkillsBlurbs11_1B_Blurb11_2B_Blurb11_3B && <Blurb11_1B/>}
            {ParentingSkillsBlurbs11_1C_Blurb11_2C_Blurb11_3C && <Blurb11_1C/>}
            {ParentingSkillsBlurbs11_1D_Blurb11_2D_Blurb11_3D && <Blurb11_1D/>}
            {ParentingSkillsBlurbs11_1E_Blurb11_2E_Blurb11_3E && <Blurb11_1E/>}
          </>
        </Card>
        <Card>
          <h3 className='chapter-title'>
            Things to <span className='card-title'>Do Now</span>
          </h3>
          <>
            {ParentingSkillsBlurbs11_1A_Blurb11_2A_Blurb11_3A && <Blurb11_2A/>}
            {ParentingSkillsBlurbs11_1B_Blurb11_2B_Blurb11_3B && <Blurb11_2B/>}
            {ParentingSkillsBlurbs11_1C_Blurb11_2C_Blurb11_3C && <Blurb11_2C/>}
            {ParentingSkillsBlurbs11_1D_Blurb11_2D_Blurb11_3D && <Blurb11_2D/>}
            {ParentingSkillsBlurbs11_1E_Blurb11_2E_Blurb11_3E && <Blurb11_2E/>}
          </>
        </Card>
        <Card>
          <h3 className='chapter-title'>
            Things to <span className='card-title'>Plan</span> for
          </h3>
          <>
            {ParentingSkillsBlurbs11_1A_Blurb11_2A_Blurb11_3A && <Blurb11_3A/>}
            {ParentingSkillsBlurbs11_1B_Blurb11_2B_Blurb11_3B && <Blurb11_3B/>}
            {ParentingSkillsBlurbs11_1C_Blurb11_2C_Blurb11_3C && <Blurb11_3C/>}
            {ParentingSkillsBlurbs11_1D_Blurb11_2D_Blurb11_3D && <Blurb11_3D/>}
            {ParentingSkillsBlurbs11_1E_Blurb11_2E_Blurb11_3E && <Blurb11_3E/>}
          </>
        </Card>
      </TwoColumnLayout>
      <img src={IMAGES.IMAGE11}/>
    </section>;
  }

  return null;
};

export default ParentingSkills;
