import React from 'react';

const Blurb3_1A = () =>
  <>
    <p>
      Attachment describes the emotional bonds between your child and the people in their life. When we experience safe and stable connections to others, we experience security and gain confidence that we can safely explore the world around us. Early bonding experiences affect our later relationships and our ability to trust others, shape our beliefs about our own value and self-esteem, and help us manage our emotional responses and regulate our emotions. Knowing this, we can work toward promoting healthy and long-lasting attachments.
    </p>
    <p>
      For your child with FASD, this may mean supporting family cohesion and identifying individuals in their life that may fill an attachment role. This also involves understanding your child’s early attachment experiences and any possible long-term impacts, while also recognizing that later attachment experiences can also be impactful.
    </p>
  </>;

export default Blurb3_1A;
