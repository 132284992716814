import React from 'react';
import CheckBox from '../../../components/CheckBox';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import SpecifyTextBox from '../../../components/SpecifyTextBox';

const OtherFactorsForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.OTHER_FACTORS.id]);
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    if (updatedFormData.factors___3 === '0') {
      updatedFormData[FORMS.OTHER_FACTORS.fields.factors___3.specify_other.id] = FORMS.OTHER_FACTORS.fields.factors___3.specify_other.value;
    }

    dispatch(submitForm(FORMS.OTHER_FACTORS.id, updatedFormData));
  };

  const otherFactors = FORMS.OTHER_FACTORS.fields;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] === '1');
  const isFormComplete = checkedFields.length > 0 &&
    (formData.factors___3 === '1' ? formData.factors_specify.length > 0 : true);

  return (

    <>
      <form>
        <div className='grid-x grid-margin-y'>
          <div className='cell large-7'>
            <label className='form-field'>Other factors <span
              className='font-italic'>(Please check all that apply)</span></label>
            <div className='grid-x small-up-1 medium-up-2'>
              {Object.entries(otherFactors).map(([factorKey, factorValues]) =>
                <CheckBox
                  checked={formData[factorKey] === '1'}
                  className='cell'
                  key={factorKey}
                  onChange={e => handleFormChange({
                    ...formData,
                    [factorKey]: e.target.checked ? '1' : '0'
                  })}
                  value={factorValues.title}/>)}
            </div>
          </div>
          <div className='cell large-7'>
            {
              formData.factors___3 === '1' && <>
                <SpecifyTextBox
                  fieldId={FORMS.OTHER_FACTORS.fields.factors___3.specify_other.id}
                  formId={FORMS.OTHER_FACTORS.id}
                  handleFormChange={handleFormChange}
                  title={FORMS.OTHER_FACTORS.fields.factors___3.specify_other.title}/>
              </>
            }
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

OtherFactorsForm.propTypes = {
  formIndex: PropTypes.number
};

export default OtherFactorsForm;
