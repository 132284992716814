import React from 'react';

const Blurb7_23E = () =>
  <>
    <p>
      People with FASD may need extra support to increase their safety. A sense of safety is essential to physical and mental well-being. To help people with FASD feel safe you can provide safety measures in their daily activities. Creating trustworthy and consistent relationships within a safe, stable, caring, and dependable environment will enhance their sense of safety, connectedness, and autonomy.
    </p>
  </>;

export default Blurb7_23E;
