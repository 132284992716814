import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import SpecifyTextBox from '../../../components/SpecifyTextBox';

const LivingSituationForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.CURRENT_LIVING_SITUATION.id]);
  const isFormComplete = formData.living !== '' &&
    (formData.living === FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.condition ? formData.living_specify.length > 0 : true) &&
    (formData.living === FORMS.CURRENT_LIVING_SITUATION.fields.living_familyspecify.condition
      ? formData.living_familyspecify.length > 0 : true);
  const dispatch = useDispatch();
  const livingSituationOptions = FORMS.CURRENT_LIVING_SITUATION.fields.living.options;

  const handleFormChange = updatedFormData => {
    if (formData.living !== updatedFormData.living) {
      updatedFormData.living_familyspecify = FORMS.CURRENT_LIVING_SITUATION.fields.living_familyspecify.value;
    }

    if (formData.living === FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.condition &&
      updatedFormData.living !== FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.condition) {
      updatedFormData.living_specify = FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.value;
    }

    dispatch(submitForm(FORMS.CURRENT_LIVING_SITUATION.id, updatedFormData));
  };

  return (
    <>
      <form>
        <div className='grid-x grid-margin-y'>
          <div className='cell large-7'>
            <label className='form-field'>{FORMS.CURRENT_LIVING_SITUATION.fields.living.title}</label>
            <div className='grid-x medium-up-2 large-up-3'>
              {Object.keys(livingSituationOptions).map(optionKey =>
                <RadioButton
                  checked={formData.living === optionKey}
                  className='cell'
                  key={optionKey}
                  label={livingSituationOptions[optionKey]}
                  onChange={event => handleFormChange({...formData, living: event.target.value})}
                  value={optionKey}/>)}
            </div>
          </div>
          <div className='cell large-7'>
            {
              formData.living === FORMS.CURRENT_LIVING_SITUATION.fields.living_familyspecify.condition && <>
                <SpecifyTextBox
                  fieldId={FORMS.CURRENT_LIVING_SITUATION.fields.living_familyspecify.id}
                  formId={FORMS.CURRENT_LIVING_SITUATION.id}
                  handleFormChange={handleFormChange}
                  title={FORMS.CURRENT_LIVING_SITUATION.fields.living_familyspecify.title}/>
              </>
            }
            {
              formData.living === FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.condition && <>
                <SpecifyTextBox
                  fieldId={FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.id}
                  formId={FORMS.CURRENT_LIVING_SITUATION.id}
                  handleFormChange={handleFormChange}
                  title={FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.title}/>
              </>
            }
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

LivingSituationForm.propTypes = {
  formIndex: PropTypes.number
};

export default LivingSituationForm;
