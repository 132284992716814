import React from 'react';

const Blurb9_2D = () =>
  <>
    <p>
      People with FASD have experienced success with supported employment. The Canada FASD Research Network offers a list of <span className='text-bold'>employment programs and services available by geographical region</span> to support individuals with FASD as they seek to prepare for, obtain, and maintain employment: <a href='https://canfasd.ca/2022/01/21/employment-services-for-people-with-fasd/'>
      https://canfasd.ca/2022/01/21/employment-services-for-people-with-fasd/</a>
    </p>
    <p>
      Determining whether to disclose a diagnosis such as FASD with employers is an important decision that each person with FASD needs to make for themselves, and you can help by providing supports and guidance regarding such a decision. Sharing an FASD diagnosis with employers can lead to better support and accommodations in the workplace, increased empathy, and reduced stigma.
    </p>
    <p>
      FASD-informed resources can help workers to better support people with FASD seek employment. The Canada FASD Research Network offers an <span className='text-bold'>Enhanced Employment Guide for Employment Professionals Working with Individuals with FASD:</span> <a href='https://canfasd.ca/wp-content/uploads/2018/09/Guide-and-Final-Report-Supporting-Employment-Success-in-FASD.pdf'>
      https://canfasd.ca/wp-content/uploads/2018/09/Guide-and-Final-Report-Supporting-Employment-Success-in-FASD.pdf</a>
    </p>
  </>;

export default Blurb9_2D;
