import React from 'react';

const Blurb6_4C = () =>
  <>
    <p>
      Supporting your teen to navigate identity development can help them to recognize and build upon strengths and skills that can be leveraged to address needs. Encouraging your teen’s identity development can also support autonomy and well-being, promote growth, and help your teen during periods of change or transitions throughout the lifespan.
    </p>
  </>;

export default Blurb6_4C;
