import React from 'react';

const Blurb6_4D = () =>
  <>
    <p>
      Continuing to build, understand, and acknowledge your own identity is important in adulthood. Having a strong sense of your own identity, as it makes sense for you, can create opportunities to support your strengths as you move through adulthood.
    </p>
  </>;

export default Blurb6_4D;
