import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import CheckBox from '../../../components/CheckBox';
import instructions from '../../../../locales/instructions';

const TearoffsSelectionForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS_SELECTORS.id]);
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.TEAROFFS_SELECTORS.id, updatedFormData));
  };

  const tearoffs = FORMS.TEAROFFS_SELECTORS.fields;
  return (
    <>
      <form>
        <div className='grid-x grid-margin-x'>
          <div className='cell medium-10 large-7'>
            <label className='form-field'>Tailored Feedback Sheet</label>
            <p className='form-instructions'>
              {instructions.tear_offs}
            </p>
          </div>
          <div className='cell medium-10 large-7'>
            {Object.entries(tearoffs).map(([tearoffKey, tearoffValues]) => <CheckBox
              checked={formData[tearoffKey] === '1'}
              key={tearoffKey}
              onChange={e => handleFormChange({
                ...formData,
                [tearoffKey]: e.target.checked ? '1' : '0'
              })}
              value={tearoffValues.title}/>)}
          </div>
        </div>
      </form>
      <FormNav
        canSubmit
        formIndex={formIndex}/>
    </>
  );
};

TearoffsSelectionForm.propTypes = {
  formIndex: PropTypes.number
};

export default TearoffsSelectionForm;
