import React from 'react';
import PropTypes from 'prop-types';

const Variables = ({variableObject}) =>
  <div
    className='grid-x small-up-4 bordered shadow padding-2 margin-bottom-1 hide-for-print variables is-hidden'
    data-variables='true'>
    {variableObject && Object.keys(variableObject).map(item => <div
      className='cell'
      key={item}>
      <span className='font-bold'>{item}: </span>
      <span>{`"${variableObject[item]}"`}</span>
    </div>)}
  </div>;

export default Variables;

Variables.propTypes = {
  variableObject: PropTypes.object.isRequired
};
