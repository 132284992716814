import React from 'react';
import PropTypes from 'prop-types';

const Card = ({children, title}) =>
  <div className='margin-bottom-2'>
    {title && <h3 className='chapter-title'>
      <span className='card-title'>
        {title}
      </span>
    </h3>
    }
    {children}
  </div>;

Card.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string
};

export default Card;
