import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const GenderIdentityForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.GENDER_IDENTITY.id]);
  const isFormComplete = formData.gender !== '' &&
    (formData.gender === FORMS.GENDER_IDENTITY.fields.gender.specify_other.condition
      ? formData.gender_specify.length > 0 : true);
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    if (formData.gender !== updatedFormData.gender) {
      updatedFormData[FORMS.GENDER_IDENTITY.fields.gender.specify_other.id] = FORMS.GENDER_IDENTITY.fields.gender.specify_other.value;
    }

    dispatch(submitForm(FORMS.GENDER_IDENTITY.id, updatedFormData));
  };

  const genderOptions = FORMS.GENDER_IDENTITY.fields.gender.options;

  return (
    <>
      <form>
        <div className='grid-x grid-margin-y large-up-2'>
          <div className='cell'>
            <label className='form-field'>{FORMS.GENDER_IDENTITY.fields.gender.title}</label>
            <div className='grid-x small-up-2 medium-up-4'>
              {Object.keys(genderOptions).map(optionKey =>
                <RadioButton
                  checked={formData.gender === optionKey}
                  className='cell'
                  key={optionKey}
                  label={genderOptions[optionKey]}
                  onChange={event => handleFormChange({...formData, gender: event.target.value})}
                  value={optionKey}/>)}
            </div>
          </div>
          <div className='cell'>
            {
              formData.gender === FORMS.GENDER_IDENTITY.fields.gender.specify_other.condition && <div className='cell'>
                <label
                  className='form-field'
                  htmlFor={FORMS.GENDER_IDENTITY.fields.gender.specify_other.id}>{FORMS.GENDER_IDENTITY.fields.gender.specify_other.title}</label>
                <input
                  id={FORMS.GENDER_IDENTITY.fields.gender.specify_other.id}
                  onChange={event => handleFormChange({
                    ...formData,
                    [FORMS.GENDER_IDENTITY.fields.gender.specify_other.id]: event.target.value
                  })}
                  type='text'
                  value={formData[FORMS.GENDER_IDENTITY.fields.gender.specify_other.id]}/>
              </div>
            }
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

GenderIdentityForm.propTypes = {
  formIndex: PropTypes.number
};

export default GenderIdentityForm;
