import React from 'react';

const Blurb17D = () =>
  <>
    <p>
        People with FASD differ in the areas where they excel and where supports would be helpful.
        Some have stronger skills in intellectual functioning, language abilities, and academics. However,
        having these skills, and the ability to consistently use them are two different things.
    </p>
    <p>
        You may experience difficulties in applying your skills. This could look like struggling to
        pay attention, slowing down to think closely about something, and thinking flexibly. Problem-solving
        and planning may also be more of a challenge. You have many strengths, but it may be more difficult
        for you to put these into action, for brain-based reasons. These difficulties may make it hard for
        you to control emotions, especially when it becomes more challenging to meet daily expectations.
        Some people may misinterpret your “application” difficulties as unwillingness to follow instructions.
        For example, others may see this as oppositional behaviour, anger, or avoidance. By understanding that
        your brain-based differences impact your ability to use your skills in everyday ways, others can change
        their approach to support you so that you can succeed in your own way. All individuals want to connect
        meaningfully with others and find purpose, so it is important to promote opportunities for success
        and acceptance.
    </p>
  </>;

export default Blurb17D;
