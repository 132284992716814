import React from 'react';

const Blurb2_4 = () =>
  <>
    <p>
      Sharing information from this report can build understanding of how individuals with FASD may have unique physical health needs and help with planning and monitoring these needs proactively.
    </p>
  </>;

export default Blurb2_4;
