import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import Variables from '../components/Variables';
import {useSelector} from 'react-redux';
import FORMS, {IMAGES} from '../../../../redux/constants';
import Blurb7_1A from '../../../blurbs/7_Interdependence/Blurb7_1A';
import Blurb7_1B from '../../../blurbs/7_Interdependence/Blurb7_1B';
import Blurb7_1C from '../../../blurbs/7_Interdependence/Blurb7_1C';
import Blurb7_1D from '../../../blurbs/7_Interdependence/Blurb7_1D';
import Blurb7_1E from '../../../blurbs/7_Interdependence/Blurb7_1E';
import Blurb7_2A from '../../../blurbs/7_Interdependence/Blurb7_2A';
import Blurb7_2B from '../../../blurbs/7_Interdependence/Blurb7_2B';
import Blurb7_2C from '../../../blurbs/7_Interdependence/Blurb7_2C';
import Blurb7_2D from '../../../blurbs/7_Interdependence/Blurb7_2D';
import Blurb7_2E from '../../../blurbs/7_Interdependence/Blurb7_2E';
import Blurb7_21A from '../../../blurbs/7_Interdependence/Blurb7_21A';
import Blurb7_21B from '../../../blurbs/7_Interdependence/Blurb7_21B';
import Blurb7_21C from '../../../blurbs/7_Interdependence/Blurb7_21C';
import Blurb7_21D from '../../../blurbs/7_Interdependence/Blurb7_21D';
import Blurb7_21E from '../../../blurbs/7_Interdependence/Blurb7_21E';
import Blurb7_22A from '../../../blurbs/7_Interdependence/Blurb7_22A';
import Blurb7_22B from '../../../blurbs/7_Interdependence/Blurb7_22B';
import Blurb7_22C from '../../../blurbs/7_Interdependence/Blurb7_22C';
import Blurb7_22D from '../../../blurbs/7_Interdependence/Blurb7_22D';
import Blurb7_22E from '../../../blurbs/7_Interdependence/Blurb7_22E';
import Blurb7_23A from '../../../blurbs/7_Interdependence/Blurb7_23A';
import Blurb7_23C from '../../../blurbs/7_Interdependence/Blurb7_23C';
import Blurb7_23B from '../../../blurbs/7_Interdependence/Blurb7_23B';
import Blurb7_23D from '../../../blurbs/7_Interdependence/Blurb7_23D';
import Blurb7_23E from '../../../blurbs/7_Interdependence/Blurb7_23E';
import Blurb7_24A from '../../../blurbs/7_Interdependence/Blurb7_24A';
import Blurb7_24B from '../../../blurbs/7_Interdependence/Blurb7_24B';
import Blurb7_24C from '../../../blurbs/7_Interdependence/Blurb7_24C';
import Blurb7_24D from '../../../blurbs/7_Interdependence/Blurb7_24D';
import Blurb7_24E from '../../../blurbs/7_Interdependence/Blurb7_24E';
import Blurb7_25 from '../../../blurbs/7_Interdependence/Blurb7_25';
import Blurb7_251 from '../../../blurbs/7_Interdependence/Blurb7_251';
import Blurb7_252 from '../../../blurbs/7_Interdependence/Blurb7_252';
import Blurb7_254 from '../../../blurbs/7_Interdependence/Blurb7_254';
import Blurb7_253 from '../../../blurbs/7_Interdependence/Blurb7_253';
import Blurb7_3A from '../../../blurbs/7_Interdependence/Blurb7_3A';
import Blurb7_3B from '../../../blurbs/7_Interdependence/Blurb7_3B';
import Blurb7_3C from '../../../blurbs/7_Interdependence/Blurb7_3C';
import Blurb7_3D from '../../../blurbs/7_Interdependence/Blurb7_3D';
import Blurb7_3E from '../../../blurbs/7_Interdependence/Blurb7_3E';
import TwoColumnLayout from './TwoColumnLayout';

const Independence = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {adaptive_brain} = useSelector(state => state.forms[FORMS.BRAIN_DOMAIN_ASSESSMENT.id]);
  const {coaching, safety, foodbank, guardianship, personaldirective, guidance, othersubstitute, poa} = useSelector(state => state.forms[FORMS.RECOMMENDATIONS.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const variables = {diagnosis, age, adaptive_brain, coaching, safety, foodbank, guardianship, personaldirective, guidance, othersubstitute, poa};

  const IndependenceBlurb7_1A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_1B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_1C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_1D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_1E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const IndependenceBlurb7_2A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_2B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_2C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_2D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_2E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const IndependenceBlurb7_21A = adaptive_brain === '1' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_21B = adaptive_brain === '1' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_21C = adaptive_brain === '1' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_21D = adaptive_brain === '1' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_21E = adaptive_brain === '1' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const IndependenceBlurb7_22A = coaching === '1' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_22B = coaching === '1' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_22C = coaching === '1' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_22D = coaching === '1' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_22E = coaching === '1' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const IndependenceBlurb7_23A = safety === '1' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_23B = safety === '1' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_23C = safety === '1' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_23D = safety === '1' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_23E = safety === '1' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const IndependenceBlurb7_24A = foodbank === '1' && age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_24B = foodbank === '1' && age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_24C = foodbank === '1' && age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_24D = foodbank === '1' && age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_24E = foodbank === '1' && age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const IndependenceBlurb7_25 = guardianship === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_251 = personaldirective === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_252 = guidance === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_253 = othersubstitute === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_254 = poa === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const IndependenceBlurb7_3A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_3B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_3C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_3D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const IndependenceBlurb7_3E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  return <section>
    <SectionTitle>Interdependence</SectionTitle>
    <Variables variableObject={variables}/>
    {
      (IndependenceBlurb7_1A || IndependenceBlurb7_1B || IndependenceBlurb7_1C || IndependenceBlurb7_1D || IndependenceBlurb7_1E) &&
      <Card>
        <h3 className='chapter-title'>
          Things to <span className='card-title'>Know</span>
        </h3>
        <>
          {IndependenceBlurb7_1A && <Blurb7_1A/>}
          {IndependenceBlurb7_1B && <Blurb7_1B/>}
          {IndependenceBlurb7_1C && <Blurb7_1C/>}
          {IndependenceBlurb7_1D && <Blurb7_1D/>}
          {IndependenceBlurb7_1E && <Blurb7_1E/>}
        </>
      </Card>
    }
    {
      /*
       * IndependenceBlurbs7_21A is not included in the following if statement because it is empty,
       * even if it meets the condition, It will not add to the text in this section.
       */
      (IndependenceBlurb7_2A || IndependenceBlurb7_2B || IndependenceBlurb7_2C || IndependenceBlurb7_2D || IndependenceBlurb7_2E ||
        IndependenceBlurb7_22A || IndependenceBlurb7_22B || IndependenceBlurb7_22C || IndependenceBlurb7_22D || IndependenceBlurb7_22E ||
        IndependenceBlurb7_23A || IndependenceBlurb7_23B || IndependenceBlurb7_23C || IndependenceBlurb7_23D || IndependenceBlurb7_23E ||
        IndependenceBlurb7_24A || IndependenceBlurb7_24B || IndependenceBlurb7_24C || IndependenceBlurb7_24D || IndependenceBlurb7_24E ||
        IndependenceBlurb7_25 || IndependenceBlurb7_251 || IndependenceBlurb7_252 || IndependenceBlurb7_253 || IndependenceBlurb7_254) &&
      <Card>
        <h3 className='chapter-title'>
          Things to <span className='card-title'>Do Now</span>
        </h3>
        <TwoColumnLayout>
          <>
            {IndependenceBlurb7_2A && <Blurb7_2A/>}
            {IndependenceBlurb7_2B && <Blurb7_2B/>}
            {IndependenceBlurb7_2C && <Blurb7_2C/>}
            {IndependenceBlurb7_2D && <Blurb7_2D/>}
            {IndependenceBlurb7_2E && <Blurb7_2E/>}
          </>
          <>
            {IndependenceBlurb7_21A && <Blurb7_21A/>}
            {IndependenceBlurb7_21B && <Blurb7_21B/>}
            {IndependenceBlurb7_21C && <Blurb7_21C/>}
            {IndependenceBlurb7_21D && <Blurb7_21D/>}
            {IndependenceBlurb7_21E && <Blurb7_21E/>}
          </>
          <>
            {IndependenceBlurb7_22A && <Blurb7_22A/>}
            {IndependenceBlurb7_22B && <Blurb7_22B/>}
            {IndependenceBlurb7_22C && <Blurb7_22C/>}
            {IndependenceBlurb7_22D && <Blurb7_22D/>}
            {IndependenceBlurb7_22E && <Blurb7_22E/>}
          </>
          <>
            {IndependenceBlurb7_23A && <Blurb7_23A/>}
            {IndependenceBlurb7_23B && <Blurb7_23B/>}
            {IndependenceBlurb7_23C && <Blurb7_23C/>}
            {IndependenceBlurb7_23D && <Blurb7_23D/>}
            {IndependenceBlurb7_23E && <Blurb7_23E/>}
          </>
          <>
            {IndependenceBlurb7_24A && <Blurb7_24A/>}
            {IndependenceBlurb7_24B && <Blurb7_24B/>}
            {IndependenceBlurb7_24C && <Blurb7_24C/>}
            {IndependenceBlurb7_24D && <Blurb7_24D/>}
            {IndependenceBlurb7_24E && <Blurb7_24E/>}
          </>
          <>
            {IndependenceBlurb7_25 && <Blurb7_25/>}
            {IndependenceBlurb7_251 && <Blurb7_251/>}
            {IndependenceBlurb7_252 && <Blurb7_252/>}
            {IndependenceBlurb7_253 && <Blurb7_253/>}
            {IndependenceBlurb7_254 && <Blurb7_254/>}
          </>
        </TwoColumnLayout>
      </Card>
    }
    {
      (IndependenceBlurb7_3A || IndependenceBlurb7_3B || IndependenceBlurb7_3C || IndependenceBlurb7_3D || IndependenceBlurb7_3E) &&
      <Card>
        <h3 className='chapter-title'>
          Things to <span className='card-title'>Plan</span> for
        </h3>
        <>
          {IndependenceBlurb7_3A && <Blurb7_3A/>}
          {IndependenceBlurb7_3B && <Blurb7_3B/>}
          {IndependenceBlurb7_3C && <Blurb7_3C/>}
          {IndependenceBlurb7_3D && <Blurb7_3D/>}
          {IndependenceBlurb7_3E && <Blurb7_3E/>}
        </>
      </Card>
    }
    <img
      className='padding-bottom-1'
      src={IMAGES.IMAGE7}/>
  </section>;
};

export default Independence;
