import React from 'react';

const Blurb10_4A = () =>
  <>
    <p>
      When it comes time to consider your child’s housing, it will be important to work together with your child, their family/caregiver(s), and service providers. Collaboration can help to prioritize their unique housing needs, preferences, and goals, while also considering unique strengths, life experiences, and social supports.
    </p>
  </>;

export default Blurb10_4A;
