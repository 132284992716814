import React from 'react';

const Blurb6_1A = () =>
  <>
    <p>
      A person’s identity develops over the lifespan with early foundations laid in childhood. Identity is shaped by many factors, including education/career goals, disabilities, spiritual experiences, and self-expression. This can result in a broad spectrum of unique qualities, values, goals, strengths, and needs that are woven together.
    </p>
    <p>
      Early identity development plays a role in many aspects of our life, including social and adaptive functioning. When talking to an individual with FASD, telling a story that reflects both challenges and strengths demonstrates that they are valued for the whole person they are. Remaining aware of stigmatizing or demeaning views about FASD, you may help to prevent you/ your child from developing negative self-perceptions and help them to appreciate their inherent value and potential for meaningful contribution.
    </p>
  </>;

export default Blurb6_1A;
