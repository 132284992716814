import React from 'react';

const Blurb2_1E = () =>
  <>
    <p>
      Physical health is fundamental to daily living and a first step towards healthy outcomes
      across the lifespan. Ongoing monitoring of your child’s/your physical health, including
      hearing, vision, mobility, and diet, is important to support overall well-being. Individuals
      with FASD may experience more physical health needs at earlier ages than might typically be
      expected – this means that ongoing vigilance even for unexpected needs is important. It is
      also important to access age and developmentally appropriate evidence-based information about
      sexual health, pregnancy, and healthy relationships.
    </p>
  </>;

export default Blurb2_1E;
