import React from 'react';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Blurb17_1A from '../../../blurbs/17_NoDiagnosisTearoff/Blurb17_1A';
import Blurb17_1B from '../../../blurbs/17_NoDiagnosisTearoff/Blurb17_1B';
import Blurb17_1C from '../../../blurbs/17_NoDiagnosisTearoff/Blurb17_1C';
import Blurb17_1D from '../../../blurbs/17_NoDiagnosisTearoff/Blurb17_1D';
import Blurb17_1E from '../../../blurbs/17_NoDiagnosisTearoff/Blurb17_1E';
import SectionTitle from '../components/SectionTitle';
import Variables from '../components/Variables';
import Card from '../components/Card';
import PaddedPrintLayout from '../../../components/PaddedPrintLayout';

const NoDiagnosisTearoff = () => {
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const variable = {age, diagnosis};
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS.id]);

  const NoDiagnosisTearoffsBlurbs17_1A = diagnosis === '3' && age === '1';
  const NoDiagnosisTearoffsBlurbs17_1B = diagnosis === '3' && age === '2';
  const NoDiagnosisTearoffsBlurbs17_1C = diagnosis === '3' && age === '3';
  const NoDiagnosisTearoffsBlurbs17_1D = diagnosis === '3' && age === '4';
  const NoDiagnosisTearoffsBlurbs17_1E = diagnosis === '3' && age === '5';

  if (formData.no_diagnosis_tearoff ||
    NoDiagnosisTearoffsBlurbs17_1A ||
    NoDiagnosisTearoffsBlurbs17_1B ||
    NoDiagnosisTearoffsBlurbs17_1C ||
    NoDiagnosisTearoffsBlurbs17_1D ||
    NoDiagnosisTearoffsBlurbs17_1E) {
    return <PaddedPrintLayout>
      <section className='last-section'>
        <SectionTitle>Tailored Feedback - No diagnosis</SectionTitle>
        <Variables variableObject={variable}/>
        <Card>
          <h3 className='chapter-title'>
            My Unique <span className='card-title'>Needs</span>
          </h3>
          <>
            {NoDiagnosisTearoffsBlurbs17_1A && <Blurb17_1A/>}
            {NoDiagnosisTearoffsBlurbs17_1B && <Blurb17_1B/>}
            {NoDiagnosisTearoffsBlurbs17_1C && <Blurb17_1C/>}
            {NoDiagnosisTearoffsBlurbs17_1D && <Blurb17_1D/>}
            {NoDiagnosisTearoffsBlurbs17_1E && <Blurb17_1E/>}
          </>
          {
            formData.no_diagnosis_tearoff && <p>{formData.no_diagnosis_tearoff}</p>
          }
        </Card>
      </section>
    </PaddedPrintLayout>;
  }

  return null;
};

export default NoDiagnosisTearoff;
