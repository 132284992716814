import React from 'react';

const Blurb5_3B = () =>
  <>
    <p>
      Your child’s intellectual functioning, or how they learn, reason, use language, and solve problems may be different compared to other children their age. This may in turn impact their experience of success in school. To support your child’s academic achievement, early intervention, modified programming, appropriate accommodations and strategies, and ongoing monitoring of their development will be important. Identified needs in this area may also be part of an individual education plan (IEP) to support their unique learning needs at school.
    </p>
  </>;

export default Blurb5_3B;
