import React from 'react';

const Blurb2_7A = () =>
  <>
    <p>
      Children with FASD, like yours, often have difficulty sleeping well. This can impact mood, behaviour, and learning. Working with a physician and/or psychologist can help you to identify additional strategies and resources to support healthy sleep, such as a <span className='text-bold'>consistent</span> nighttime routine. <span className='text-bold'>Dr.Hanlon-Dearman and colleagues have adapted a Better Nights, Better Days program and resources for children with FASD, which can be found here:</span> <a href='https://ndd.betternightsbetterdays.ca/'>https://ndd.betternightsbetterdays.ca/</a>
    </p>
  </>;

export default Blurb2_7A;
