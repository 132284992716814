import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {FormNav} from '../../../components/FormNav';
import {useDispatch, useSelector} from 'react-redux';
import {submitForm} from '../../../../redux/actions/forms';
import FORMS from '../../../../redux/constants';
import PropTypes from 'prop-types';
import SpecifyTextBox from '../../../components/SpecifyTextBox';

const IdentificationForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.IDENTIFICATION.id]);
  const dispatch = useDispatch();
  const countryOptions = FORMS.IDENTIFICATION.fields.country.options;
  const provinceOptions = FORMS.IDENTIFICATION.fields.province.options;
  const isFormComplete = formData.siteid.length > 0 && formData.country.length > 0 &&
    (formData.country === FORMS.IDENTIFICATION.fields.country.specify_other.condition ? formData.country_other.length > 0 : true) &&
    (formData.country === FORMS.IDENTIFICATION.fields.province.condition ? formData.province.length > 0 : true);

  const handleFormChange = updatedFormData => {
    if (formData.country !== updatedFormData.country) {
      updatedFormData.country_other = FORMS.IDENTIFICATION.fields.country.specify_other.value;
      updatedFormData.province = FORMS.IDENTIFICATION.fields.province.value;
    }

    dispatch(submitForm(FORMS.IDENTIFICATION.id, updatedFormData));
  };

  return (
    <>
      <div className='lead group-name'>Identification</div>
      <form>
        <div className='grid-x grid-margin-y grid-margin-x'>
          <div className='cell large-5'>
            <label
              className='form-field'
              htmlFor='site-id'>{FORMS.IDENTIFICATION.fields.siteid.title}</label>
            <input
              className='margin-bottom-0'
              id='site-id'
              onChange={event => handleFormChange({...formData, siteid: event.target.value})}
              placeholder='site Id'
              type='text'
              value={formData.siteid}/>
          </div>
          <div className='auto cell'>
            <label className='form-field'>{FORMS.IDENTIFICATION.fields.country.title}</label>
            <div className='grid-x small-up-2 medium-up-3'>
              {Object.keys(countryOptions).map(optionKey =>
                <RadioButton
                  checked={formData.country === optionKey}
                  className='cell'
                  key={optionKey}
                  label={countryOptions[optionKey]}
                  onChange={event => handleFormChange({...formData, country: event.target.value})}
                  value={optionKey}/>)}
            </div>
            {
              formData.country === FORMS.IDENTIFICATION.fields.country.specify_other.condition && <>
                <SpecifyTextBox
                  fieldId={FORMS.IDENTIFICATION.fields.country.specify_other.id}
                  formId={FORMS.IDENTIFICATION.id}
                  handleFormChange={handleFormChange}
                  title={FORMS.IDENTIFICATION.fields.country.specify_other.title}/>
              </>
            }
            {
              formData.country === FORMS.IDENTIFICATION.fields.province.condition && <>
                <label className='form-field'>
                  {FORMS.IDENTIFICATION.fields.province.title}
                </label>
                <div className='form-field-value-rounded-borders grid-x small-up-2 medium-up-3'>
                  {Object.keys(provinceOptions).map(optionKey =>
                    <RadioButton
                      checked={formData.province === optionKey}
                      className='cell'
                      key={optionKey}
                      label={provinceOptions[optionKey]}
                      onChange={event => handleFormChange({...formData, province: event.target.value})}
                      value={optionKey}/>)}
                </div>
              </>
            }
          </div>
        </div>

      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>

    </>
  );
};

IdentificationForm.propTypes = {
  formIndex: PropTypes.number
};

export default IdentificationForm;
