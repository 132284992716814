import React from 'react';

const Blurb3_3D = () =>
  <>
    <p>
      You have reported experiencing one of more disrupted attachment experiences. When people make sense of or interact in the world differently due to brain-based differences, they may not always detect interpersonal cues as expected. For this reason, it can be very helpful to have support learning to identify your cues and ways to respond that make sense for you. Accessing support that focuses on your central relationship(s), like a partner, family member, or friend, can improve your sense of social safety. You may choose to explore therapy that helps build healthy relationships in adulthood. Even in circumstances where attachment is disrupted, it is possible to re-establish secure and strong relationships at all developmental periods.
    </p>
  </>;

export default Blurb3_3D;
