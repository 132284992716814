import React from 'react';
import {generatePath, Link} from 'react-router-dom';
import ROUTES from '../../../routes';
import FORMS from '../../../redux/constants';
import instructions from '../../../locales/instructions';

const Index = () =>
  <div>
    <h3 className='margin-vertical-2'>
      {instructions.disclaimer.title}
    </h3>
    <p className='lead heading'>
      {instructions.disclaimer.termsTitle}
    </p>
    <p className='text margin-bottom-2'>
      {instructions.disclaimer.termsDescription}
    </p>
    <p className='lead heading'>
      {instructions.disclaimer.userResponsibilitiesTitle}
    </p>
    <p className='text margin-bottom-2'>
      {instructions.disclaimer.userResponsibilitiesDescription}
    </p>
    <p className='lead heading'>
      {instructions.disclaimer.dataEntryTitle}
    </p>
    <p className='text'>
      {instructions.disclaimer.dataEntryDescription}
    </p>
    <p className='text'>
      {instructions.disclaimer.dataEntryDescription2}
    </p>
    <p className='text'>
      {instructions.disclaimer.dataEntryDescription3}
    </p>
    <p className='text margin-bottom-2'>
      {instructions.disclaimer.dataEntryDescription4}
    </p>
    <p className='lead heading'>
      {instructions.disclaimer.contentTitle}
    </p>
    <p className='text margin-bottom-2'>
      {instructions.disclaimer.contentDescription}
    </p>
    <p className='lead heading'>
      {instructions.disclaimer.externalLinksTitle}
    </p>
    <p className='text margin-bottom-2'>
      {instructions.disclaimer.externalLinksDescription}
    </p>
    <p className='lead heading'>
      {instructions.disclaimer.infringingContentTitle}
    </p>
    <p className='text'>
      {instructions.disclaimer.infringingContentDescription}
    </p>
    <p className='text margin-bottom-2'>
      {instructions.disclaimer.infringingContentDescription2}
    </p>
    <h3 className='margin-vertical-2'>
      {instructions.disclaimer.subTitle2}
    </h3>
    <p className='lead heading'>
      {instructions.disclaimer.disclaimerTitle}
    </p>
    <p className='text'>
      {instructions.disclaimer.disclaimerDescription}
    </p>
    <p className='text margin-bottom-2'>
      {instructions.disclaimer.disclaimerDescription2}
    </p>
    <div className='grid-x align-left'>
      <Link
        className='cell medium-2 large button'
        to={generatePath(ROUTES.FORMS, {url: FORMS.UNIQUE_BRAIN.url})}>
          Start
      </Link>
    </div>
  </div>;

export default Index;
