import React from 'react';

const Blurb14_8 = () =>
  <>
    <p>
      The current Canadian guidelines for diagnosing FASD highlight 10 brain domains to consider during the diagnostic process. Preschool age children’s brains are still in early stages of development so examination of all these areas is not yet possible. This means that some of your child’s areas of strengths and needs may not yet have developed or emerged.
    </p>
    <p>
      Because each person with FASD or PAE is unique, it is not easy to predict where challenges and strengths will emerge– each pathway can be different. Therefore, it will be important that your child undergo future assessment when they begin elementary school and at other important periods of transition, like high school, to gain a greater understanding of their functioning and ensure that your child is receiving appropriate supports and opportunities for success. The following report has been prepared to provide ideas for how to nurture your child’s unique areas of strengths and needs identified during the FASD assessment process, as well as recommendations for potential areas of support and services.
    </p>
  </>;

export default Blurb14_8;
