import React from 'react';

const Blurb10_10 = () =>
  <>
    <p>
      When a person is already living in their own home, it is important to acknowledge the skills and strengths that are being used, and noticing what works -- as is said, ‘if it’s not broken, don’t fix it’! Celebrating our successes helps with motivation and continued engagement with supports.
    </p>
    <p>
      Because everyone is unique and constantly growing, it is also important to continue to monitor whether things are still working, how supports are evolving to better meet needs, and to continue building on you/your child’s strengths and successes.
    </p>
  </>;

export default Blurb10_10;
