import React from 'react';

const Blurb10_1A = () =>
  <>
    <p>
      Access to safe, affordable housing is a basic human right. Finding housing that meets your child’s unique needs will help create a safe and stable home environment. Even when your child is young, we can consider ways to support success. You may ask yourself, ‘what will be a good fit for my child when it comes to housing?’ This will evolve as your child develops, but watching for goodness of fit can guide how supports may unfold.
    </p>
    <p>
      Housing for individuals with FASD can be complex due to a range of barriers. These can include affordability, access to housing supports, quality of housing, and mental health needs. Addressing these factors requires a coordinated approach, including government and community working together. Housing workers, landlords, and other supports can use the following housing guide to help people with FASD achieve housing success. <a href='https://canfasd.ca/wp-content/uploads/2019/10/FASD-X-Housing-Pei-2018_Amended-March-04-2019-dl.pdf'>
      https://canfasd.ca/wp-content/uploads/2019/10/FASD-X-Housing-Pei-2018_Amended-March-04-2019-dl.pdf</a>
    </p>
  </>;

export default Blurb10_1A;
