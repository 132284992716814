import React from 'react';

const Blurb5_1B = () =>
  <>
    <p>
      Schools provide a setting to prepare children and teens for adulthood, and to pursue many other important future goals, like social and identity development, and employment planning. A variety of strengths and difficulties may emerge in school settings for children with FASD. It can be difficult to get ahead of these challenges and nurture strengths, so individualized, collaborative, and goal-oriented approaches to support are important.
    </p>
    <p>
      Through meaningful collaboration between school staff, families, and caregivers, strategies for students with FASD can be co-created for achieving desired goals, success can be reinforced and replicated, and supports can be adapted as needed. Students with FASD will often require comprehensive planning that connects classroom, school, and home resources.
    </p>
  </>;

export default Blurb5_1B;
