import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import SpecifyTextBox from '../../../components/SpecifyTextBox';

const TreatmentAccessForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.TREATMENT_ACCESS.id]);
  const dispatch = useDispatch();
  const treatmentAccesses = FORMS.TREATMENT_ACCESS.fields;
  const fieldOptions = FORMS.TREATMENT_ACCESS.options;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] !== '');
  const isFormComplete = Object.keys(treatmentAccesses).filter(key => key !== 'othersubstances_treat').every(field => checkedFields.includes(field)) &&
    (formData.othersubstances_treat && formData.othersubstances_treat === '1' ? formData.othersubstances_treat_specify.length > 0 : true);

  const handleFormChange = updatedFormData => {
    if (formData.othersubstances_treat !== updatedFormData.othersubstances_treat) {
      updatedFormData[FORMS.TREATMENT_ACCESS.fields.othersubstances_treat.specify_other.id] = FORMS.TREATMENT_ACCESS.fields.othersubstances_treat.specify_other.value;
    }

    dispatch(submitForm(FORMS.TREATMENT_ACCESS.id, updatedFormData));
  };

  return (

    <>
      <div className='lead group-name'>Are any of the following substance use/misuse treatments currently being assessed?</div>
      <form>
        <div className='grid-x grid-margin-y'>
          <div className='cell large-8'>
            <div className='grid-x'>
              <div className='cell small-4'/>
              <div className='cell small-8'>
                <div className='grid-x'>
                  {
                    Object.values(fieldOptions).map(fieldOption =>
                      <div
                        className='auto cell text-center'
                        key={fieldOption}>
                        <label className='form-field'>{fieldOption}</label>
                      </div>)
                  }
                </div>
              </div>
            </div>
            {Object.keys(treatmentAccesses).map(accessKey =>
              <div
                className='grid-x'
                key={accessKey}>
                <div className='cell small-4'>
                  <label className='form-field'>{treatmentAccesses[accessKey].title}</label>
                </div>
                <div className='cell small-8'>
                  <div className='grid-x grid-margin-x'>
                    {Object.keys(fieldOptions).map(optionKey =>
                      <div
                        className='auto cell text-center'
                        key={optionKey}>
                        <input
                          checked={formData[accessKey] === optionKey}
                          className='margin-right-0'
                          id={`answer_${optionKey}`}
                          key={optionKey}
                          name={accessKey}
                          onChange={event => handleFormChange({...formData, [accessKey]: event.target.value})}
                          type='radio'
                          value={optionKey}
                        />
                      </div>)}
                  </div>
                </div>
              </div>)}
          </div>
          <div className='cell large-8'>
            {
              formData.othersubstances_treat === FORMS.TREATMENT_ACCESS.fields.othersubstances_treat.specify_other.condition && <>
                <SpecifyTextBox
                  fieldId={FORMS.TREATMENT_ACCESS.fields.othersubstances_treat.specify_other.id}
                  formId={FORMS.TREATMENT_ACCESS.id}
                  handleFormChange={handleFormChange}
                  title={FORMS.TREATMENT_ACCESS.fields.othersubstances_treat.specify_other.title}/>
              </>
            }
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

TreatmentAccessForm.propTypes = {
  formIndex: PropTypes.number
};

export default TreatmentAccessForm;
