import React from 'react';

const Blurb7_24D = () =>
  <>
    <p>
      To support food security, it may be helpful to contact community organizations and your support team about accessing your local food bank or other services.
    </p>
  </>;

export default Blurb7_24D;
