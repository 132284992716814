const DateToday = () => {
  const today = new Date();

  // Arrays containing the names of the days and months
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  // Get the day, month, and year from the Date object
  const dayName = days[today.getDay()]; // getDay() returns a number from 0 to 6
  const monthName = months[today.getMonth()]; // getMonth() returns a number from 0 to 11
  const day = today.getDate(); // getDate() returns the day of the month
  const year = today.getFullYear(); // getFullYear() returns the year

  // Combine the parts into a full date string
  return `${dayName}, ${monthName} ${day}, ${year}`;
};

export default DateToday;
