import React from 'react';

const Blurb5_1E = () =>
  <>
    <p>
      Education and skill-building environments provide unique opportunities for people with FASD to pursue many other important future goals like social and identity development and employment planning. A variety of strengths and difficulties can emerge in education or skill-building. It can be difficult to get ahead of these challenges and nurture strengths, so individualized, collaborative, and goal-oriented approaches to support are important.
    </p>
    <p>
      Through meaningful collaboration between school or training staff, families, and other supports, strategies for students with FASD can be co-created for achieving desired goals, success can be reinforced and replicated, and supports can be adapted as needed. Students, regardless of age, will often require comprehensive planning that connects in classroom or on the job training, the school setting, and home resources.
    </p>
  </>;

export default Blurb5_1E;
