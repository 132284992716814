import React from 'react';

const Blurb8_2D = () =>
  <>
    <p>
      The skills and supports you will need to nurture your mental health may be different from other adults. You can support your own mental health by keeping an eye out for challenges and accessing supports early if concerns come up. This may include connecting with a mental health professional or finding activities that generate positive emotions. Some adults find that their mental wellness is improved through exercise or mindfulness activities such as yoga or meditation, and others may benefit from social activities. Here is a short guide to supporting mental well-being in individuals with FASD from CanFASD: <a href='https://canfasd.ca/2020/05/08/mental-health-for-individuals-with-fasd/'>
      https://canfasd.ca/2020/05/08/mental-health-for-individuals-with-fasd/</a>
    </p>
  </>;

export default Blurb8_2D;
