import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import getKeyByValue from '../../../utils/getKeyByValue';
import SpecifyTextBox from '../../../components/SpecifyTextBox';

const MedicalHistoryForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.MEDICAL_HISTORY.id]);
  const dispatch = useDispatch();
  const medicalHistoryFields = FORMS.MEDICAL_HISTORY.fields;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] !== '');
  const isFormComplete = Object.keys(medicalHistoryFields).filter(key => key !== 'medicalother').every(field => checkedFields.includes(field)) &&
    formData.growth_specify.length > 0 &&
    (formData.neurological === '1' ? formData.neurological_specify.length > 0 : true) &&
    (formData.mental === '1' ? formData.mental_specify.length > 0 : true) &&
    (formData.headneck === '1' ? formData.headneck_specify.length > 0 : true) &&
    (formData.cardiovascular === '1' ? formData.cardiovascular_specify.length > 0 : true) &&
    (formData.respiratory === '1' ? formData.respiratory_specify.length > 0 : true) &&
    (formData.endocrine === '1' ? formData.endocrine_specify.length > 0 : true) &&
    (formData.musculoskeletal === '1' ? formData.musculoskeletal_specify.length > 0 : true) &&
    (formData.infectiousdiseases === '1' ? formData.infectiousdiseases_specify.length > 0 : true) &&
    (formData.medicalother === '1' ? formData.medicalother_specify.length > 0 : true);

  const handleFormChange = (updatedFormData, fieldId) => {
    if (fieldId &&
      medicalHistoryFields[fieldId].specify_other &&
      formData[fieldId] !== updatedFormData[fieldId]) {
      updatedFormData[medicalHistoryFields[fieldId].specify_other.id] = medicalHistoryFields[fieldId].specify_other.value;
    }

    dispatch(submitForm(FORMS.MEDICAL_HISTORY.id, updatedFormData));
  };

  const renderOptions = (optionsObject, fieldKey) => {
    if (optionsObject[2]) {
      return <>
        <div
          className='auto small-6 large-2 text-center'
          key='2'>
          <input
            checked={formData[fieldKey] === '2'}
            className='margin-right-0'
            id='answer_2'
            name={fieldKey}
            onChange={event => handleFormChange({...formData, [fieldKey]: event.target.value}, fieldKey)}
            type='radio'
            value='2'
          />
        </div>
        <div
          className='auto small-6 large-2  text-center'
          key='1'>
          <input
            checked={formData[fieldKey] === '1'}
            className='margin-right-0'
            id='answer_1'
            name={fieldKey}
            onChange={event => handleFormChange({...formData, [fieldKey]: event.target.value}, fieldKey)}
            type='radio'
            value='1'
          />
        </div>
      </>;
    }

    return Object.keys(optionsObject).map(optionKey => <div
      className='cell small-6 large-2 text-center'
      key={optionKey}>
      <input
        checked={formData[fieldKey] === optionKey}
        className='margin-right-0'
        id={`answer_${optionKey}`}
        name={fieldKey}
        onChange={event => handleFormChange({...formData, [fieldKey]: event.target.value}, fieldKey)}
        type='radio'
        value={optionKey}
      />
    </div>);
  };

  const renderSpecifyField = specifyObject => <div
    className='cell small-12 large-8'
    key={specifyObject.id}>
    <SpecifyTextBox
      fieldId={specifyObject.id}
      formId={FORMS.MEDICAL_HISTORY.id}
      handleFormChange={handleFormChange}
      title={specifyObject.title}/>
  </div>;

  const canRenderSpecifyField = (object, fieldKey) => {
    if (object.condition) {
      return formData[fieldKey] === getKeyByValue(medicalHistoryFields[fieldKey].options, 'Yes') && renderSpecifyField(object);
    }

    return renderSpecifyField(object);
  };

  return (
    <>
      <form>
        <label className='form-field padding-bottom-2 note'>Note: Please select at least one/all that apply.</label>
        <div>
          <div className='grid-x grid-margin-x'>
            <div className='cell small-4 large-3'/>
            <div className='cell small-8'>
              <div className='grid-x'>
                {
                  Object.values(medicalHistoryFields.growth.options).map(fieldOption =>
                    <div
                      className='cell small-6 large-2 text-center'
                      key={fieldOption}>
                      <label className='form-field'>{fieldOption}</label>
                    </div>)
                }
              </div>
            </div>
          </div>
          {Object.keys(medicalHistoryFields).map(fieldKey =>
            <div
              className='margin-bottom-1'
              key={fieldKey}>
              <div
                className='grid-x grid-margin-x'>
                <div className='cell small-4 large-3'>
                  <label className='form-field'>{medicalHistoryFields[fieldKey].title}</label>
                </div>
                <div className='cell small-8'>
                  <div className='grid-x'>
                    {
                      renderOptions(medicalHistoryFields[fieldKey].options, fieldKey)
                    }
                    {medicalHistoryFields[fieldKey].specify_other &&
                          canRenderSpecifyField(medicalHistoryFields[fieldKey].specify_other, fieldKey)}
                  </div>
                </div>
              </div>
            </div>)}
        </div>

      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

MedicalHistoryForm.propTypes = {
  formIndex: PropTypes.number
};

export default MedicalHistoryForm;
