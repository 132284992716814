import React from 'react';

const Blurb3_3B = () =>
  <>
    <p>
      Your child has encountered one or more disrupted attachment experiences. Early childhood is an important time for intervention and can promote healthy attachment and relationship outcomes in adolescence and adulthood. Even in circumstances where attachment is disrupted, it is possible to re-establish secure and strong relationships at all developmental periods.
    </p>
    <p>
      Support that specifically focuses on the child-caregiver relationship may be an effective way to help you to identify behavioural cues that may suggest the child is having difficulty in interactions with others. These cues can include difficulties regulating emotions. These supports will allow the caregiver and child to identify and respond to these behavioural cues.
    </p>
    <p>
      Early behavioural interventions, such as parent-child interaction therapy (PCIT), that focus on helping caregivers understand their child’s unique brain, balance child and caregiver needs, and adjust behaviours can support the development of more secure attachment bonds between children with FASD and their caregiver(s).
    </p>
  </>;

export default Blurb3_3B;
