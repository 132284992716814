import React from 'react';

const Blurb1_8D = () =>
  <>
    <p>
        Findings from your assessment suggest that you may have difficulties across most areas of neurodevelopmental
        functioning. This includes their overall ability to learn, reason, and solve problems. Difficulties with
        academic skills and adaptive/social functioning were also identified.
    </p>
    <p>
        Adults with these kinds of challenges may struggle with communicating clearly, remembering and learning new
        information, paying attention, and making decisions. You may also have challenges related to education and
        training, peer interactions, and accomplishing goals.
    </p>
    <p>
        Many people with FASD show a lot of scatter in their skills and may experience times of success during
        specific tasks. However, changes to expectations may rapidly lower their level of success, cause confusion,
        and can lead to questions regarding the person’s effort or motivation. One of the unique impacts of prenatal
        alcohol exposure is an uneven skill set, which can make it hard to set consistent expectations. When we
        understand the brain-based nature of these difficulties we can adapt supports and meaningfully tailor
        interventions to accommodate broad needs, build acceptance, and create flexible and responsive environments.
    </p>
    <p>
        People with FASD also have important strengths, such as perseverance, willingness to accept help, and
        a desire for connection to others. Such strengths can help identify opportunities for people with FASD
        to make meaningful contributions to family and community and uncover new possibilities.
    </p>
  </>;

export default Blurb1_8D;
