import React from 'react';

const Blurb1_3A = () =>
  <>
    <p>
      Early intervention is important to support your child’s daily needs and behaviours. Prenatal alcohol
      exposure (PAE) impacts the brains and bodies of people in different ways. Your child’s assessment team
      indicated that your child has Fetal Alcohol Spectrum Disorder (FASD) without Sentinel Facial Features.
      Although some individuals may have physical features that are unique to PAE, other individuals like your
      child, do not. Individualized, ongoing supports can help all individuals with FASD to experience success
      and achieve healthy outcomes.
    </p>
  </>;

export default Blurb1_3A;
