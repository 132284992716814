import React from 'react';

const Blurb2_2D = () =>
  <>
    <p>
      It will be important to connect with primary health care providers (e.g., doctor, nurse practitioner) as you move through your adult life.
    </p>
  </>;

export default Blurb2_2D;
