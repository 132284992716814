import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

const SpecifyTextBox = ({fieldId, title, formId, className, handleFormChange}) => {
  const formData = useSelector(state => state.forms[formId]);

  return (
    <div
      className={className}>
      <input
        className='margin-bottom-0'
        id={fieldId}
        onChange={event => handleFormChange({...formData, [fieldId]: event.target.value})}
        type='text'
        value={formData[fieldId]}/>
      <label
        className='specify-form-field'
        htmlFor={fieldId}>{title}</label>
    </div>
  );
};

export default SpecifyTextBox;

SpecifyTextBox.propTypes = {
  className: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};
