import React from 'react';

const Blurb1_5C = () =>
  <>
    <p>
      Tailored intervention is important to support your teen’s daily needs and behaviours. Based on the
      assessment results, your teen did not meet diagnostic criteria for Fetal Alcohol Spectrum
      Disorder (FASD) at this time. It will be important to continue to monitor your teen’s development,
      behaviours and daily functioning to support healthy outcomes as they transition to
      adulthood and beyond.
    </p>
  </>;

export default Blurb1_5C;
