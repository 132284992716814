import React from 'react';

const Blurb1_6 = () =>
  <>
    <p>
      Everyone with FASD is unique and by gaining an understanding of each person’s difficulties and skills
      we are better equipped to work together towards healthy outcomes. With shared understanding we can
      identify: 1) ways to establish well suited environments, 2) supports that may leverage strengths and
      offset difficulties, and 3) interventions that may enhance growth and development for each individual.
    </p>
    <p>
      Through a shared understanding of FASD, including an individual’s strengths and needs, we can provide
      tailored, proactive supports to promote success and healthy outcomes. The following report builds upon
      your child’s FASD assessment findings to nurture strengths and needs and identify recommendations for
      support and services.
    </p>
  </>;

export default Blurb1_6;
