import React from 'react';
import CheckBox from '../../../components/CheckBox';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import SpecifyTextBox from '../../../components/SpecifyTextBox';

const OtherFeaturesForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.OTHER_FEATURES.id]);

  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    if (updatedFormData.associatedfeatures___6 === '0') {
      updatedFormData[FORMS.OTHER_FEATURES.fields.associatedfeatures___6.specify_other.id] = FORMS.OTHER_FEATURES.fields.associatedfeatures___6.specify_other.value;
    }

    dispatch(submitForm(FORMS.OTHER_FEATURES.id, updatedFormData));
  };

  const otherAssociatedFeatures = FORMS.OTHER_FEATURES.fields;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] === '1');
  const isFormComplete = checkedFields.length > 0 &&
    (formData.associatedfeatures___6 === '1' ? formData.associatedfeatures_specify.length > 0 : true);

  return (
    <>
      <form>
        <div className='grid-x grid-margin-y'>
          <div className='cell large-7'>
            <label className='form-field'>Other Associated Features <span>(Please check all that apply)</span></label>
            <div className='grid-x small-up-1 medium-up-3'>
              {Object.entries(otherAssociatedFeatures).map(([featurekey, featureValues]) =>
                <CheckBox
                  checked={formData[featurekey] === '1'}
                  className='cell'
                  key={featurekey}
                  onChange={e => handleFormChange({
                    ...formData,
                    [featurekey]: e.target.checked ? '1' : '0'
                  })}
                  value={featureValues.title}/>)}
            </div>
          </div>
          <div className='cell large-7'>
            {
              formData.associatedfeatures___6 === '1' && <>
                <SpecifyTextBox
                  fieldId={FORMS.OTHER_FEATURES.fields.associatedfeatures___6.specify_other.id}
                  formId={FORMS.OTHER_FEATURES.id}
                  handleFormChange={handleFormChange}
                  title={FORMS.OTHER_FEATURES.fields.associatedfeatures___6.specify_other.title}/>
              </>
            }
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

OtherFeaturesForm.propTypes = {
  formIndex: PropTypes.number
};

export default OtherFeaturesForm;
