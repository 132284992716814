import React from 'react';

const Blurb3_7 = () =>
  <>
    <p>
      Recommendations from your assessment suggested that family and couples counselling may be helpful. Attachment-based family therapy can support healthy attachment and family cohesion between children and caregiver(s), siblings, intimate partners, and/or other close family members. Working with a registered clinician who is FASD- and trauma-informed will be important.
    </p>
  </>;

export default Blurb3_7;
