import React from 'react';

const Blurb6_2C = () =>
  <>
    <p>
      To help promote your teen’s ongoing identity development, provide them with opportunities to explore their unique interests, beliefs, and values. Conversations, modelling, and play can all be important was to explore identity and can lead to greater self-awareness and insight. When supporters understand your teen’s unique identity, they are better equipped to address challenges and build on strengths as your teen continues to grow and develop.
    </p>
  </>;

export default Blurb6_2C;
