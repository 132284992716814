import React from 'react';

const Blurb8_8B = () =>
  <>
    <p>
      Trauma occurs when an individual experiences a distressing or disturbing event with an actual or perceived threat to their safety and well-being. Trauma can include experiences that are passed down through family generations. The impacts of trauma can be significant and long-lasting and affect a person’s mental, emotional, and physical health, as well as social functioning, academic achievement, self-regulation, and caregiver relationships. Trauma-informed therapy can be helpful for you and your child to navigate the impacts of trauma in their life.
    </p>
  </>;

export default Blurb8_8B;
