import React from 'react';

const Blurb5_4A = () =>
  <>
    <p>
      Your child may struggle to express themselves verbally and/or consistently understand others.  This may be especially true when verbal exchanges are fast paced, which is typical within social interactions and classroom environments. Sometimes individuals with FASD can effectively mask these challenges by repeating familiar language, controlling conversations, and simply agreeing with others and following along with peers – particularly as they get older. They may also simply feel overwhelmed by verbal communication and quickly dysregulate, become angry, or avoid others.
    </p>
    <p>
      Your child can build these basic skills with support from a speech-language pathologist who is familiar with FASD. Ensuring supporters are aware of these language differences may create space for better understanding and identification of alternative communication and learning strategies.
    </p>
  </>;

export default Blurb5_4A;
