import React from 'react';

const Blurb5_6E = () =>
  <>
    <p>
      When learning, education, and academics are challenging, and you/your child may experience difficulties with problem-solving, complex ideas, reasoning, language delays, achievement, and memory. Appropriate supports in your/your child’s learning environments, regardless of the context the learning occurs, can help you to learn and complete education in a meaningful way that makes sense for you/your child.
    </p>
  </>;

export default Blurb5_6E;
