import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import {useSelector} from 'react-redux';
import FORMS, {IMAGES} from '../../../../redux/constants';
import Blurb4_1A from '../../../blurbs/4_SocialCompetency/Blurb4_1A';
import Blurb4_1B from '../../../blurbs/4_SocialCompetency/Blurb4_1B';
import Blurb4_1C from '../../../blurbs/4_SocialCompetency/Blurb4_1C';
import Blurb4_1D from '../../../blurbs/4_SocialCompetency/Blurb4_1D';
import Blurb4_1E from '../../../blurbs/4_SocialCompetency/Blurb4_1E';
import Blurb4_2A from '../../../blurbs/4_SocialCompetency/Blurb4_2A';
import Blurb4_2B from '../../../blurbs/4_SocialCompetency/Blurb4_2B';
import Blurb4_2C from '../../../blurbs/4_SocialCompetency/Blurb4_2C';
import Blurb4_2D from '../../../blurbs/4_SocialCompetency/Blurb4_2D';
import Blurb4_2E from '../../../blurbs/4_SocialCompetency/Blurb4_2E';
import Blurb4_3A from '../../../blurbs/4_SocialCompetency/Blurb4_3A';
import Blurb4_3B from '../../../blurbs/4_SocialCompetency/Blurb4_3B';
import Blurb4_3C from '../../../blurbs/4_SocialCompetency/Blurb4_3C';
import Blurb4_3D from '../../../blurbs/4_SocialCompetency/Blurb4_3D';
import Blurb4_3E from '../../../blurbs/4_SocialCompetency/Blurb4_3E';
import Blurb4_4A from '../../../blurbs/4_SocialCompetency/Blurb4_4A';
import Blurb4_4B from '../../../blurbs/4_SocialCompetency/Blurb4_4B';
import Blurb4_4C from '../../../blurbs/4_SocialCompetency/Blurb4_4C';
import Blurb4_4D from '../../../blurbs/4_SocialCompetency/Blurb4_4D';
import Blurb4_4E from '../../../blurbs/4_SocialCompetency/Blurb4_4E';
import Variables from '../components/Variables';
import TwoColumnLayout from './TwoColumnLayout';

const SocialCompetency = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {ref_reason___6} = useSelector(state => state.forms[FORMS.REFERRAL_REASON.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);

  const variables = {diagnosis, ref_reason___6, age};

  const SocialCompetencyBlurb4_1A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_1B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_1C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_1D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_1E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const SocialCompetencyBlurb4_2A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_2B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_2C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_2D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_2E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const SocialCompetencyBlurb4_3A = ref_reason___6 === '1' &&
    age === '1' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_3B = ref_reason___6 === '1' &&
    age === '2' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_3C = ref_reason___6 === '1' &&
    age === '3' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_3D = ref_reason___6 === '1' &&
    age === '4' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_3E = ref_reason___6 === '1' &&
    age === '5' &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const SocialCompetencyBlurb4_4A = age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_4B = age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_4C = age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_4D = age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const SocialCompetencyBlurb4_4E = age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  return <section>
    <SectionTitle>Social Competency</SectionTitle>
    <Variables variableObject={variables}/>
    {
      (SocialCompetencyBlurb4_1A || SocialCompetencyBlurb4_1B || SocialCompetencyBlurb4_1C || SocialCompetencyBlurb4_1D || SocialCompetencyBlurb4_1E) &&
      <Card>
        <h3 className='chapter-title'>
          Things to <span className='card-title'>Know</span>
        </h3>
        <>
          {SocialCompetencyBlurb4_1A && <Blurb4_1A/>}
          {SocialCompetencyBlurb4_1B && <Blurb4_1B/>}
          {SocialCompetencyBlurb4_1C && <Blurb4_1C/>}
          {SocialCompetencyBlurb4_1D && <Blurb4_1D/>}
          {SocialCompetencyBlurb4_1E && <Blurb4_1E/>}
        </>
      </Card>
    }
    <TwoColumnLayout>
      {
        (SocialCompetencyBlurb4_2A || SocialCompetencyBlurb4_2B || SocialCompetencyBlurb4_2C || SocialCompetencyBlurb4_2D || SocialCompetencyBlurb4_2E ||
          SocialCompetencyBlurb4_3A || SocialCompetencyBlurb4_3B || SocialCompetencyBlurb4_3C || SocialCompetencyBlurb4_3D || SocialCompetencyBlurb4_3E) &&
        <Card>
          <h3 className='chapter-title'>
            Things to <span className='card-title'>Do Now</span>
          </h3>
          <>
            {SocialCompetencyBlurb4_2A && <Blurb4_2A/>}
            {SocialCompetencyBlurb4_2B && <Blurb4_2B/>}
            {SocialCompetencyBlurb4_2C && <Blurb4_2C/>}
            {SocialCompetencyBlurb4_2D && <Blurb4_2D/>}
            {SocialCompetencyBlurb4_2E && <Blurb4_2E/>}
          </>
          <>
            {SocialCompetencyBlurb4_3A && <Blurb4_3A/>}
            {SocialCompetencyBlurb4_3B && <Blurb4_3B/>}
            {SocialCompetencyBlurb4_3C && <Blurb4_3C/>}
            {SocialCompetencyBlurb4_3D && <Blurb4_3D/>}
            {SocialCompetencyBlurb4_3E && <Blurb4_3E/>}
          </>
        </Card>
      }
      {
        (SocialCompetencyBlurb4_4A || SocialCompetencyBlurb4_4B || SocialCompetencyBlurb4_4C || SocialCompetencyBlurb4_4D || SocialCompetencyBlurb4_4E) &&
        <Card>
          <h3 className='chapter-title'>
            Things to <span className='card-title'>Plan</span> for
          </h3>
          <>
            {SocialCompetencyBlurb4_4A && <Blurb4_4A/>}
            {SocialCompetencyBlurb4_4B && <Blurb4_4B/>}
            {SocialCompetencyBlurb4_4C && <Blurb4_4C/>}
            {SocialCompetencyBlurb4_4D && <Blurb4_4D/>}
            {SocialCompetencyBlurb4_4E && <Blurb4_4E/>}
          </>
        </Card>
      }
    </TwoColumnLayout>
    <img src={IMAGES.IMAGE4}/>
  </section>;
};

export default SocialCompetency;
