import React from 'react';

const Blurb3_2E = () =>
  <>
    <p>
      Stable and consistent interactions that promote positive attachment with caregivers, peers, colleagues, and supportive others are important for developing healthy relationships. Here are some resources for caregivers and supportive others of individuals with FASD: <a href='https://canfasd.ca/wp-content/uploads/2018/03/Caregiver-Resource-Guide-FASD-March-2018.pdf'>
        https://canfasd.ca/wp-content/uploads/2018/03/Caregiver-Resource-Guide-FASD-March-2018.pdf
      </a>
    </p>
    <p>
      Connecting with FASD-informed supports and community agencies may provide a helpful step in developing positive and supportive relationships, through peer groups, mentorship programs, and other social opportunities.
    </p>
  </>;

export default Blurb3_2E;
