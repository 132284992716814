import React from 'react';

const Blurb10_9B = () =>
  <>
    <p>
      Safe and stable housing is a lifelong need, and related supports, accommodations, and strategies may change as we grow. To help your child one day prepare for living in safe and affordable housing, it is important to identify their unique strengths and needs, recognize and plan for when they need supports, and create opportunities to practice necessary living skills in a safe environment.
    </p>
  </>;

export default Blurb10_9B;
