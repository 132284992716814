import React from 'react';

const Blurb8_4E = () =>
  <>
    <p>
      You/your child may benefit from mental health services, such as individualized therapy, group therapy, medication, and/or family intervention. Your/your child’s mental health care provider should be licensed with local professional bodies, and knowledgeable about FASD-informed interventions that will work with for you/them.
    </p>
  </>;

export default Blurb8_4E;
