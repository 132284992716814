import React from 'react';

const Blurb7_2B = () =>
  <>
    <p>
      Children with FASD may face challenges with adaptive skills, such as setting healthy personal boundaries, understanding social cues, and safely completing daily living tasks. Adults can help children develop these skills through intentional support, skill-building, and routines. Proactive community engagement and collaboration among supports allow for safe skill-building that is balanced with intervention to promote interdependence.
    </p>
  </>;

export default Blurb7_2B;
