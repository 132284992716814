import React from 'react';

const Blurb2_5B = () =>
  <>
    <p>
      The five senses of smell, sight, taste, touch, and sound help us regulate and respond to sensory information, like pain, temperature, and textures. Sometimes individuals with FASD experience sensory input differently. Lights may be too bright, sounds too loud, textures unpleasant, and pain may hurt more or less than expected, which can even create safety concerns. With targeted intervention from occupational therapists or physical therapists, sensory functioning and safety can be supported. Awareness of your child’s sensory needs can also help us identify environmental strategies and accommodations that reduce stress and increase success.
    </p>
    <p>
      For more information about sensory processing and FASD you can access the following webinar on the CanFASD YouTube channel: <span className='text-bold'>The Sensory World of People with FASD:</span> <a href='https://www.youtube.com/watch?v=LL7HyBoCI3g&t=460s'>https://www.youtube.com/watch?v=LL7HyBoCI3g&t=460s</a>
    </p>
  </>;

export default Blurb2_5B;
