import React from 'react';

const Blurb7_24B = () =>
  <>
    <p>
      To support food security for you and your child, it may be helpful to contact community organizations
      and your support team about accessing your local food bank or other services.
    </p>
  </>;

export default Blurb7_24B;
