import React from 'react';

const Blurb9_4A = () =>
  <>
    <p>
      When the time comes for your child to seek out employment, your child will likely benefit from coaching and education surrounding the expectations of their job. You may want to help your child with the practical tasks like helping them remember when they work (e.g., creating a schedule with them), helping them plan transportation to and from their job, or practicing what to do in certain situations. Your child’s coworkers and employers may also benefit from education on FASD to help create a positive and safe work environment. This FASD practical guide might be helpful to give to your child’s employer: <a href='https://drive.google.com/file/d/1MIdXwlXOWOgvPSQwn4TwVHPNbslTLMX3/view'>
      https://drive.google.com/file/d/1MIdXwlXOWOgvPSQwn4TwVHPNbslTLMX3/view</a>
    </p>
  </>;

export default Blurb9_4A;
