import React from 'react';

const Blurb2_6C = () =>
  <>
    <p>
      Motor skills are required for our daily activities. Gross motor skills help us to walk, play sports, and exercise, while fine motor skills help us tie our shoes, draw, and button our shirts. Motor difficulties can impact a teenager’s ability to complete these everyday tasks. Motor skills can vary greatly from one person to another: some tasks may come easily, while others may be difficult.
    </p>
    <p>
      Understanding your teen’s motor skills can help us to build successes and respond to difficulties. Observation and talking with caregivers can be excellent sources of information to understand what a teenager can and cannot do. Targeted intervention from clinicians can further support motor development and improve function. Awareness of your teen’s motor needs can also help others to identify supports or accommodations in your teen’s environments, such as at home and at school, that reduce stress and increase success.
    </p>
  </>;

export default Blurb2_6C;
