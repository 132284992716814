import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import NoRoute from './app/pages/404';
import ROUTES from './routes';
import Home from './app/pages/Home';
import {BaseForm} from './app/pages/Form/BaseForm';
import Report from './app/pages/Report';
import {useIdleTimer} from 'react-idle-timer';
import {resetApp} from './redux/actions/app';
import {useDispatch} from 'react-redux';
import TIMEOUT from './constants/timeout';
import Topbar from './app/components/Topbar';
import ProgressBar from './app/components/ProgressBar';
import Disclaimer from './app/pages/Disclaimer';

const App = function() {
  const [remaining, setRemaining] = useState(0);
  const dispatch = useDispatch();
  const LAST_ACTIVE_TIME = 'lastActiveTime';

  const handleOnAction = () => {
    localStorage.setItem(LAST_ACTIVE_TIME, Date.now());
  };

  const handleOnIdle = () => {
    localStorage.setItem(LAST_ACTIVE_TIME, Date.now());
    dispatch(resetApp());
    window.location.replace('/');
  };

  const {getRemainingTime, isPrompted} = useIdleTimer({
    timeout: TIMEOUT.INTERVAL * 1000,
    onIdle: handleOnIdle,
    onAction: handleOnAction
  });

  useEffect(() => {
    const lastActive = localStorage.getItem(LAST_ACTIVE_TIME);

    if (lastActive) {
      const elapsed = Date.now() - Number(lastActive);

      if (elapsed > TIMEOUT.INTERVAL * 1000) {
        dispatch(resetApp());
      }
    }
    else {
      localStorage.setItem(LAST_ACTIVE_TIME, Date.now());
    }
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingSeconds = Math.ceil(getRemainingTime() / 1000);
      setRemaining(remainingSeconds);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return <main>
    <BrowserRouter>
      <Topbar/>
      <div className='grid-container'>
        <Routes>
          <Route
            element={<Home/>}
            path={ROUTES.ROOT}/>
          <Route
            element={<Disclaimer/>}
            path={ROUTES.DISCLAIMER}/>
          <Route
            element={<Report remaining={remaining}/>}
            path={ROUTES.REPORT}/>
          <Route
            element={
              <>
                <ProgressBar/>
                <BaseForm remaining={remaining}/>
              </>
            }
            path={ROUTES.FORMS}
          />
          <Route
            element={<NoRoute/>}
            path='*'/>
        </Routes>
      </div>
    </BrowserRouter>
  </main>;
};

export default App;
