import React from 'react';

const Blurb5_15A = () =>
  <>
    <p>
      Your child may benefit from specialized and FASD-informed behavioural support which may, include intervention for self-regulation, cognition, and adaptive functioning. Positive behavioural techniques and skill-building for your child, along with helping caregivers to reframe behaviour will be useful.
    </p>
  </>;

export default Blurb5_15A;
