import React from 'react';

const Blurb4_2C = () =>
  <>
    <p>
      Brain-based differences can make it more challenging for people with FASD to regulate emotions, read social cues, and interact positively with others in social settings. This can be stressful for adolescents with FASD, particularly during the teen years when relationships with peers become more important. With appropriate interventions and skill-building opportunities, adolescents can further develop their social skills and improve their interactions with peers, make and maintain friendships, and begin to regulate their emotions across social contexts. Your teen may have strengths in reciprocating affection, friendliness, and the desire to please others, which may be leveraged in the development of social skills.
    </p>
  </>;

export default Blurb4_2C;
