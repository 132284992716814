import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {FormNav} from '../../../components/FormNav';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import PropTypes from 'prop-types';

const AssessmentForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.ASSESSMENT.id]);
  let isFormComplete = false;
  const reassessmentValue = FORMS.ASSESSMENT.fields.at_risk.condition;

  if (formData.assessment_type !== reassessmentValue && formData.assessment_type.length > 0) {
    isFormComplete = true;
  }
  else if (formData.assessment_type === reassessmentValue && formData.at_risk.length > 0) {
    isFormComplete = true;
  }

  const dispatch = useDispatch();
  const assessmentOptions = FORMS.ASSESSMENT.fields.assessment_type.options;
  const reAssessmentOptions = FORMS.ASSESSMENT.fields.at_risk.options;

  const handleFormChange = updatedFormData => {
    if (updatedFormData.assessment_type !== formData.assessment_type) {
      updatedFormData.at_risk = FORMS.ASSESSMENT.fields.at_risk.value;
    }

    dispatch(submitForm(FORMS.ASSESSMENT.id, updatedFormData));
  };

  return (
    <>
      <form>
        <div className='grid-x'>
          <div className='cell medium-4 large-3'>
            <label className='form-field'>{FORMS.ASSESSMENT.fields.assessment_type.title}</label>
            {Object.keys(assessmentOptions).map(optionkey =>
              <RadioButton
                checked={formData.assessment_type === optionkey}
                className='cell'
                key={optionkey}
                label={assessmentOptions[optionkey]}
                onChange={event => handleFormChange({...formData, assessment_type: event.target.value})}
                value={optionkey}/>)}
          </div>
          <div className='cell auto'>
            {formData.assessment_type === reassessmentValue && <div className=''>
              <div className=''>
                <label className='form-field'>{FORMS.ASSESSMENT.fields.at_risk.title}</label>
              </div>
              <div className=''>

                <div>
                  {Object.keys(reAssessmentOptions).map(optionKey =>
                    <RadioButton
                      checked={formData.at_risk === optionKey}
                      className='cell'
                      key={optionKey}
                      label={reAssessmentOptions[optionKey]}
                      onChange={event => handleFormChange({...formData, at_risk: event.target.value})}
                      value={optionKey}/>)}
                </div>
              </div>
            </div>}

          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

AssessmentForm.propTypes = {
  formIndex: PropTypes.number
};

export default AssessmentForm;
