import React from 'react';

const Blurb6_4E = () =>
  <>
    <p>
      Supporting you/your child to navigate identity development can help you/your child to recognize and build upon strengths and skills that can be leveraged to address needs. Exploring your/their identity development can also support autonomy and well-being, promote growth, and help you/ your child during periods of change or transitions throughout the lifespan.
    </p>
  </>;

export default Blurb6_4E;
