import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import SpecifyTextBox from '../../../components/SpecifyTextBox';

const OtherDiagnosisForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);

  const dispatch = useDispatch();
  const otherDiagnosis = FORMS.OTHER_DIAGNOSIS.fields;
  const fieldOptions = FORMS.OTHER_DIAGNOSIS.options;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] !== '');
  const isFormComplete = Object.keys(otherDiagnosis).filter(key => key !== 'otherdiagnosis').every(field => checkedFields.includes(field)) &&
    (formData.otherdiagnosis && (formData.otherdiagnosis === '0' || formData.otherdiagnosis === '1')
      ? formData.otherdiagnosis_specify.length > 0 : true);

  const handleFormChange = updatedFormData => {
    if (updatedFormData.otherdiagnosis === '2') {
      updatedFormData[FORMS.OTHER_DIAGNOSIS.fields.otherdiagnosis.specify_other.id] = FORMS.OTHER_DIAGNOSIS.fields.otherdiagnosis.specify_other.value;
    }

    dispatch(submitForm(FORMS.OTHER_DIAGNOSIS.id, updatedFormData));
  };

  return (
    <>
      <div className='lead group-name'>Other diagnosis</div>
      <form>
        <label className='form-field padding-bottom-2 note'>Note: Assessment did not have to occur at this
          clinic</label>
        <div className='grid-x grid-margin-y'>
          <div className='cell large-9'>
            <div className='grid-x'>
              <div className='cell small-4'/>
              <div className='cell small-8'>
                <div className='grid-x'>
                  {
                    Object.values(fieldOptions).map(fieldOption =>
                      <div
                        className='auto cell text-center'
                        key={fieldOption}>
                        <label className='form-field'>{fieldOption}</label>
                      </div>)
                  }
                </div>
              </div>
            </div>
            {Object.keys(otherDiagnosis).map(diagnosisKey =>
              <div
                className='grid-x'
                key={diagnosisKey}>
                <div className='cell small-4'>
                  <label className='form-field'>{otherDiagnosis[diagnosisKey].title}</label>
                </div>
                <div className='cell small-8'>
                  <div className='grid-x'>
                    {Object.keys(fieldOptions).map(optionKey =>
                      <div
                        className='auto cell text-center'
                        key={optionKey}>
                        <input
                          checked={formData[diagnosisKey] === optionKey}
                          className='margin-right-0'
                          id={`answer_${optionKey}`}
                          key={optionKey}
                          name={diagnosisKey}
                          onChange={event => handleFormChange({...formData, [diagnosisKey]: event.target.value})}
                          type='radio'
                          value={optionKey}
                        />
                      </div>)}
                  </div>
                </div>
              </div>)}
          </div>
          <div className='cell large-9'>

            {
              formData.otherdiagnosis && formData.otherdiagnosis !== '2' && <>
                <SpecifyTextBox
                  fieldId={FORMS.OTHER_DIAGNOSIS.fields.otherdiagnosis.specify_other.id}
                  formId={FORMS.OTHER_DIAGNOSIS.id}
                  handleFormChange={handleFormChange}
                  title={FORMS.OTHER_DIAGNOSIS.fields.otherdiagnosis.specify_other.title}/>
              </>
            }
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

OtherDiagnosisForm.propTypes = {
  formIndex: PropTypes.number
};

export default OtherDiagnosisForm;
