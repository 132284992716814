import React from 'react';

const Blurb4_2D = () =>
  <>
    <p>
      Brain-based differences can make it more challenging for people with FASD to regulate emotions, read social cues, and interact positively with others in social settings. This can be stressful because adults with FASD, like everyone, often value connections and relationships. With specialized intervention and consistent social interactions, you can improve your social skills, make, and maintain friendships, and become better at regulating emotions across social contexts. You may have strengths in reciprocating affection, friendliness, and the desire to please others, which may be leveraged in the development of social skills.
    </p>
  </>;

export default Blurb4_2D;
