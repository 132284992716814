import React from 'react';

const Blurb3_4E = () =>
  <>
    <p>
      You/your child may have had past traumatic experiences. When people experience trauma, this can influence attachment, how the person perceives themselves, and their relationships with caregivers and other people in their lives. Attachment-based therapy can help you/your child develop healthy coping skills, and support attachment relationships, family cohesion and overall well-being.
    </p>
  </>;

export default Blurb3_4E;
