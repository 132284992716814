import React from 'react';
import CheckBox from '../../../components/CheckBox';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import SpecifyTextBox from '../../../components/SpecifyTextBox';

const EthnicityForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.ETHNICITY.id]);
  const dispatch = useDispatch();
  const ethnicities = FORMS.ETHNICITY.fields;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] === '1');
  const isFormComplete = checkedFields.length > 0 &&
    (formData.ethnicity___12 === '1' ? formData.ethnicity_specify.length > 0 : true);

  const handleFormChange = updatedFormData => {
    if (!updatedFormData.ethnicity___12) {
      updatedFormData[ethnicities.ethnicity___12.specify_other.id] = ethnicities.ethnicity___12.specify_other.value;
    }

    dispatch(submitForm(FORMS.ETHNICITY.id, updatedFormData));
  };

  return (
    <>
      <form>
        <div className='grid-x grid-margin-y'>
          <div className='cell large-7'>
            <label className='form-field'>
              Which ethnic group(s) does this person most identify with?
            </label>
            {Object.entries(ethnicities).map(([ethnicityKey, ethnicityValues]) =>
              <CheckBox
                checked={formData[ethnicityKey] === '1'}
                className='checkbox-cells'
                key={ethnicityKey}
                onChange={e => handleFormChange({
                  ...formData,
                  [ethnicityKey]: e.target.checked ? '1' : '0'
                })}
                value={ethnicityValues.title}/>)}
          </div>
          <div className='cell large-7'>
            {
              formData.ethnicity___12 === '1' && <>
                <SpecifyTextBox
                  fieldId={ethnicities.ethnicity___12.specify_other.id}
                  formId={FORMS.ETHNICITY.id}
                  handleFormChange={handleFormChange}
                  title={ethnicities.ethnicity___12.specify_other.title}/>
              </>
            }
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

EthnicityForm.propTypes = {
  formIndex: PropTypes.number
};

export default EthnicityForm;
