import React from 'react';

const Blurb3_10E = () =>
  <>
    <p>
      Attachment and relationships are informed by skills, abilities, and experiences across many different areas of life. Supporting families to navigate stress and areas of need, listening to caregivers, and engaging family, are all important in leveraging and maintaining stability while promoting your strengths and supporting your needs. Families are excellent sources of information related to strengths and resiliency factors that may be incorporated in success planning.
    </p>
    <p>
      Ongoing mental health supports like therapy, alongside ongoing monitoring will be important in informing supports regarding your attachment and relationships. Consider some key goals for continued interactions or support that will encourage familial cohesion and support that is best suited for you/your child, your family, or other supportive people in your/your child’s life.
    </p>
  </>;

export default Blurb3_10E;
