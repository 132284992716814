import React from 'react';

const Blurb5_3D = () =>
  <>
    <p>
      Your intellectual functioning, or how you learn, reason, use language, and solve problems may be different than others your age. This may in turn impact your experiences of success in learning. To support your learning, modified programming, appropriate accommodations and strategies will be important. Identified needs in this area may also be part of an individualized support plan where unique learning needs and accommodations are outlined.
    </p>
  </>;

export default Blurb5_3D;
