import React from 'react';

const Blurb7_22B = () =>
  <>
    <p>
      Accessing mentoring or coaching resources in the community may help your child. Supports like this can help kids develop a sense of belonging and connection to others and to grow adaptive skills. Learning by doing is often based on relationships, a common area of strength for children with FASD. Social and recreational activities can also help your child create and maintain relationships and regulate their feelings.
    </p>
  </>;

export default Blurb7_22B;
