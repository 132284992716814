import React from 'react';

const Blurb1_4C = () =>
  <>
    <p>
      Tailored intervention is important to support your teen’s unique needs and behaviours. Your teen did not
      meet diagnostic criteria for Fetal Alcohol Spectrum Disorder (FASD). Individuals who do not meet the
      diagnostic criteria for FASD but have prenatal alcohol exposure (PAE) and early signs of developmental
      concerns can be designated as at risk for neurodevelopmental disorder and FASD, associated with prenatal
      alcohol exposure. This is not a diagnosis of FASD but is meant to support early intervention and later
      re-assessment to determine if the diagnostic criteria for FASD are met. Although the COMPASS report
      focuses on those diagnosed with FASD, the information provided is useful for supporting healthy
      outcomes for all adolescents.
    </p>
  </>;

export default Blurb1_4C;
