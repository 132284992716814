import React from 'react';

const Blurb3_2D = () =>
  <>
    <p>
      Stable and consistent interactions that promote positive attachment with caregivers, peers, colleagues, and supportive others are important for developing healthy relationships. Connecting with FASD-informed supports and community agencies may be a helpful step in developing positive relationships through peer groups, mentorship programs, and other social opportunities.

    </p>
  </>;

export default Blurb3_2D;
