import React from 'react';

const Blurb14_3A = () =>
  <>
    <p>
      Based on the assessment results, your client does not currently meet diagnostic criteria for Fetal Alcohol Spectrum Disorder (FASD). However, they may have PAE and early signs of developmental concerns, so early intervention and future multidisciplinary re-assessment is warranted to determine if the diagnostic criteria for FASD are met. Although the COMPASS report focuses on those diagnosed with FASD, the information provided is useful for supporting healthy outcomes for all people.
    </p>
  </>;

export default Blurb14_3A;
