import React from 'react';

const Blurb12_1A = () =>
  <>
    <p>
      Most people, including those with FASD, want to belong in a community. Engaging with community can positively influence well-being across the lifespan, providing a positive way to build relationships, develop social skills, participate in enjoyed activities, and find a role promotes feelings of safety and security. Service providers, like yourself, are highly skilled in finding or co-creating opportunities for clients with FASD to leverage their strengths, set goals, and fulfil their desire to be a part of their community.
    </p>
    <p>
      Almost everyone receives support from others in their daily life, varying across individuals and life stages. Supports may include help with decision-making, self-care, hygiene, money management, social functioning, and/or accessing community resources, such as social and recreational activities. Co-creating a stable, caring, and dependable environment and facilitating access to community supports can enhance your client’s sense of safety and connection to others with increasing autonomy as they continue to grow and develop. Your client, like all people with FASD, possesses many strengths that can be leveraged to meet needs across the lifespan.
    </p>
  </>;

export default Blurb12_1A;
