import React from 'react';

const Blurb7_22D = () =>
  <>
    <p>
      Accessing mentoring or coaching resources in the community may help people with FASD. Supports like this can help people develop a sense of belonging and connection to others and to grow adaptive skills. Learning by doing is often based on relationships, a common area of strength for people with FASD. Social and recreational activities can also help individuals with FASD to create and maintain relationships and regulate their feelings.
    </p>
  </>;

export default Blurb7_22D;
