import React from 'react';

const Blurb4_1D = () =>
  <>
    <p>
      Our social skills can influence our success in school and work, the relationships we have, and the way we see ourselves. The development of social skills is complex, with many more moving parts than we may realize! Social success requires the application of cognitive, behavioural, and emotional skills and knowledge, and is further impacted by environmental factors. Social strengths and needs are woven together in unique ways for each person.
    </p>
    <p>
      It is important to continue to work on building your own social skills because they can be used to successfully communicate and build strong long-lasting social relationships.
    </p>
  </>;

export default Blurb4_1D;
