import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import CheckBox from '../../../components/CheckBox';
import PropTypes from 'prop-types';
import SpecifyTextBox from '../../../components/SpecifyTextBox';

const ReferralReasonForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.REFERRAL_REASON.id]);

  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    if (updatedFormData.ref_reason___11 === '0') {
      updatedFormData.ref_reasonspecify = FORMS.REFERRAL_REASON.fields.ref_reason___11.specify_other.value;
    }

    dispatch(submitForm(FORMS.REFERRAL_REASON.id, updatedFormData));
  };

  const referralReasons = FORMS.REFERRAL_REASON.fields;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] === '1');
  const isFormComplete = checkedFields.length > 0 && (formData.ref_reason___11 === '1' ? formData.ref_reasonspecify.length > 0 : true);

  return (
    <>
      <form>
        <div className='grid-x grid-margin-y'>
          <div className='cell large-7'>
            <label className='form-field'>Reason for Referral</label>
            {Object.entries(referralReasons).map(([reasonKey, reasonValues]) =>
              <CheckBox
                checked={formData[reasonKey] === '1'}
                className='checkbox-cells'
                key={reasonKey}
                onChange={e => handleFormChange({
                  ...formData,
                  [reasonKey]: e.target.checked ? '1' : '0'
                })}
                value={reasonValues.title}/>)}
          </div>
          <div className='cell large-7'>
            {
              formData.ref_reason___11 === '1' && <>
                <SpecifyTextBox
                  fieldId={FORMS.REFERRAL_REASON.fields.ref_reason___11.specify_other.id}
                  formId={FORMS.REFERRAL_REASON.id}
                  handleFormChange={handleFormChange}
                  title={FORMS.REFERRAL_REASON.fields.ref_reason___11.specify_other.title}/>
              </>
            }
          </div>
        </div>
      </form>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

ReferralReasonForm.propTypes = {
  formIndex: PropTypes.number
};

export default ReferralReasonForm;
