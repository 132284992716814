import React from 'react';

const Blurb4_4D = () =>
  <>
    <p>
      Continuing to support your social skills across relationships and social settings, including home and work will be important. Identifying other domains of healthy functioning such as community engagement where you can try out your skills and promote related abilities will be important to developing and building on strengths.
    </p>
  </>;

export default Blurb4_4D;
