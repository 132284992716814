import React from 'react';

const Blurb5_16C = () =>
  <>
    <p>
      Education is an important part of your teen’s development that informs goal planning and achievement throughout the lifespan. As your teen enters middle/high school, it will be important for educators to consider their unique strengths, needs, and goals when developing their education plan. Your teen may benefit from adaptations that allow for more “hands-on experiences,” time to process and think, and opportunities to share their knowledge with others. In this way your child can remain engaged at school and build skills to support their transition out of school into adulthood.
    </p>
  </>;

export default Blurb5_16C;
