import React from 'react';

const Blurb8_10 = () =>
  <>
    <p>
      Individuals with FASD may have more difficulties related to substance use than expected for many different reasons. Substance use can lead to problems with physical and mental health, and difficulties in daily living, so healthy connections and proactive mental health interventions can sometimes help. People with FASD can be supported to address substance use by having open conversations key support people in their lives, doing safety planning to reduce risks, and helping them to access FASD-informed substance use treatment. Treatment may include support with withdrawal symptoms, progressing through treatment, and continuing care afterwards. Evidence-based practices to support people with FASD in treatment for substance use can be found in this CanFASD guide: <a href='https://canfasd.ca/wp-content/uploads/publications/Moving-Towards-FASD-Informed-Care-in-Substance-Use-Treatment.pdf'>
      https://canfasd.ca/wp-content/uploads/publications/Moving-Towards-FASD-Informed-Care-in-Substance-Use-Treatment.pdf</a>
    </p>
  </>;

export default Blurb8_10;
