import React from 'react';

const Blurb6_4A = () =>
  <>
    <p>
      Supporting your child to navigate identity development can help them to recognize and build upon strengths and skills that can be leveraged to address needs. Encouraging your child’s identity development can also support autonomy and well-being, promote growth, and help your child during periods of change or transitions throughout the lifespan.
    </p>
  </>;

export default Blurb6_4A;
