import React from 'react';

const Blurb8_6E = () =>
  <>
    <p>
      Along with FASD, you/your child has also been diagnosed with autism, which is a neurodevelopmental disorder related to social emotional and behavioural functioning. Just like FASD, everyone with autism is unique. Researchers are still learning how autism and FASD are related and interact. You may find it helpful to connect with autism support services in your community.
    </p>
  </>;

export default Blurb8_6E;
