import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import {useSelector} from 'react-redux';
import FORMS, {IMAGES} from '../../../../redux/constants';
import Blurb10_1A from '../../../blurbs/10_Housing/Blurb10_1A';
import Blurb10_1B from '../../../blurbs/10_Housing/Blurb10_1B';
import Blurb10_1C from '../../../blurbs/10_Housing/Blurb10_1C';
import Blurb10_1D from '../../../blurbs/10_Housing/Blurb10_1D';
import Blurb10_1E from '../../../blurbs/10_Housing/Blurb10_1E';
import Blurb10_2 from '../../../blurbs/10_Housing/Blurb10_2';
import Blurb10_3 from '../../../blurbs/10_Housing/Blurb10_3';
import Blurb10_4A from '../../../blurbs/10_Housing/Blurb10_4A';
import Blurb10_4B from '../../../blurbs/10_Housing/Blurb10_4B';
import Blurb10_4C from '../../../blurbs/10_Housing/Blurb10_4C';
import Blurb10_4D from '../../../blurbs/10_Housing/Blurb10_4D';
import Blurb10_4E from '../../../blurbs/10_Housing/Blurb10_4E';
import Blurb10_5 from '../../../blurbs/10_Housing/Blurb10_5';
import Blurb10_6 from '../../../blurbs/10_Housing/Blurb10_6';
import Blurb10_7 from '../../../blurbs/10_Housing/Blurb10_7';
import Blurb10_8 from '../../../blurbs/10_Housing/Blurb10_8';
import Blurb10_9A from '../../../blurbs/10_Housing/Blurb10_9A';
import Blurb10_9B from '../../../blurbs/10_Housing/Blurb10_9B';
import Blurb10_9C from '../../../blurbs/10_Housing/Blurb10_9C';
import Blurb10_9D from '../../../blurbs/10_Housing/Blurb10_9D';
import Blurb10_9E from '../../../blurbs/10_Housing/Blurb10_9E';
import Blurb10_10 from '../../../blurbs/10_Housing/Blurb10_10';
import Blurb10_11 from '../../../blurbs/10_Housing/Blurb10_11';
import Variables from '../components/Variables';
import TwoColumnLayout from './TwoColumnLayout';

const Housing = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {living} = useSelector(state => state.forms[FORMS.CURRENT_LIVING_SITUATION.id]);
  const {helpliving, assistedhousing} = useSelector(state => state.forms[FORMS.EXPERIENCE.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const variables = {diagnosis, living, helpliving, assistedhousing, age};

  const HousingBlurbs10_1A = age === '1' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurbs10_1B = age === '2' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurbs10_1C = age === '3' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurbs10_1D = age === '4' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurbs10_1E = age === '5' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const HousingBlurbs10_2 = living === '8' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurbs10_3 = living === '0' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const HousingBlurbs10_4A = age === '1' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurbs10_4B = age === '2' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurbs10_4C = age === '3' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurbs10_4D = age === '4' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurbs10_4E = age === '5' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const HousingBlurb10_5 = helpliving === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurb10_6 = assistedhousing === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurb10_7 = living === '8' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurb10_8 = living === '0' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const HousingBlurb10_9A = age === '1' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurb10_9B = age === '2' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurb10_9C = age === '3' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurb10_9D = age === '4' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurb10_9E = age === '5' && (living >= '1' && living <= '7' || living === '9') &&
    (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  const HousingBlurb10_10 = living === '8' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');
  const HousingBlurb10_11 = living === '0' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2');

  return <section>
    <SectionTitle>Housing</SectionTitle>
    <Variables variableObject={variables}/>
    <TwoColumnLayout>

      {
        /*
         * HousingBlurbs10_3 is not included in the following if statement because it is empty,
         * even if it meets the condition, It will not add to the text in this section.
         */
        (HousingBlurbs10_1A || HousingBlurbs10_1B || HousingBlurbs10_1C || HousingBlurbs10_1D || HousingBlurbs10_1E || HousingBlurbs10_2) &&
        <Card>
          <h3 className='chapter-title'>
            Things to <span className='card-title'>Know</span>
          </h3>
          <>
            {HousingBlurbs10_1A && <Blurb10_1A/>}
            {HousingBlurbs10_1B && <Blurb10_1B/>}
            {HousingBlurbs10_1C && <Blurb10_1C/>}
            {HousingBlurbs10_1D && <Blurb10_1D/>}
            {HousingBlurbs10_1E && <Blurb10_1E/>}
            {HousingBlurbs10_2 && <Blurb10_2/>}
            {HousingBlurbs10_3 && <Blurb10_3/>}
          </>
        </Card>
      }
      {
        /*
         * HousingBlurbs10_7 is not included in the following if statement because it is empty,
         * even if it meets the condition, It will not add to the text in this section.
         */
        (HousingBlurbs10_4A || HousingBlurbs10_4B || HousingBlurbs10_4C || HousingBlurbs10_4D || HousingBlurbs10_4E ||
          HousingBlurb10_5 || HousingBlurb10_6 || HousingBlurb10_8) &&
        <Card>
          <h3 className='chapter-title'>
            Things to <span className='card-title'>Do Now</span>
          </h3>
          <>
            {HousingBlurbs10_4A && <Blurb10_4A/>}
            {HousingBlurbs10_4B && <Blurb10_4B/>}
            {HousingBlurbs10_4C && <Blurb10_4C/>}
            {HousingBlurbs10_4D && <Blurb10_4D/>}
            {HousingBlurbs10_4E && <Blurb10_4E/>}
          </>
          <>
            {HousingBlurb10_5 && <Blurb10_5/>}
          </>
          <>
            {HousingBlurb10_6 && <Blurb10_6/>}
          </>
          <>
            {HousingBlurb10_7 && <Blurb10_7/>}
            {HousingBlurb10_8 && <Blurb10_8/>}
          </>
        </Card>
      }
      {
        /*
         * HousingBlurbs10_11 is not included in the following if statement because it is empty,
         * even if it meets the condition, It will not add to the text in this section.
         */
        (HousingBlurb10_9A || HousingBlurb10_9B || HousingBlurb10_9C || HousingBlurb10_9D || HousingBlurb10_9E ||
          HousingBlurb10_10) &&
        <Card>
          <h3 className='chapter-title'>
            Things to <span className='card-title'>Plan</span> for
          </h3>
          <>
            {HousingBlurb10_9A && <Blurb10_9A/>}
            {HousingBlurb10_9B && <Blurb10_9B/>}
            {HousingBlurb10_9C && <Blurb10_9C/>}
            {HousingBlurb10_9D && <Blurb10_9D/>}
            {HousingBlurb10_9E && <Blurb10_9E/>}
          </>
          <>
            {HousingBlurb10_10 && <Blurb10_10/>}
            {HousingBlurb10_11 && <Blurb10_11/>}
          </>
        </Card>
      }
    </TwoColumnLayout>
    <img src={IMAGES.IMAGE10}/>
  </section>;
};

export default Housing;
