import React from 'react';

const Blurb1_3E = () =>
  <>
    <p>
      The assessment provided an opportunity to understand how your/your child’s needs and behaviours can
      be supported through intervention. Prenatal alcohol exposure (PAE) impacts the brains and bodies of
      people in different ways. Your/ your child’s assessment team indicated that you/your child have/has
      Fetal Alcohol Spectrum Disorder (FASD) without Sentinel Facial Features. Although some individuals
      may have physical features that are unique to PAE, other individuals like yourself/your child, do
      not. Individualized, ongoing supports can help all individuals with FASD to experience success and
      achieve healthy outcomes.
    </p>
  </>;

export default Blurb1_3E;
