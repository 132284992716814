import React from 'react';

const Blurb17_1D = () =>
  <>
    <p>
      The Canadian guidelines for diagnosing FASD highlight 10 brain domains to consider during the diagnostic process. This includes academic achievement, attention, cognition, language, memory, neuroanatomy, executive functioning, adaptive behaviour, motor skills, and affect regulation. Each person has their own unique areas of strengths and needs across these 10 brain domains. At this time, your pattern of strengths and needs does not indicate that you meet criteria for a diagnosis of Fetal Alcohol Spectrum Disorder. This means that your functioning is not considered to be impacted by prenatal alcohol exposure.
    </p>
    <p>
      It is important to know that as people continue to grow and change throughout their lifespan, their unique areas of strength and need also continue to evolve. It is likely that your needs have or will change as you age and develop, and it will be important that your supports adapt, change, and evolve to keep up. It will be important that your functioning continue to be monitored as you grow, develop, and age and continue to meet major milestones. It is recommended that you undergo future assessments to understand your unique areas of strength and need and ensure the achievement of healthy outcomes throughout the lifespan.
    </p>
    <p>
      The first step to achieving healthy outcomes is understanding, so that you feel support and acceptance, regardless of diagnosis. Through a shared understanding of your unique brain, we can provide individualized, proactive support so that you can experience success and achieve healthy outcomes.
    </p>
  </>;

export default Blurb17_1D;
