import React from 'react';

const Blurb5_16D = () =>
  <>
    <p>
      Education is an important part of your development that informs goal planning and achievement even into adulthood. If you choose to explore education or skill development opportunities, it will be important for educators, such as those within work training programs, to consider your unique strengths, needs, and goals when developing an individualized and supportive education plan. You may benefit from adaptations to programming that allow for more active participation in “hands-on experiences,” time to process and engage with content, and opportunities to share their knowledge with others. This will help you to stay engaged and build skills to support your educational journey.
    </p>
  </>;

export default Blurb5_16D;
